import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChildren
} from '@angular/core';

import { Router } from '@angular/router';

import { StartingPageLastVisited } from '@alii-web/modules/starting-page/models';
import { OwnerlistItem } from '@alii-web/models/protocol.model';

interface Version {
    version: string;
    link: string;
}

interface ProtocolItem {
    id: string;
    title: string;
    ownerList: OwnerlistItem[];
    versions: Version[];
    isLiterature?: boolean;
    queryParams: { version: string };
    external_url: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-last-visited',
    templateUrl: 'last-visited.component.html',
    styleUrls: ['./last-visited.component.scss']
})
export class LastVisitedComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
    lastVisited: StartingPageLastVisited;

    @ViewChildren('card') cards: QueryList<ElementRef>;

    protocols: ProtocolItem[];

    @HostListener('window:resize', ['$event']) onResize() {
        this._resizeCards();
    }

    constructor(private renderer: Renderer2, private router: Router,) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this._resizeCards();
    }

    ngOnChanges(changes: SimpleChanges) {
        const lastVisited = changes.lastVisited;
        if (lastVisited && lastVisited.currentValue) {
            this.protocols = [];
            lastVisited.currentValue.protocols.forEach(protocol => {
                this.protocols.push({
                    id: protocol.id.toString(),
                    title: protocol.title,
                    ownerList: protocol.ownerlist,
                    isLiterature: protocol.isLiterature,
                    versions: protocol.versions,
                    queryParams: this._queryParams(protocol.versions),
                    external_url: protocol.external_url
                });
            });

            this._resizeCards();
        }
    }

    clickVersion(id: string, version: string) {
        if (id) {
            version = version.charAt(0).toUpperCase() + version.slice(1);
            if(version == "Literature_version") {
                this.router.navigate(['/protocols', id, 'literatures'  ]);
            } else {
                this.router.navigate(['/protocols', id], { queryParams: { version } });
            }
        } 
        return false;
    }

    clickName(protocol) {
        if (protocol.id) {
            if(protocol.isLiterature) {
                this.router.navigate(['/protocols', protocol.id, 'literatures' ]);
            } else {
                this.router.navigate(['/protocols', protocol.id], { queryParams: protocol.queryParams });
            }
        } 
        return false;
    }

    trackByFn = (index, item) => item.id || index;

    private _resizeCards() {
        setTimeout(() => {
            if (this.cards) {
                const cards = this.cards.toArray();
                if (cards.length) {
                    let max = 0;
                    // Get max height
                    const heights = cards.map(card => card.nativeElement.offsetHeight);
                    cards.forEach(card => {
                        const height = card.nativeElement.offsetHeight;
                        if (max < height) {
                            max = height;
                        }
                    });
                    // Adjust all card height to max
                    cards.forEach((card, index) => {
                        if (heights[index] !== max) {
                            const elFooter = card.nativeElement.querySelector('.card__footer');
                            if (elFooter) {
                                setTimeout(
                                    () => this.renderer.setStyle(elFooter, 'marginTop', max - heights[index] + 'px'),
                                    0
                                );
                            }
                            this.renderer.setStyle(card.nativeElement, 'height', max + 'px');
                        }
                    });
                }
            }
        }, 1000);
    }

    private _queryParams(versions: Version[]): { version: string } {
        if (versions && versions.length === 0) {
            return { version: 'Draft' };
        }
        const current = versions.find(v => v.version === 'current');
        const draft = versions.find(v => v.version === 'draft');
        const preview = versions.find(v => v.version === 'preview');
        if (current) {
            return { version: 'Current' };
        }
        if (draft) {
            return { version: 'Draft' };
        }
        if (preview) {
            return { version: 'Preview' };
        }
        if (!current && !draft) {
            return { version: versions[0].version };
        }
        return null;
    }
}
