import { Action } from '@ngrx/store';
import { User } from '../../../../models/user.interface';

export const LOAD_USERS = '[Organisation] Load Users';
export const LOAD_USERS_FAIL = '[Organisation] Load Users Fail';
export const LOAD_USERS_SUCCESS = '[Organisation] Load Users Success';

export class LoadUsers implements Action {
    readonly type = LOAD_USERS;
    constructor(public payload: any) {}
}

export class LoadUsersFail implements Action {
    readonly type = LOAD_USERS_FAIL;
    constructor(public payload: any) {}
}

export class LoadUsersSucces implements Action {
    readonly type = LOAD_USERS_SUCCESS;
    constructor(public payload: User[]) {}
}

export const CREATE_USER = '[Organisation] Create User';
export const CREATE_USER_SUCCESS = '[Organisation] Create User Success';
export const CREATE_USER_FAIL = '[Organisation] Create User Fail';

export class CreateUser implements Action {
    readonly type = CREATE_USER;
    constructor(public payload: any) {}
}

export class CreateUserFail implements Action {
    readonly type = CREATE_USER_FAIL;
    constructor(public payload: any) {}
}

export class CreateUserSuccess implements Action {
    readonly type = CREATE_USER_SUCCESS;
    constructor(public payload: any) {}
}

export const REMOVE_USER = '[Organisation] Remove User';
export const REMOVE_USER_SUCCESS = '[Organisation] Remove User Success';
export const REMOVE_USER_FAIL = '[Organisation] Remove User Fail';

export class RemoveUser implements Action {
    readonly type = REMOVE_USER;
    constructor(public payload: any) {}
}

export class RemoveUserFail implements Action {
    readonly type = REMOVE_USER_FAIL;
    constructor(public payload: any) {}
}

export class RemoveUserSuccess implements Action {
    readonly type = REMOVE_USER_SUCCESS;
    constructor(public payload: any) {}
}

export const SET_ADMIN = '[Organisation] Set Admin';
export const SET_ADMIN_SUCCESS = '[Organisation] Set Admin Success';
export const SET_ADMIN_FAIL = '[Organisation] Set Admin Fail';

export class SetAdmin implements Action {
    readonly type = SET_ADMIN;
    constructor(public payload: any) {}
}

export class SetAdminFail implements Action {
    readonly type = SET_ADMIN_FAIL;
    constructor(public payload: any) {}
}

export class SetAdminSuccess implements Action {
    readonly type = SET_ADMIN_SUCCESS;
    constructor(public payload: any) {}
}

export const SET_REVIEWER = '[Organisation] Set Reviewer';
export const SET_REVIEWER_SUCCESS = '[Organisation] Set Reviewer Success';
export const SET_REVIEWER_FAIL = '[Organisation] Set Reviewer Fail';

export class SetReviewer implements Action {
    readonly type = SET_REVIEWER;
    constructor(public payload: any) {}
}

export class SetReviewerFail implements Action {
    readonly type = SET_REVIEWER_FAIL;
    constructor(public payload: any) {}
}

export class SetReviewerSuccess implements Action {
    readonly type = SET_REVIEWER_SUCCESS;
    constructor(public payload: any) {}
}

export const REMOVE_REVIEWER = '[Organisation] Remove Reviewer';
export const REMOVE_REVIEWER_SUCCESS = '[Organisation] Remove Admin Success';
export const REMOVE_REVIEWER_FAIL = '[Organisation] Remove Admin Fail';

export class RemoveReviewer implements Action {
    readonly type = REMOVE_REVIEWER;
    constructor(public payload: any) {}
}

export class RemoveReviewerFail implements Action {
    readonly type = REMOVE_REVIEWER_FAIL;
    constructor(public payload: any) {}
}

export class RemoveReviewerSuccess implements Action {
    readonly type = REMOVE_REVIEWER_SUCCESS;
    constructor(public payload: any) {}
}


export const SET_STATISTICS = '[Organisation] Set Statistics';
export const SET_STATISTICS_SUCCESS = '[Organisation] Set Statistics Success';
export const SET_STATISTICS_FAIL = '[Organisation] Set Statistics Fail';

export class SetStatistics implements Action {
    readonly type = SET_STATISTICS;
    constructor(public payload: any) {}
}

export class SetStatisticsFail implements Action {
    readonly type = SET_STATISTICS_FAIL;
    constructor(public payload: any) {}
}

export class SetStatisticsSuccess implements Action {
    readonly type = SET_STATISTICS_SUCCESS;
    constructor(public payload: any) {}
}


export const REMOVE_STATISTICS = '[Organisation] Remove Statistics';
export const REMOVE_STATISTICS_SUCCESS = '[Organisation] Remove Statistics Success';
export const REMOVE_STATISTICS_FAIL = '[Organisation] Remove Statistics Fail';

export class RemoveStatistics implements Action {
    readonly type = REMOVE_STATISTICS;
    constructor(public payload: any) {}
}

export class RemoveStatisticsFail implements Action {
    readonly type = REMOVE_STATISTICS_FAIL;
    constructor(public payload: any) {}
}

export class RemoveStatisticsSuccess implements Action {
    readonly type = REMOVE_STATISTICS_SUCCESS;
    constructor(public payload: any) {}
}


export type UsersAction =
    | LoadUsers
    | LoadUsersFail
    | LoadUsersSucces
    | CreateUser
    | CreateUserSuccess
    | CreateUserFail
    | RemoveUser
    | RemoveUserSuccess
    | RemoveUserFail
    | SetAdmin
    | SetAdminSuccess
    | SetAdminFail
    | SetReviewer
    | SetReviewerSuccess
    | SetReviewerFail
    | SetStatistics
    | SetStatisticsSuccess
    | SetStatisticsFail
    | RemoveReviewer
    | RemoveReviewerSuccess
    | RemoveReviewerFail;
