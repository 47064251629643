import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { DashboardExisting } from '@alii-web/models/dashboard-existing.model';
import {
    CreateDashboardV2DTO,
    DashboardV2Action,
    GetAllCategoriesV2RO,
    GetDashboardV2RO,
    listActionTypes,
    UpdateDashboardV2DTO
} from '@alii-web/models/dashboard-v2.model';
import { ConfirmModalComponent } from '@alii-web/modules/protocols/entry-components';
import { DashboardService } from '@alii-web/services';
import { StartingPageActionDashboard } from '@alii-web/modules/starting-page/models';

type EditMode = 'list' | 'update' | 'create';

interface CategoryItem {
    id: string;
    name: string;
    position: number;
}

interface SavedState {
    id: string;
    title: string;
    selectedCategoryItems: CategoryItem[];
    availableCategoryItems: CategoryItem[];
}

const cn = 'DashboardManageComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-manage',
    templateUrl: './dashboard-manage.component.html',
    styleUrls: ['../common/common.scss']
})
export class DashboardManageV2Component implements OnInit, OnDestroy {
    currentDashboard: GetDashboardV2RO;

    loadingDashboard: boolean;
    loadingAllDashboards: boolean;

    loadingCreate: boolean;
    loadingUpdate: boolean;
    loadingDelete: boolean;

    allDashboards: StartingPageActionDashboard[];
    allCategories: GetAllCategoriesV2RO;

    selectedCategoryItems: CategoryItem[];
    availableCategoryItems: CategoryItem[];

    form: FormGroup;

    maxlength = 75;
    sizeSelectCategories = 10;
    sizeSelectAllCategories = 10;

    subscriptions: Subscription[] = [];

    editMode: EditMode;

    selectedIndex = 0;
    selectedAvailableIndex = 0;

    savedStateKeys = ['update', 'create'];
    savedState: { [key: string]: SavedState } = {};

    permissions = {};

    tooltipKeys = ['update', 'reset', 'delete', 'create', 'manage'];
    tooltip = {};

    itemName = 'DASHBOARD';

    // development = !environment.production;
    development = false; // !environment.production;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private dashboardService: DashboardService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: ['', Validators.required],
            categories: []
        });

        // If called as `/dashboard?client=123` redirect to the home page,
        // preserving the client query param.
        const client = this.route.snapshot.queryParams['client'];
        if (client) {
            this.router.navigate(['home'], { queryParams: { client } });
        }

        // params['id'] indicates the edit mode for this page.
        //   null   => 'list'
        //   create => 'create'
        //   else   => 'update' and dashboardId = params['id]
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                const id = params['id'];
                const editMode: EditMode = id ? (id === 'create' ? 'create' : 'update') : 'list';
                this._switchEditMode(editMode, id);
            })
        );

        // Setup translations
        this._onLangChange();
        this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this._onLangChange()));

        // Setup permissions
        this.subscriptions.push(
            this.permissionsService.permissions$.subscribe(permissions => (this.permissions = permissions))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    getNumberOfProtocols(dashboard: GetDashboardV2RO) {
        const found = (this.allDashboards || []).find(d => d.id === dashboard.dashboard_id);
        return found ? found.number_of_protocols : '';
    }

    selectDashboard(id: string, navigate = true) {
        if (navigate) {
            this.router.navigate(['dashboard', id]);
        }
    }

    dblclickSelectedCategories(category: CategoryItem) {
        if (this.permissions['remove_category']) {
            // Move category back to available category items list
            this.availableCategoryItems.push(category);

            // If we are moving the selected item then deselect.
            if (this.selectedIndex === this._findIndexById(this.selectedCategoryItems, category.id)) {
                this.selectedIndex = 0;
            }

            // Sort all available categories by id.
            this.availableCategoryItems = this._sortCategoriesById(this.availableCategoryItems);

            // Remove category from selected category items list
            this.selectedCategoryItems = this.selectedCategoryItems
                .filter(c => c.id !== category.id)
                .map((c, i) => ({ ...c, position: i + 1 }));

            if (!this.selectedCategoryItems.length) {
                this.selectedIndex = 0;
            }
        }
    }

    dblclickAvailableCategories(category: CategoryItem) {
        if (this.permissions['add_category']) {
            // Get the highest position from the selected categories items list, if any.
            const sortedCategories = this._sortCategoriesByPosition(this.selectedCategoryItems);
            category.position = sortedCategories.length
                ? sortedCategories[sortedCategories.length - 1].position + 1
                : 1;

            // Move category from available to selected items list
            this.selectedCategoryItems.push(category);

            // If we are moving the selected item then deselect.
            if (this.selectedAvailableIndex === this._findIndexById(this.availableCategoryItems, category.id)) {
                this.selectedAvailableIndex = 0;
            }

            // Remove category from available items list
            this.availableCategoryItems = this.availableCategoryItems.filter(c => c.id !== category.id);

            if (!this.availableCategoryItems.length) {
                this.selectedAvailableIndex = 0;
            }
        }
    }

    onSubmit() {
        switch (this.editMode) {
            case 'create':
                this._handleOnSubmitCreate();
                break;
            case 'update':
                this._handleOnSubmitUpdate();
                break;
            default:
                console.warn(`${cn} onSubmit() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onReset() {
        switch (this.editMode) {
            case 'create':
                this._handleOnResetCancel();
                break;
            case 'update':
                this._handleOnResetUpdate();
                break;
            default:
                console.warn(`${cn} onReset() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onCreate() {
        switch (this.editMode) {
            case 'list':
                this._handleOnCreateCreate();
                break;
            case 'update':
                this._handleOnCreateUpdate();
                break;
            default:
                console.warn(`${cn} onCreate() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onCancel() {
        switch (this.editMode) {
            case 'create':
                this._handleOnCancelCreate();
                break;
            default:
                console.warn(`${cn} onCancel() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onDelete() {
        switch (this.editMode) {
            case 'update':
                this._handleOnDeleteUpdate();
                break;
            default:
                console.warn(`${cn} onDelete() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onSelectCategory(category: CategoryItem) {
        this.selectedIndex = this._findIndexById(this.selectedCategoryItems, category.id);
    }

    onSelectAvailableCategory(category: CategoryItem) {
        this.selectedAvailableIndex = this._findIndexById(this.availableCategoryItems, category.id);
    }

    onUp() {
        if (this.selectedIndex > 1) {
            this._shiftIndexBy(-1);
        } else {
            console.warn(`${cn} onUp() selectedIndex='${this.selectedIndex}'`);
        }
    }

    onDown() {
        if (this.selectedIndex && this.selectedIndex <= this.selectedCategoryItems.length - 1) {
            this._shiftIndexBy(1);
        } else {
            console.warn(`${cn} onDown() selectedIndex='${this.selectedIndex}'`);
        }
    }

    onCreateCategory() {
        this.router.navigate(['dashboard', 'category', 'create']);
    }

    onManageCategories() {
        let categoryId: string;
        const queryParams = { dashboardId: this.currentDashboard.dashboard_id };
        if (this.selectedAvailableIndex) {
            categoryId = this.availableCategoryItems[this.selectedAvailableIndex - 1].id;
        } else if (this.selectedIndex) {
            categoryId = this.selectedCategoryItems[this.selectedIndex - 1].id;
        }

        if (categoryId) {
            this.router.navigate(['dashboard', 'category', categoryId], { queryParams });
        } else {
            this.router.navigate(['dashboard', 'category'], { queryParams });
        }
    }

    handleDashboardClicked(dashboard: DashboardExisting) {
        this.router.navigate(['dashboard', dashboard.id, 'edit']);
    }

    debugPermissions() {
        const result = listActionTypes
            .map(permission => this.permissions[permission])
            .filter(permission => !!permission)
            .map(permission => permission.name);

        return result && result.length ? 'Permissions: ' + result.join(', ') : '';
    }

    trackByFn = (index, item) => item.id || index;

    private _onLangChange() {
        // Redefine the button tooltips based on the current language.
        const prefix = this.itemName + '.MANAGE.TOOLTIP.';
        this.translateService.get(this.tooltipKeys.map(key => prefix + key.toUpperCase())).subscribe(results => {
            this.tooltipKeys.forEach(key => (this.tooltip[key] = results[prefix + key.toUpperCase()]));
        });
    }

    private _handleOnSubmitCreate() {
        const payload: CreateDashboardV2DTO = {
            title: this.form.value.title,
            categoryIds: this.selectedCategoryItems.map(c => c.id)
        };

        this.loadingCreate = true;
        this.dashboardService.createDashboardV2(payload).subscribe(
            response => {
                this._showToastr('create', true);
                if (response && response.dashboard) {
                    const dashboard = response.dashboard;
                    this.allDashboards.push({
                        id: dashboard.dashboard_id,
                        name: dashboard.dashboard_title,
                        number_of_protocols: '0'
                    });
                    this.savedState['create'] = null;
                    this.router.navigate(['dashboard', response.dashboard.dashboard_id]);
                } else {
                    this.router.navigate(['dashboard']);
                }
            },
            () => this._showToastr('create', false),
            () => {
                this.loadingCreate = this._cancelLoading();
            }
        );
    }

    private _handleOnSubmitUpdate() {
        const payload: UpdateDashboardV2DTO = {
            title: this.form.value.title,
            categoryIds: this.selectedCategoryItems.map(c => c.id)
        };

        this.loadingUpdate = true;
        this.dashboardService.updateDashboardV2(this.currentDashboard.dashboard_id, payload).subscribe(
            () => {
                this._handleOnSubmitUpdateSucceeded();
                this._showToastr('update', true);
            },
            () => this._showToastr('update', false),
            () => {
                this.loadingUpdate = this._cancelLoading();
            }
        );
    }

    private _handleOnSubmitUpdateSucceeded() {
        // Update the title of the current dashboard and in the dashboards list.
        const title = this.form.value.title;
        const id = this.currentDashboard.dashboard_id;
        this.currentDashboard.dashboard_title = title;
        this.allDashboards = this.allDashboards.map(d => ({
            ...d,
            name: d.id.toString() === id ? title : d.name
        }));
        this._saveStateUpdate(id, title);
    }

    private _handleOnResetCancel() {
        this._switchEditModeCreate();
    }

    private _handleOnResetUpdate() {
        this._restoreStateUpdate();
    }

    private _handleOnCreateCreate() {
        this.router.navigate(['dashboard', 'create']);
    }

    private _handleOnCreateUpdate() {
        // Save the current dashboard id and navigate to create path
        this._saveStateCreate(this.currentDashboard.dashboard_id, this.currentDashboard.dashboard_title);
        this.router.navigate(['dashboard', 'create']);
    }

    private _handleOnCancelCreate() {
        // Return to previous dashboard if present otherwise go to main listing page.
        const savedStateCreate = this.savedState['create'];
        if (savedStateCreate) {
            const id = savedStateCreate.id;
            this.savedState['create'] = null;
            this.router.navigate(['dashboard', id]);
        } else {
            this.router.navigate(['dashboard']);
        }
    }

    private _handleOnDeleteUpdate() {
        // Double-check just in case and if verified do actual delete.
        const modalRef = this.modalService.open(ConfirmModalComponent);
        if (modalRef) {
            modalRef.componentInstance.data = {
                title: this.itemName + '.MANAGE.DELETE.TITLE',
                text: this.itemName + '.MANAGE.DELETE.TEXT'
            };
            modalRef.result.then(
                () => this._handleOnDeleteUpdateVerified(),
                () => {}
            );
        } else {
            console.error(`${cn} _handleOnDeleteUpdate() cannot open confirm modal component`);
            return false;
        }
    }

    private _handleOnDeleteUpdateVerified() {
        this.loadingDelete = true;
        this.dashboardService.deleteDashboardV2(this.currentDashboard.dashboard_id).subscribe(
            () => {
                this.allDashboards = this.allDashboards.filter(
                    d => d.id.toString() !== this.currentDashboard.dashboard_id.toString()
                );
                this.currentDashboard = null;
                this.router.navigate(['dashboard']);
                this.form.reset();
                this._showToastr('delete', true);
            },
            () => this._showToastr('delete', false),
            () => {
                this.loadingDelete = this._cancelLoading();
            }
        );
    }

    private _saveStateUpdate(id: string, title: string) {
        // Save initial state in case of reset.
        this._saveState('update', id, title);
    }

    private _saveStateCreate(id: string, title: string) {
        // Save initial state in case of reset.
        this._saveState('create', id, title);
    }

    private _saveState(key: string, id: string, title: string) {
        if (this.savedStateKeys.includes(key)) {
            this.savedState[key] = {
                id,
                title,
                selectedCategoryItems: [...this.selectedCategoryItems],
                availableCategoryItems: [...this.availableCategoryItems]
            };
        } else {
            console.warn(`${cn} _saveState() invalid key='${key}'`);
        }
    }

    private _restoreStateUpdate(del = false) {
        this._restoreState('update', del);
    }

    private _restoreStateCreate(del = false) {
        this._restoreState('create', del);
    }

    private _restoreState(key: string, del = false) {
        if (this.savedStateKeys.includes(key)) {
            const savedState = this.savedState[key];
            if (savedState) {
                this.form.patchValue({ title: savedState.title });
                this.selectedCategoryItems = [...savedState.selectedCategoryItems];
                this.availableCategoryItems = [...savedState.availableCategoryItems];

                if (del) {
                    delete this.savedState[key];
                }

                // Flag change detection
                this.cdr.markForCheck();
            } else {
                console.warn(`${cn} _restoreState() saved state for key='${key}' does not exist`);
            }
        } else {
            console.warn(`${cn} _restoreState() invalid key='${key}'`);
        }
    }

    private _saveStateClear() {
        this.savedStateKeys.forEach(key => (this.savedState[key] = null));
    }

    private _shiftIndexBy(n: number) {
        const index = this.selectedIndex - 1;
        const x = [...this.selectedCategoryItems];
        const [y] = x.splice(index, 1);
        x.splice(index + n, 0, y);
        this.selectedCategoryItems = x.map((c, i) => ({ ...c, position: i + 1 }));
        this.selectedIndex = this.selectedIndex + n;
    }

    private _findIndexById = (categoryItems: CategoryItem[], id: string) =>
        categoryItems.findIndex(item => item.id === id) + 1;

    private _switchEditMode(editMode: EditMode, dashboardId: string) {
        this.editMode = editMode;
        this.selectedIndex = 0;

        // Need to wait until dashboards and categories have been loaded.
        const timerId = setInterval(() => {
            if (!this.loadingAllDashboards) {
                switch (editMode) {
                    case 'list':
                        this._switchEditModeList();
                        break;
                    case 'update':
                        this._switchEditModeUpdate(dashboardId);
                        break;
                    case 'create':
                        this._switchEditModeCreate();
                        break;
                    default:
                        console.warn(`${cn} _switchEditMode() unknow editMode='${editMode}'`);
                        break;
                }
                clearInterval(timerId);
            }
        }, 200);
    }

    private _switchEditModeList() {
        this.currentDashboard = null;
        this.selectedCategoryItems = [];

        if (!this.allDashboards) {
            // Get all dashboards
            this.loadingAllDashboards = true;
            this.dashboardService.getAllDashboardsV2().subscribe(
                response => (this.allDashboards = response),
                error => console.warn(`${cn} getAllDashboardsV2() failed`, error),
                () => (this.loadingAllDashboards = this._cancelLoading())
            );
        }
    }

    private _switchEditModeUpdate(dashboardId: string) {
        this.loadingDashboard = true;
        this.cdr.markForCheck();
        setTimeout(() =>
            this.dashboardService.getDashboardV2(dashboardId).subscribe(
                dashboard => this._loadDashboard(dashboard),
                () => console.warn(`${cn} getDashboardV2 failed`),
                () => (this.loadingDashboard = this._cancelLoading())
            )
        );
    }

    private _switchEditModeCreate() {
        this.currentDashboard = null;
        this.selectedCategoryItems = [];
        if (!this.allCategories) {
            console.warn(
                `${cn} _switchEditModeCreate() must first select a dashboard, redirecting to dashboard list...`
            );
            this.router.navigate(['/dashboard']);
        } else {
            this._loadAvailableCategories();
            this.form.reset();
        }
    }

    private _loadAvailableCategories() {
        this.availableCategoryItems = (this.allCategories || []).map(c => ({
            id: c.id,
            name: c.title,
            position: 0
        }));
        this.availableCategoryItems = this._sortCategoriesById(this.availableCategoryItems);
        this.cdr.markForCheck();
    }

    private _loadDashboard(dashboard: GetDashboardV2RO) {
        if (!dashboard || !dashboard.dashboard_title) {
            this._showToastr('load', false);
            this.router.navigate(['dashboard']);
            return;
        }

        this.currentDashboard = dashboard;
        this.form.patchValue({ title: dashboard.dashboard_title });

        // Load all dashboards
        this._loadAllDashboards(dashboard.actions);

        // Load all categories
        this._loadAllCategories(dashboard.actions);

        // Load all available categories
        this._loadAvailableCategories();

        // Load all the permissions
        this._loadPermissions(dashboard.actions);

        // Filter out those categories which are already used by the dashboard.
        const categoryIds = dashboard.categories.map(category => category.id.toString());

        // Setup available category items list.
        this.availableCategoryItems = this.availableCategoryItems.filter(
            category => !categoryIds.includes(category.id)
        );

        // Sort available categories by id.
        this.availableCategoryItems = this._sortCategoriesById(this.availableCategoryItems);

        this.selectedCategoryItems = dashboard.categories
            .filter(category => categoryIds.includes(category.id.toString()))
            .map(c => ({
                id: c.id,
                name: c.name,
                position: c.position
            }));

        // Sort selected categories by position.
        this.selectedCategoryItems = this._sortCategoriesByPosition(this.selectedCategoryItems);

        this._saveStateUpdate(dashboard.dashboard_id, dashboard.dashboard_title);

        // If we are returning from a cancelled create, we need to restore state.
        if (this.savedState['create']) {
            this._restoreStateCreate(true);
        }
    }

    private _loadAllDashboards(actions: DashboardV2Action[] = []) {
        this.allDashboards = [];
        const actionName = 'switchDashboard';
        const found = actions.find(action => action.action === actionName);
        if (found) {
            if (found.value) {
                found.dashboards.forEach(dashboard =>
                    this.allDashboards.push({
                        id: dashboard.id.toString(),
                        name: dashboard.title
                    })
                );
            }
        } else {
            console.warn(`${cn} _loadAllDashboards() cannot find action='${actionName}'`);
        }
    }

    private _loadAllCategories(actions: DashboardV2Action[] = []) {
        this.allCategories = [];
        const actionName = 'add_category';
        const found = actions.find(action => action.action === actionName);
        if (found) {
            if (found.value) {
                found.tags.forEach(tag =>
                    this.allCategories.push({
                        id: tag.id,
                        title: tag.title
                    })
                );
            }
        } else {
            console.warn(`${cn} _loadAllCategories() cannot find action='${actionName}'`);
        }
    }

    private _loadPermissions(actions: DashboardV2Action[] = []) {
        this.permissionsService.addPermission(
            actions.filter(action => action.action && action.value).map(action => action.action)
        );
        actions
            .filter(action => action.action && !action.value)
            .map(action => action.action)
            .forEach(permission => this.permissionsService.removePermission(permission));
    }

    private _cancelLoading(): boolean {
        this.cdr.markForCheck();
        return false;
    }

    private _sortCategoriesById(categories: CategoryItem[] = []): CategoryItem[] {
        return this._sortCategories(categories, 'id');
    }

    private _sortCategoriesByName(categories: CategoryItem[] = []): CategoryItem[] {
        return this._sortCategories(categories, 'name');
    }

    private _sortCategoriesByPosition(categories: CategoryItem[] = []): CategoryItem[] {
        return this._sortCategories(categories, 'position');
    }

    private _sortCategories(categories: CategoryItem[] = [], attr: string): CategoryItem[] {
        return attr === 'id'
            ? categories.sort((a, b) => (+a[attr] < +b[attr] ? -1 : +b[attr] < +a[attr] ? 1 : 0))
            : categories.sort((a, b) => (a[attr] < b[attr] ? -1 : b[attr] < a[attr] ? 1 : 0));
    }

    private _showToastr(prefix: string, success: boolean) {
        const keys = [
            this.itemName + `.MANAGE.ACTION.${prefix.toUpperCase()}.${success ? 'OK' : 'NOK'}`,
            `${success ? 'SUCCESS' : 'ERROR'}`
        ];
        this.translateService.get(keys).subscribe(key => {
            if (success) {
                this.toastr.success(key[keys[0]], key[keys[1]]);
            } else {
                this.toastr.error(key[keys[0]], key[keys[1]]);
            }
        });
    }
}
