import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Pubmed } from '../../../../models/pubmed.interface';
import { Subscription } from 'rxjs';

import { RenderHTML } from '../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../literatures/components/reviews/edit-html.component';

import { runInThisContext } from 'vm';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-search-pubmed-modal',
    templateUrl: './search-pubmed-modal.component.html'
})
export class SearchPubmedModalComponent implements OnInit, OnDestroy {
    pubmeds: Pubmed[];
    sourceTable: any[];
    parentId: string;
    filteredSourceTable: any[];
    ppdId: string;
    actionsShown: boolean

    @Output() eventBus = new EventEmitter<any>();
    @ViewChild('agGrid0') agGrid0: AgGridAngular;
    @ViewChild('agGrid1') agGrid1: AgGridAngular;

    form: FormGroup;
    nonPubmedForm: FormGroup;
    searchForm: FormGroup;
    query: string;
    search: boolean;
    included: Pubmed[];
    searchLoading: boolean;
    activeTab: string;
    selectFootnotes: boolean;
    footnote;
    frameworkComponents;
 
    disabled = true;

    subscr: Subscription;

    constructor(
        public activeModal: NgbActiveModal, 
        private fb: FormBuilder) 
        {
            this.frameworkComponents = {
            renderHTML: RenderHTML,
            editHTML: EditHTML,
            }
        }

    ngOnInit() {
        if (this.included === undefined) {
            this.included = [];
        }

        if (!this.search) {
            this.filterSourceTable()
        }

        this.activeTab = 'search-form';
        this.form = this.fb.group({});
        this.searchForm = this.fb.group({
            query: [this.query, Validators.required]
        });
        if (this.search) {
            this.buildUids();
        } else
        {
            this.buildPubMedIds();
        }
         this.nonPubmedForm = this.fb.group({
            url: '',
            anchorText: '',
            author: '',
            year: '',
            title: ''
        });
        this.subscr = this.nonPubmedForm.valueChanges.subscribe(v => (this.disabled = !(v.url || v.author || v.title)));
    }

    defaultColDef: any = {
        resizable: true,
        cellStyle: {
          'align-items': 'center'
        }
      };

    showActions() {
        this.actionsShown = true
    }

    getSelectedRowData() {
        let selectedData = []
        if(this.agGrid0) {
            let selectedNodes = this.agGrid0.api.getSelectedNodes();    
            selectedData = selectedData.concat(selectedNodes.map(node => node.data) );
            
        }
        if(this.agGrid1) {
            let selectedNodes = this.agGrid1.api.getSelectedNodes();    
            selectedData = selectedData.concat(selectedNodes.map(node => node.data) );
        }
        return selectedData;
    }


    ngOnDestroy() {
        if (this.subscr) {
            this.subscr.unsubscribe();
        }
    }

    isIncluded(pubmed) {
        let isIncluded = false
        this.included.forEach((article) => {
            if (article.id == pubmed.id) {
                isIncluded = true
            }   
          });
        return isIncluded
    }


    buildUids() {
        const pubmeds = this.pubmeds ? this.pubmeds : [];
        pubmeds.map(pubmed => {
            this.form.addControl(pubmed.uid, this.fb.control(false));
        });
    }

    buildPubMedIds() {
        const pubmeds = this.pubmeds ? this.pubmeds : [];
        pubmeds.map(pubmed => {
            this.form.addControl(pubmed.id, this.fb.control(false));
        });
    }


    onClickTab(tabId) {
        this.activeTab = tabId;
    }

    onSubmitSearch() {
        if (this.searchForm.valid) {
            this.searchLoading = true;
            const event = {
                type: 'handleGetPubmedSearch',
                payload: {
                    ...this.searchForm.value,
                    footnote: !!this.footnote,
                    parentId: this.parentId
                }
            };

            this.eventBus.emit(event);
        }
    }

    filterSourceTable() {
        this.filteredSourceTable = []
        this.sourceTable.forEach( sourceList => {
            let filteredRowData = []
            let filteredColumnDefs = []
            sourceList.rowData.forEach(e => {
                if (!this.included.find(source => source.id == e.id )) {
                filteredRowData.push(Object.assign({}, e))
                }
                });
            sourceList.columnDefs.forEach(e => filteredColumnDefs.push(Object.assign({}, e)));
            let filteredData = {rowData: filteredRowData, columnDefs: filteredColumnDefs}
            this.filteredSourceTable.push(filteredData)
        })
    }

    onSubmitAddSources() {
        const findingIds = this.getSelectedRowData().map(element => element.id)
        let event = {
                type: 'handleAddReference',
                payload: {
                    uids: [],
                    ppdId: this.ppdId,
                    findingIds,
                    parentId: this.parentId,
                    footnote: !!this.footnote
                }
            };
        
        this.eventBus.emit(event);
    }

    onSubmitAddReferences() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            let event = {}
            const uids = Object.keys(data).filter(item => data[item]);
            if (this.search) {
                event = {
                    type: 'handleAddReference',
                    payload: {
                        uids,
                        ppdId: this.ppdId,
                        parentId: this.parentId,
                        footnote: !!this.footnote
                    }
                };
            } else {
                event = {
                    type: 'handleAddReference',
                    payload: {
                        uids: [],
                        ppdId: this.ppdId,
                        parentId: this.parentId,
                        findingIds: uids,
                        footnote: !!this.footnote
                    }
                };
            }
            this.eventBus.emit(event);
        } else {
            console.log('not valid')
        }
    }


    onSubmitNonPubmedForm() {
        if (this.nonPubmedForm.valid) {
            const event = {
                type: 'handleAddNonPubmedReference',
                payload: {
                    ppdId: this.ppdId,
                    ...this.nonPubmedForm.value,
                    footnote: !!this.footnote
                }
            };

            this.eventBus.emit(event);
        }
    }

    onCancel() {
        this.activeModal.dismiss('Cancelled by user');
    }

    trackByFn = (index, item) => item.id || index;
}
