import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// Development only
import { diff, detailedDiff } from 'deep-object-diff';
import _ from 'lodash';

import { Paragraph } from '@alii-web/models/paragraph.interface';
import { Outcome, OutcomeType } from '@alii-web/models/outcome.interface';
import { ParagraphsService } from '@alii-web/services';
import { environment } from '@environments/environment';
import { Question } from '@alii-web/models/question.interface';
import { NgbDateStruct, NgbTimeStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';

// Development only
import { ModelsService, ProtocolsService } from '../../../../../../services';
import { Option } from '@alii-web/models/question.interface';
import { set } from 'immer/dist/internal';

const cn = 'ModelDetailComponent';

type OutcomesView = 'vertical' | 'horizontal';

// Any display width less than BREAKPOINT_WIDTH_PX will cause the outcomes list to appear below the
// questions instead of on the side.
const BREAKPOINT_WIDTH_PX = 200;

@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'alii-web-model-detail',
    templateUrl: './model-detail.component.html',
    styleUrls: ['./model-detail.component.scss']
})
export class ModelDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input()
    parentId: string;

    @Input()
    findings: Question[];

    @Input()
    outcomes: Outcome[];

    @Input()
    tagList: any[];

    @Input()
    modelSource: string;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input()
    populationId: string;

    @Input()
    paragraphId: string;

    @Input()
    paragraph: Paragraph;

    @Input()
    showIdentifiers: boolean;

    @Input()
    sidebarCollapsed: boolean;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('content') contentWindow: ElementRef;

    arrayIsCollapsedPopulations: boolean[];
    showModel = true;
    showAllOutcomes = false;
    isEditAble = false;

    showMoreOutcomes = false;

    // Side-by-side outcomes view
    outcomesView: OutcomesView;
    outcomesShowToggleView = environment.featureFlags.outcomesShowToggleView;
    outcomesSideBySide = environment.featureFlags.outcomesSideBySide;

    // Typeahead dropdown list
    dropdownOptions = {};
    selectedOptions = {};
    originalOptions = {};
    // A value of -1 means disabled.
    typeaheadMaxOptions = environment.featureFlags.typeaheadMaxOptions || 10;

    // TODO: Flags for tracking pending calls for displaying/hiding spinner, this should
    // be done using the store state changes instead.
    outcomeListPending = false;
    resetModelPending = false;
    updateModelPending = false;
    showAllOutcomesPending = false;
    activeFilters: any[] = [];
    subscriptions: Subscription[] = [];

    lang = {};
    visibleInputFields = [];

    currentQuestionIndex = 0;

    @Input() currentQuestionTitle: string;

    @ViewChild('hostElement', { static: false }) hostElementRef: ElementRef;

    // Development only
    private development: boolean;
    private updateModelRequest: any;
    private getProtocolRequest: any;
    outcomeVisibilityMap: Map<any, boolean> = new Map<any, boolean>();


    @HostListener('window:resize', ['$event']) handleResize() {
        if (this.outcomesSideBySide) {
            this._handleResize();
        }
    }

    constructor(
        private paragraphService: ParagraphsService,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private hostElement: ElementRef,
        private cdr: ChangeDetectorRef,
        private translateService: TranslateService,
        // Development only
        private modelsService: ModelsService,
        private protocolsService: ProtocolsService
    ) {}

    ngOnInit(): void {
        this.outcomes =  JSON.parse(JSON.stringify(this.outcomes));

        this.outcomes.forEach(outcome => {
            outcome.showRows = false;
          });
        
        this.activeFilters = []

        // this.development = !environment.production;

        this.outcomesView = this.outcomesSideBySide ? 'vertical' : 'horizontal';
        this.arrayIsCollapsedPopulations = Array.isArray(this.populations)
            ? this.populations.reduce((acc, pop) => {
                  return {
                      ...acc,
                      [pop.id]: true
                  };
              }, {})
            : {};

        // Language translations
        const words = [
            'title',
            'test',
            'showall',
            'reset',
            'information',
            'select.single',
            'select.multiple',
            'treeview'
        ];
        const keys = words.map(w => 'MODEL.DETAIL.' + w.toUpperCase());
        this.subscriptions.push(
            this.translateService
                .stream(keys)
                .subscribe(results =>
                    Object.keys(results).forEach((key, index) => (this.lang[words[index]] = results[key]))
                )
        );

        // Development only
        if (this.development) {
            // Some sanity checks, just in case.
            this._sanityChecks();
        }
    }




    thumbLeftPos(option) {
        return 'calc(' + (+option.complex_value.start)*5 + '% - 20px)'
    }


    thumbWidth(option) {
        let endval = 11;
        if (!!option.complex_value.end) {
            endval = +option.complex_value.end;
        }
        let startval = 11;
        if (!!option.complex_value.start) {
            startval = +option.complex_value.start;
        }

        return 'calc(' + ((endval-startval)*5) + '%)'
    }

   
    ngAfterViewInit() {
        if (!this.getCurrentQuestionTitle()) {
            this.setCurrentQuestionTitle('Waar heb je last van?');
        }

        this.setOutcomeLists();
        if (this.outcomesSideBySide) {
          setTimeout(() => {
            this._handleResize();
            // Must mark to force re-render initial resize call.
            this.cdr.markForCheck();
          }, 200);
        }
      }


    showVragenlijstenSelector(outcomes) {
        return outcomes?.length > 0;
    }



    akwaHasNextQuestion(title: string) {
        if (title === 'Diagnose (algemeen probleem)' ||
            title === 'Thema (specifiek probleem)' 
        )
        {
            return false;
        }

        var nextQ = this.akwaGetNextQuestion(title);
        return !!nextQ;
    }
    
    otherStandardList(title = "Andere standaard vragenlijst"): string | undefined {
        let jsonObject = this.findings;
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {                
                const item = jsonObject[key];
                if (item.hasOwnProperty("protocol_paragraph.title") && item["protocol_paragraph.title"] === "Welke vragenlijsten worden standaard gebruikt?") {
                for (const optionId in item.options) {
                    if (item.options.hasOwnProperty(optionId) && item.options[optionId].title === title) {
                        return item.options[optionId].value;
                    }
                }
                }
            }
        }
        return undefined;
    }

    akwaShowQuestion(title: string) {
        if (title === 'Welke onderwerpen wil je evalueren met een vragenlijst?') {
            title = 'Diagnose (algemeen probleem)';
        }

        const changed = this.getCurrentQuestionTitle() !== title;
        this.setCurrentQuestionTitle(title);

        if (changed) {
            const allQuestions = Array.from(document.getElementsByClassName('question-title')) as HTMLElement[];
            if (title === 'Diagnose (algemeen probleem)') {
                title = 'Welke onderwerpen wil je evalueren met een vragenlijst?';
            }
    
            const currentIndex = allQuestions.findIndex(question => question.innerText === title);
            const q =  allQuestions[currentIndex];
            setTimeout(() => {
                q.scrollIntoView({ behavior: "smooth"});    
            }, 100);
        }
        
        return;
    }


    questionIndex(title: string) {
        const allQuestions = Array.from(document.getElementsByClassName('question-title')) as HTMLElement[];
        const currentIndex = allQuestions.findIndex(question => question.innerText === title);
        return currentIndex;
    }

    isDone(title: string) {
        return this.questionIndex(title) < this.questionIndex(this.getCurrentQuestionTitle());
    }

    akwaGetNextQuestion(title: string) {

        if (title === 'Levensgebied (je functioneren/kwaliteit van leven)') {
            title = 'Welke onderwerpen wil je evalueren met een vragenlijst?'
        }

        const allQuestions = Array.from(document.getElementsByClassName('question-title')) as HTMLElement[];
        const currentIndex = allQuestions.findIndex(question => question.innerText === title);
        
        if (currentIndex === -1 || currentIndex === allQuestions.length - 1) {
          return null;
        }

               
        let nextTitle  = allQuestions[currentIndex + 1].innerText;
        if (nextTitle === "Welke onderwerpen wil je evalueren met een vragenlijst?") {
            return 'Diagnose (algemeen probleem)'
        }

        
        return nextTitle;
      }


    getTitle(title) {
        return title.split("--")[0];
    }


    akwaShowNextQuestion(title: string) {
        console.log({title})
        var nextQ = this.akwaGetNextQuestion(title);
        this.akwaShowQuestion(nextQ);
    }

    getCurrentQuestionTitle() {
        return this.currentQuestionTitle;
        //return window.sessionStorage.getItem('akwaCurrentTitle');
    }

    setCurrentQuestionTitle(title:string) {
        this.eventBus.emit({
            type: "setCurrentQuestionTitle",
            payload: {
                "title": title
            }
        });
    }

    setOutcomeLists() {
        this.outcomes.forEach((outcome)=> {
            if (outcome.outcomeList) {
                outcome.outcomeList.forEach(element => {
                    this.outcomeListSelected[element.id] = element.selected
                });
            }
        } )
    }


    get filterDuplicateOutcomes() {
        const filteredOutcomes = JSON.parse(JSON.stringify(this.outcomes));
      
        const standard = filteredOutcomes
          ?.find((x) => x.title === "Vaste vragenlijsten die worden gebruikt in de organisatie")
          ?.outcomeList.map((x) => x.title);
      
        if (standard) {
          filteredOutcomes.forEach((item) => {
            if (item.title === "Advies" || item.title === "Andere opties") {
              item.outcomeList.forEach((y) => {
                if (standard.includes(this.getTitle(y.title))) {
                  y.hidden = true;
                }
              });
            }
          });
        }
      
        return filteredOutcomes;
      }

    showStandaardVragenlijst() {
        return this.outcomes?.findIndex(x => x.title === "Show: standaard vragenlijst") !== -1;
    }

    showAndersNamelijk() {
        return this.outcomes?.findIndex(x => x.title === "Show: anders namelijk") !== -1;
    }

    showWatNogMeer() {
        return this.outcomes?.findIndex(x => x.title === "Show: wat nog meer") !== -1;
    }

    showSelector() {
        return this.outcomes?.findIndex(x => x.title === "Show: selector") !== -1;
    }


    // the show next model for now is only implemented for the rapp. that's why this 
    // hardcoded guid is in here
    showNextModelLink() {
        return window.location.href.indexOf("90ce114f-69fa-4364-a2a0-efbb57733e76") > -1;
    }

    ngOnChanges(changes: SimpleChanges) {
        // Update typeahead when changes to the findings have been detected.
        if (changes.findings) {
            this._initTypeahead();
            // for akwa sometimes the ui resets after te first click, this fix always makes sure the right question is open
            this.akwaShowQuestion(this.getCurrentQuestionTitle());
            
            // work around for next question button nothing responding to updated answers
            setTimeout(() => {
                this.cdr.detectChanges();
            }, 100);
        }

        // Development only
        if (this.development) {
            const { findings } = changes;
            if (findings && findings.currentValue && !findings.firstChange) {
                if (this.getProtocolRequest) {
                    this._checkGetProtocol(findings.currentValue);
                }
                if (this.updateModelRequest) {
                    this._checkUpdateModel(findings.currentValue);
                }
            }
        }

        if (changes.outcomes) {
            const outcomes = changes.outcomes.currentValue;
            if (outcomes) {
                if (this.resetModelPending) {
                    if (!outcomes.length) {
                        this.resetModelPending = false;
                        this.setOutcomeLists();
                    }
                }
                if (this.updateModelPending) {
                    this.updateModelPending = false;
                    this.setOutcomeLists();
                }
                if (this.showAllOutcomesPending) {
                    this.showAllOutcomesPending = false;
                }
                if (this.outcomeListPending) {
                    this.outcomeListPending = false;
                }
                setTimeout(() => {
                    this.applyFilter()
                    }, 0);
                
            }
        }

        if (changes.sidebarCollapsed && !changes.sidebarCollapsed.firstChange) {
            setTimeout(() => {
                this._handleResize();
                // Must mark to force re-render initial resize call.
                this.cdr.markForCheck();
            }, 1000);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    formatGradeKey(key: string) {
        return key.replace(/_/g, ' ').replace('GRADE - ', '');
    }

    // TODO: Duplicated code paragraph-footer.component.ts
    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                // TODO: fix this in backend
                const value = finding[key].toString().replace('illegal reference: ', '');
                return {
                    key,
                    value
                };
            });
    }

    onClickRelatedParagraph(paragraphId) { 
        var element = document.getElementById("heading-" + paragraphId)
        element.scrollIntoView()
    }

    onSelectOutcome(outcome) {
        if (!outcome.outcomeList) {
            const outcomeId = outcome.id;
            this.outcomeSelected[outcomeId] = !this.outcomeSelected[outcomeId];

            const action = {
                type: 'onSelectOutcome',
                payload: {
                    outcomeId,
                    selected: this.outcomeSelected[outcomeId]
                }
            };

            this.eventBus.emit(action);
        }
    }

    onSelectOutcomeList(outcomeList) {
        const outcomeId = '' + outcomeList.id;
        const modelId = this.paragraph.id;
        const populationId = this.populationId;
        this.outcomeListSelected[outcomeId] = !this.outcomeListSelected[outcomeId];
        this.outcomeListPending = true;
        
        const action = {
            type: 'onSelectOutcomeList',
            payload: {
                populationId,
                outcomeId,
                modelId,
                action: outcomeList.selectionType === 'select' ? 'select' : outcomeList.selected ? 'remove' : 'add'
            }
        };

        this.eventBus.emit(action);
    }

    onShowMoreOutcomes(event: MouseEvent) {
        this.showMoreOutcomes = !this.showMoreOutcomes;
        event.stopPropagation();
        return false;
    }

    onSelectDate(date: NgbDate, i, option, findingId) {
        this.updateModelPending = true;
        const id = option.id;
        const value = date.day+"/"+date.month+"/"+date.year;

        let payload = { option: { id, value, selected: true} };
        this.eventBus.emit({
            type: 'onUpdateModel',
            payload: {
                ...payload,
                showMessageIsLoading: this.outcomesView === 'horizontal',
                modelId: this.paragraphId,
                findingId,
                ...(this.populationId && { populationId: this.populationId })
            }
        });
    }

    isVisibleInput(id) {
        var element = <HTMLInputElement> document.getElementById(id);
        if (element && element.value)
            return true;

        return this.visibleInputFields.indexOf(id) !== -1;
    }

    getLastVisibleInputIndex(type:string): number {
        for (let i =6; i >= 0; i--) {
            if (this.isVisibleInput(type + i)) {
                return i;
            }
        }
        return 1;
    }

    deleteInput(id, option, finding) {
        this.visibleInputFields = this.visibleInputFields.filter(item => item !== id);
        let next = <HTMLInputElement>document.getElementById(id);
        if (next) {
            next.value = '';
            this.changeOption('', option, finding.id, 'string');
        }
   }

   deleteTarget(id, option, finding) {
        this.visibleInputFields = this.visibleInputFields.filter(item => item !== id);
        this.changeOption('', option, finding.id, 'akwa-evaluation', 'goal');    
        this.changeOption('', option, finding.id, 'akwa-evaluation', 'goal-delete');    
    }
    


    handleEnterKey(complaintNumber, type) {
        this.changeOrEnterHit(complaintNumber, type);
    }


    changeOrEnterHit(complaintNumber, type) {
        let next = type + (complaintNumber + 1); 
        let current = type + complaintNumber;
        let currentElem = <HTMLInputElement>document.getElementById(current);
      
        if (currentElem.value.trim() === '') {
        //   const messageDiv = document.createElement('div');
        //   messageDiv.textContent = 'Dit veld mag niet leeg zijn';
        //   messageDiv.classList.add('fade-in-out'); // Apply the CSS class
        //   currentElem.parentNode.insertBefore(messageDiv, currentElem.nextSibling);
      
        //   // Trigger the fade-in effect by adding the "show" class
        //   setTimeout(() => {
        //     messageDiv.classList.add('show');
        //   }, 10); // Delay to ensure the transition effect works
      
        //   // Remove the message and trigger fade-out after 1.5 seconds
        //   setTimeout(() => {
        //     messageDiv.classList.remove('show'); // Trigger fade-out effect
        //     setTimeout(() => {
        //       currentElem.parentNode.removeChild(messageDiv);
        //     }, 500); // Wait for the fade-out animation to finish
        //   }, 1500);
      
          return;
        }
      
        if (!this.isVisibleInput(next)) {
          this.visibleInputFields.push(next)
          setTimeout(() => {
            let nextElem = document.getElementById(next);
            console.log({ nextElem });
            nextElem.focus();
          }, 50);
        }
        this.cdr.detectChanges();
        return;
      }
      

    removeRange(range) {
        this.visibleInputFields = this.visibleInputFields.filter(x => x !== range);
    }


      toggleRows(outcome: any): void {
        const isVisible = this.outcomeVisibilityMap.get(outcome) || false;
        this.outcomeVisibilityMap.set(outcome, !isVisible);
      }
      
      isRowVisible(outcome: any): boolean {
        return this.outcomeVisibilityMap.get(outcome) || false;
      }

      
      changeOption(value, option, findingId, questionType = '', complexAnswerType = '') {
        let payload;
        const id = option.id;
        let multiple = questionType === 'multiple';

        if (questionType === 'akwa-evaluation') {          
            payload = { multiple: true, option: { id, complex_value: value, complex_value_type: complexAnswerType, selected: (complexAnswerType !== 'goal-delete')  } };
        }
        else if (option.type === 'continuous') {
            // slider? set value
            payload = { option: { id, value, selected: value !== '' } };
        } else if (option.type === 'string') {
            let multiple = false;
            
            if (option.title.includes('Welke problemen ervaar je?') !== -1  || option.title.includes('Wat gaan jullie doen?') !== -1 ) {
                multiple = true;
            }
                     
            payload = { multiple, option: { id, value, selected: value !== '' } };                
        }
        else {
            // (multi-)select? toggle selection
            payload = { multiple, option: { id, selected: !option.selected } };
        }
        
        this.updateModelPending = true;
        this.showAllOutcomes = false
        console.log({payload})
        this.eventBus.emit({
            type: 'onUpdateModel',
            payload: {
                ...payload,
                showMessageIsLoading: this.outcomesView === 'horizontal',
                modelId: this.paragraphId,
                findingId,
                ...(this.populationId && { populationId: this.populationId })
            }
        });
    }


    onChangeOption(event, i, option, findingId, questionType = '', complexAnswerType = '') {
        this.changeOption(event.target.value, option, findingId, questionType, complexAnswerType);
    }

    onClickArticle(article) {
        const { articleId } = article;

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);
    }

    onShowHelpText(event, helpText, identifierText) {
        if (identifierText) {
            helpText = helpText + identifierText;
        }
        const helpTextAction = {
            type: 'openHelpText',
            payload: {
                helpText
            }
        };

        this.eventBus.emit(helpTextAction);
        event.stopPropagation();
        return false;
    }

    openOutcomeTreeview(event, outcome: Outcome) {
        const openTreeAction = {
            type: 'openOutcomeTreeview',
            payload: {
                outcome,
                populationId: this.populationId
            }
        };
        this.eventBus.emit(openTreeAction);
        event.stopPropagation();
        return false;
    }

    openOutcomeEdit(event, outcome: Outcome) {
        const openOutcomeEditAction = {
            type: 'openOutcomeEdit',
            payload: {
                outcome,
                populationId: this.populationId,
                outcomeListSelected: this.outcomeListSelected,
                modelId: this.paragraph.id
            }
        };
        this.eventBus.emit(openOutcomeEditAction);
        event.stopPropagation();
        return false;
    }

    resetModel() {
        this._initTypeahead();
       
        // Display the spinner until completed, see ngOnChanges.
        this.resetModelPending = true;
        this.eventBus.emit({
            type: 'resetModel',
            payload: { modelId: this.paragraphId }
        });

    }

    hasOutcomeIcon(outcome) {

        let type = outcome.type
        if (outcome.icon) {
            if(outcome.icon === 'recommendation')   { 
                return false
            }
            return true
        }
        else if (type === 'other' ||
            type === 'not recommended'||
            type === 'exclamation'||
            type === 'diagnostic'||
            type === 'therapeutic'
            ) {
                return true
        } 
        return false
    }

    outcomeIcon(outcome) {
        if (outcome.icon === 'not recommended')
            {return 'icon-minus-circle shiftLeft';}
        else if (outcome.icon === 'exclamation')
            {return 'icon-exclamation-triangle shiftLeft';}
        else if (outcome.icon === 'diagnostic')
            {return 'icon-heartbeat shiftLeft';}
        else if (outcome.icon === 'therapeutic')
            {return 'icon-medkit shiftLeft';}
        return 'icon-check shiftLeft';
    }

    resetPatient() {
        this.eventBus.emit({
            type: 'handleResetPatient',
            payload: ''
        });
        this.setOutcomeLists()
        return false;
    }

    newFindings(sources) {
        sources.forEach(source => {
            source.finding.forEach(finding => {
                if (finding.status === 'new') {
                    return true;
                }
            });
        });
        return false;
    }

    isActiveTag(tag) {
        const index = this.activeFilters.indexOf(tag.title, 0);
        return index > -1 
    }

    hasActiveFilters(tag) {
        return this.activeFilters.length
    }

    resetFilters() {
        this.activeFilters = []
        const outcomes = document.querySelectorAll('.outcomeItem');
        outcomes.forEach(e => {
            e.parentElement.parentElement.classList.remove('hidden');
        })
    }

    applyFilter() {
        if (this.activeFilters.length) {
            const outcomes = document.querySelectorAll('.outcomeItem');
            outcomes.forEach(e => {
                e.parentElement.parentElement.classList.add('hidden');
                this.activeFilters.forEach(filter => {
                    if(e.getElementsByClassName(filter).length) {
                        e.parentElement.parentElement.classList.remove('hidden');
                    }
                }) 
            })
        } else { this.resetFilters() }
    }

    filterOutComes(tag) {
        this.activeFilters = [tag.title];

        /*
        // the old way allowed for more than 1 filter at the 
        // same time, but for the time being we disabled this
        // feature by customer request.

        const index = this.activeFilters.indexOf(tag.title, 0);
        if (index > -1) {
            this.activeFilters.splice(index, 1);
        } else {
            this.activeFilters.push(tag.title);
        }
        */
        this.applyFilter()
    }


    linkToNextModel(){
        return "/protocols/6a94e5aa-fe4c-4462-9f6c-c1ff53bd3188?version=Current&populationId=" + this.populationId 
    }

    getScoreColor(score) {
        const colors = ["#990000", "#E5841E", "#B6D7A8", "#35824E"];
        return colors[score-1];
    }

    getColoredBalls(score) {
        return Array(+score).fill(0).map((x,i)=>i);
    }

    getGreyBalls(score) {
        let noScore = 4 - +score
        return Array(noScore).fill(0).map((x,i)=>i);
    }

    outcomeSelected(outcomeId) {
        return outcomeId in this.outcomeSelected && this.outcomeSelected[outcomeId];
    }

    outcomeListSelected(outcomeListId) {
        return outcomeListId in this.outcomeListSelected && this.outcomeListSelected[outcomeListId];
    }

    togglePopulationProperty(populationId) {
        this.populationPropertiesVisible[populationId] = !this.populationPropertiesVisible[populationId];
    }

    populationPropertiesVisible(populationId) {
        return populationId in this.populationPropertiesVisible && this.populationPropertiesVisible[populationId];
    }

    toggleFindings(articleId) {
        this.findingsVisible[articleId] = !this.findingsVisible[articleId];
    }

    findingsVisible(articleId) {
        return articleId in this.findingsVisible && this.findingsVisible[articleId];
    }

    toggleShow() {
        this.showModel = !this.showModel;
    }

    numberToList(num) {
        return Array.from(Array(+num), (x, i) => i);
    }

    objectToArray(o) {
        return o ? Object.values(o) : [];
    }

    onToggleShowAllOutcomes() {
        this.showAllOutcomes = !this.showAllOutcomes;

        if(this.showAllOutcomes) {
            this.showAllOutcomesPending = true;
        }

        const action = this.showAllOutcomes
            ? {
                  type: 'handleShowAllOutcomes',
                  payload: {
                      modelId: this.paragraphId
                  }
              }
            : {
                  type: 'handleShowOutcomesByPopulationId',
                  payload: {
                      modelId: this.paragraphId
                  }
              };

        this.eventBus.emit(action);
    }

    safeText(text) {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }

    iconOutcomeType(type: OutcomeType) {
        let result = '';
        const list: Array<{ type: OutcomeType; name: string | null }> = [
            { type: 'recommendation', name: null },
            { type: 'warning', name: 'exclamation' },
            { type: 'information', name: 'info' },
            { type: 'selection', name: null }
        ];

        const found = list.find(item => item.type === type);
        if (found && found.name) {
            result = `<span class="button__icon"><span class="icon-${found.name}"></span></span>`;
        }

        return this.sanitizer.bypassSecurityTrustHtml(result);
    }

    clickIconOutcomeType(event: MouseEvent, type: OutcomeType) {
        event.stopPropagation();
        return false;
    }

    findingsObjectToArray(findingsObject) {
        const findings = this.objectToArray(findingsObject);
        findings.sort((a, b) => (a['index'] > b['index'] ? 1 : -1));
        return findings;
    }

    sortByScore(list: any[]): any[] {
        return list.slice().sort((a, b) => (a.score < b.score ? 1 : b.score < a.score ? -1 : 0));
    }

    sortByTitle(list: any[]): any[] {
        return list.slice().sort((a, b) => (a.title < b.title ? -1 : b.title < a.title ? 1 : 0));
    }

    getOptionId(i: number, j: number) {
        return `option-${i}-${j}`;
    }

    // Typeahead: show or hide the given typeahead dropdown list.
    showTypeahead(i: number) {
        return this.typeaheadMaxOptions === -1
            ? false
            : this.dropdownOptions[i].length &&
                  this.dropdownOptions[i].length + this.selectedOptions[i].length > this.typeaheadMaxOptions;
    }

    // --- TOGGLE OUTCOMES VIEW --- //

    // ToggleOutcomesView: switch between horizontal and vertical views.
    toggleOutcomesView() {
        this.outcomesView = this.outcomesView === 'vertical' ? 'horizontal' : 'vertical';
    }

    // --- TYPEAHEAD DROPDOWN --- //

    // Typeahead: option has been selected from the typeahead dropdown list, move it to list above and click in
    // order to select.
    onSelected(event: any) {
        const { id, item } = event;
        const ids = id.split('-');
        const i = ids[ids.length-1];
       
        if (ids[1]) {
            const i = ids[ids.length-1];
            if (item.name.includes("binnenkort beschikbaar")) {
                return false;
            }
            this.selectedOptions[i].push(item);
            this.dropdownOptions[i] = this.dropdownOptions[i].filter(option => option !== item);
            const j = this.originalOptions[i].findIndex(x => x.name == item.name);
            this.clickOption(i, j);
        } else {
            console.warn(`${cn} onSelected invalid id='${id}'`);
        }
    }

    // Typeahead: whether or not to the option is displayed above the typeahead dropdown list.
    isSelected(i, name) {
        if (this.selectedOptions[i]) {
            return this.selectedOptions[i].find(x => x.name == name);
        } else {
            return false;
        }
    }

    // Typeahead: whether or not to display the option above the typeahead dropdown list.
    showOption(i: number, name: string) {
        if (this.showTypeahead(i)) {
            return this.selectedOptions[i].find(x => x.name == name);
        } else {
            return true;
        }
    }

    // Typeahead: given option is moved back to the typeahead dropdown list and removed from view.
    onClose(i, name) {
        this.selectedOptions[i] = this.selectedOptions[i].filter(option => option.name !== name);

        // Retain the original order when moving options back.
        this.dropdownOptions[i] = [];
        this.originalOptions[i].forEach(option => {
            if (!this.selectedOptions[i].includes(option)) {
                this.dropdownOptions[i].push(option);
            }
        });
    }

    clickOption(i: number, j: number) {
        // Attempt to access option element every 200ms until either it is found or max time has been
        // reached, then click the option.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        const sel = '#' + this.getOptionId(i, j);


        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            const el = this.hostElement.nativeElement.querySelectorAll(sel);
            if (el && el[0]) {
                // Found option so click it.
                const option = el[0];
                option.click();
                clearInterval(timerId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} clickOption(i=${i},j=${j}) cannot find option sel='${sel}'`);
                    clearInterval(timerId);
                }
            }
        }, TIMEOUT);
    }

    get pending() {
        return this.resetModelPending || this.updateModelPending || this.showAllOutcomesPending;
    }

    // --- Keep icon question glued to end of string: start --- //

    // Don't allow icon to appear alone on a separate line, ensure that it is always preceded by at least one word. This
    // is done by splitting the given text using the startOf() and endOf() functions and inserting the last word and the
    // icon within spans with whitespace is nowrap style.

    // Return the first words of a string not including the last word. If null, empty string or only one word,
    // then return the empty string.
    startOf(text: string) {
        text = text || '';
        const words = text.split(' ');
        return words.splice(0, words.length - 1).join(' ');
    }

    // Return the last word of a string. If null or empty string, then return the empty string. If only one word,
    // then return the word.
    endOf(text: string) {
        text = text || '';
        const words = text.split(' ');
        return words.length ? words.splice(words.length - 1).join(' ') : '';
    }

    // --- Keep icon question glued to end of string: end --- //

    trackByFn = (index, item) => item.id || index;

    // Typeahead: initialize the typeahead dropdown if needed, e.g. when number of options greater than max,
    // and populate the dropdown with unselected options and display the remaining above the dropdown.
    private _initTypeahead() {
        this.findingsObjectToArray(this.findings).forEach((finding: any, i) => {
            this.dropdownOptions[i] = [];
            this.selectedOptions[i] = [];
            this.originalOptions[i] = [];
            const options = this.objectToArray(finding.options).filter((option: any) =>
                ['categorical', 'discrete'].includes(option.type)
            );
            if (this.typeaheadMaxOptions !== -1 && options.length > this.typeaheadMaxOptions) {
                options.forEach((option: any) => {
                    const name = option.title;
                    const synonyms = option.synonyms || [];
                    this.originalOptions[i].push({name, synonyms});
                    if (option.selected) {
                        this.selectedOptions[i].push({name, synonyms});
                    } else {
                        this.dropdownOptions[i].push({name, synonyms});
                    }
                });
            }
        });
    }

    public akwaUpdateFrequencyText(event, outcomeId) {
        const payload =  { outcomeId, populationId: this.populationId, text: event.target.value, modelId: this.paragraphId};
        const action = {
            type: 'handleOutcomeEdit',
            payload
        };
        this.eventBus.emit(action);
    }


    public akwaThemesText(text) {
        let goal = this.akwaGoalText(text);
        var targetIndex = goal.indexOf('meten');
        if (targetIndex !== -1) {
            return goal.substring(targetIndex+5);
        }
        
        return goal;
    }

    akwaGetGoalTextVaste(title) {
        if (title === "Anders, namelijk")
            return "";
        
        for (const parent of this.outcomes) {
          for (const outcome of parent.outcomeList) {
            if (outcome.title.includes(title) && this.akwaGoalText(outcome.text)) {
              return this.akwaGoalText(outcome.text);
            }
          }
        }
        return "Deze vragenlijst meet geen van de gekozen onderwerpen";
      }

    public akwaGoalTitle(text) {
        if (text === 'Anders, namelijk') {
            return this.otherStandardList();
        }

        var targetIndex = text.indexOf('--');
  
        if (targetIndex !== -1) {
            return text.substring(0, targetIndex);
        }
        
        return text;
    }

    public akwaMinuteText(text) {
        var startTargetIndex = text.indexOf('de invultijd wordt geschat op');
      
        if (startTargetIndex !== -1) {
          var endTargetIndex = text.indexOf('|');
      
          if (endTargetIndex === -1) {
            endTargetIndex = text.length; // Set end index to end of the line if '|' is not found
          }
      
          return text.substring(startTargetIndex + 29, endTargetIndex);
        }
      
        return '';
      }

    public akwaPdfLink(text) {
        var startTargetIndex = text.indexOf('||');
  
        if (startTargetIndex !== -1) {
            var startTargetIndex = text.indexOf('|')+2
            return text.substring(startTargetIndex);
        }

        var startTargetIndex = text.indexOf('|');

        if (startTargetIndex !== -1) {
            return text.substring(startTargetIndex+1);
        }
        
        return '';
    }

    public akwaGoalText(text) {
        var targetIndex = text.indexOf(', de invultijd wordt geschat op');
  
        if (targetIndex !== -1) {
            return text.substring(0, targetIndex);
        }
        
        return '';
    }

    private _handleResize() {
        
        const width = this.contentWindow.nativeElement.clientWidth;
        const outcomesView = width < BREAKPOINT_WIDTH_PX ? 'horizontal' : 'vertical';
        if (this.outcomesView !== outcomesView) {
            this.outcomesView = outcomesView;
        }

        if (this.outcomesView === 'vertical') {
            // Attempt to access elements every 200ms until either they are found or max time has been reached.
            const TIMEOUT = 200;
            const MAX = 5000;
            let msecs = 0;
            // TODO: Replace with RXJS timer take until which is more elegant.
            const timerId = setInterval(() => {
                if (width < BREAKPOINT_WIDTH_PX) {
                    // Window width decreased too small in the meantime, so we can stop looking.
                    clearInterval(timerId);
                } else {
                    const elModelViewOuterBlock = document.getElementById('model-view-outer-block-' + this.paragraphId);
                    const elOutcomeViewOuterBlock = document.getElementById(
                        'outcome-view-outer-block-' + this.paragraphId
                    );
                    const elOutcomeViewInnerBlock = document.getElementById(
                        'outcome-view-inner-block-' + this.paragraphId
                    );
                    if (elModelViewOuterBlock && elOutcomeViewOuterBlock && elOutcomeViewInnerBlock) {
                        // Found.
                        if (elOutcomeViewOuterBlock.offsetHeight < elModelViewOuterBlock.offsetHeight) {
                            // The height of the outcome block has become smaller then the height of the model
                            // block so we need to reset the blocks to have equal height for scrolling.
                            this.renderer.setStyle(
                                elOutcomeViewOuterBlock,
                                'height',
                                elModelViewOuterBlock.offsetHeight + 'px'
                            );
                        }
                        // Enable outcomes to scroll by enabling the class if not already present.
                        if (!elOutcomeViewOuterBlock.classList.contains('outcome-view-outer-block')) {
                            this.renderer.addClass(elOutcomeViewOuterBlock, 'outcome-view-outer-block');
                        }
                        if (!elOutcomeViewInnerBlock.classList.contains('outcome-view-inner-block')) {
                            this.renderer.addClass(elOutcomeViewInnerBlock, 'outcome-view-inner-block');
                        }
                        clearInterval(timerId);
                    } else {
                        // Not found.
                        msecs += TIMEOUT;
                        if (msecs >= MAX) {
                            console.warn(`${cn} _setupInnerScrolling: cannot find elements`);
                            clearInterval(timerId);
                        }
                    }
                }
            }, TIMEOUT);
        }
    }

    // Development only
    private _sanityChecks() {
        // Check get protocols
        this.subscriptions.push(
            this.protocolsService.request$.subscribe(request => {
                if (request && request.response && request.action === 'getProtocol') {
                    this.getProtocolRequest = request;
                    this._checkGetProtocol(this.findings);
                }
            })
        );

        // Check update model
        this.subscriptions.push(
            this.modelsService.request$.subscribe(request => {
                if (request && request.response && request.action === 'updateModel') {
                    this.updateModelRequest = request;
                }
            })
        );
    }

    // Development only
    private _checkGetProtocol(findings) {
        let found;
        this.getProtocolRequest.response.paragraphs.forEach(paragraph => {
            if (!found) {
                found = paragraph.children.find(child => this.paragraph.id === child.id);
            }
        });
        if (found) {
            // const arrHide: boolean[] = [];
            const questions: Question[] = [];
            Object.keys(findings).forEach(questionId => {
                questions.push(findings[questionId]);
                // arrHide.push(findings[questionId].hide);
            });
            // console.log(`${JSON.stringify(arrHide)} ${cn} getProtocol$`);

            // const a1 = JSON.stringify(arrHide);
            // const a2 = JSON.stringify(found.questions.map(question => question.hide));
            // console.log(`${a2} ${cn} getProtocol$ => ${a1 === a2 ? 'OK' : 'NOK'}`);
            this._compareQuestions(questions, found.questions, 'getProtocol$');
        } else {
            console.warn(`${cn} getProtocol$ cannot find paragraph with id='${this.paragraph.id}'`);
        }
        this.getProtocolRequest = null;
    }

    private _checkUpdateModel(findings) {
        const found = this.updateModelRequest.response.updated_paragraphs.find(
            paragraph => this.paragraph.id === paragraph.id
        );
        if (found) {
            // const arrHide: boolean[] = [];
            const questions: Question[] = [];
            Object.keys(findings).forEach(questionId => {
                questions.push(findings[questionId]);
                // arrHide.push(findings[questionId].hide);
            });
            // console.log(`${JSON.stringify(arrHide)} ${cn} updateModel$`);

            // const a1 = JSON.stringify(arrHide);
            // const a2 = JSON.stringify(found.questions.map(question => question.hide));
            // console.log(`${a2} ${cn} updateModel$ => ${a1 === a2 ? 'OK' : 'NOK'}`);
            this._compareQuestions(questions, found.questions, 'updateModel$');
        } else {
            console.warn(`${cn} updateModel$ cannot find paragraph with id='${this.paragraph.id}'`);
        }
        this.updateModelRequest = null;
    }

    // Development only
    private _compareQuestions(q1: Question[], q2: Question[], fn: string) {
        const _showDiffs = (q1, q2) => {
            const _diff = (d: Record<string, unknown>, s: string) => {
                let result = null;
                if (d[s]) {
                    const js = JSON.stringify(d[s]);
                    if (js !== '{}') {
                        result = `${s}: ${js}`;
                    }
                }
                return result;
            };
            const d = detailedDiff(q1, q2) as Record<string, unknown>;
            console.warn();
            return `{ ${['added', 'deleted', 'updated']
                .map(s => _diff(d, s))
                .filter(result => !!result)
                .join(', ')} }`;
        };

        const _q1 = _.cloneDeep(q1);
        _q1.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
        const _q2 = _.cloneDeep(q2);
        _q2.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));

        _q1.forEach((q, index) => {
            const arr: Option[] = [];
            Object.keys(q.options).forEach(id => {
                arr.push(q.options[id]);
            });
            q.options = arr;
            q.options.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
            _q2[index].options.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
            if (JSON.stringify(diff(q, _q2[index])) !== '{}') {
                // console.log(`q1[${index}]`, q);
                // console.log(`q2[${index}]`, _q2[index]);
                console.warn(`${cn} ${fn} question[${index}] diffs='${_showDiffs(q, _q2[index])}'`);
            }
        });
    }
}
