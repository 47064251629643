<section class="page__section -no-bottom">
    <div class="table-responsive">
        <table class="table -aliiTable">
            <thead>
                <tr>
                    <th class="table__text table__title table__column-30" scope="col">User</th>
                    <th class="table__text table__title table__column-20" scope="col">E-mail</th>
                    <th class="table__text table__column-40" scope="col">Roles</th>
                    <th class="table__text table__column-20" scope="col" *ngxPermissionsOnly="['Admin']">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users; trackBy: trackByFn">
                    <td class="table__text table__title table__actions">
                        <a class="table__link">{{ user.first_name }} {{ user.prefix }} {{ user.last_name }}</a>
                    </td>
                    <td class="table__text" [innerHTML]="user.email"></td>
                    <td class="table__text">
                        <span *ngFor="let role of getRoles(user); trackBy: trackByFn" class="tag">{{ role.title }}</span>
                        &nbsp;
                    </td>
                    <td class="table__actions" *ngxPermissionsOnly="['Admin']">
                        
                            <div class="btn-group" ngbDropdown placement="bottom-right">
                                <select (change)="manageUser(user, $event)" style="border: 0; min-width: 200px">
                                    <option> select action </option>
                                    <option value="setAdmin" *ngIf=!isAdmin(user) > Make Admin </option>
                                    <option value="setReviewer" *ngIf=!isReviewer(user) style="margin-left: 2px"> Make Reviewer </option>
                                    <option value="removeReviewer" *ngIf=isReviewer(user) style="margin-left: 2px"> Remove Reviewer </option>
                                    <option value="setStatistics" *ngIf=!isStatistics(user) style="margin-left: 2px"> Send statistics </option>
                                    <option value="removeStatistics" *ngIf=isStatistics(user) style="margin-left: 2px"> Stop sending statistics </option>
                                    <option value="editUserDetails" style="margin-left: 2px"  *ngxPermissionsOnly="['SuperAdmin']"> Edit user details </option>
                                    <option value="onClickRemoveUser" *ngIf=!isAdmin(user) style="margin-left: 2px"> Remove </option>
                                </select>
                            </div>

                    </td>
                </tr>
                <tr>
                    <td style='height: 200px'></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
