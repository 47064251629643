import * as fromActions from '../actions/models-findings.action';
import produce from 'immer';
import { current } from 'immer'; // for debugging


export interface ModelsFindingsState {
    entries: {};
}

export const initialState: ModelsFindingsState = {
    entries: {}
};

const cn = 'ModelsFindingsReducer';

export function reducer(state = initialState, action: fromActions.ModelsFindingsAction): ModelsFindingsState {
    switch (action.type) {
        case fromActions.MAP_MODELS_FINDINGS: {
            const { findings } = action.payload;

            return { ...state, entries: findings };
        }

        case fromActions.ADD_FINDING_SUCCESS: {
            const { parentId, findings } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                const modelFindings = findings.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});

                if (!(parentId in draft)) {
                    draft[parentId] = {};
                }
                Object.assign(draft[parentId], modelFindings);
            });

            return {
                ...state,
                entries: newState
            };
        }

        case fromActions.UPDATE_FINDING_SUCCESS: {
            const { parentId, ppdId, finding } = action.payload;
            const originalState = state.entries;

            const newState = produce(originalState, draft => {
                Object.assign(draft[parentId][ppdId], finding);
            });

            return {
                ...state,
                entries: newState
            };
        }

        case fromActions.DELETE_FINDING_SUCCESS: {
            const { parentId, ppdId } = action.payload;

            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                delete draft[parentId][ppdId];
            });
            return { ...state, entries: newState };
        }

        case fromActions.USER_UPDATE_MODEL: {
            const { modelId, findingId, option, multiple = false } = action.payload;
            const originalState = state.entries;

            const newState = produce(originalState, draft => {
                const draftOptions = draft[modelId][findingId].options;
                if (!multiple) {
                    Object.keys(draftOptions).forEach(k => (draftOptions[k].selected = false));
                }

                // TODO: For some reason, Import Model by SheetId fails here, need to investigate.
                if (draftOptions[option.id]) {
                    if ('complex_value' in option && draftOptions && draftOptions[option.id]) {
                        if (!draftOptions[option.id].complex_value) {
                            draftOptions[option.id].complex_value = {};
                        }
                        draftOptions[option.id].complex_value[option.complex_value_type] = option.complex_value;
                        draftOptions[option.id].selected = option.selected;
                    } 
                    else {
                        Object.assign(draftOptions[option.id], option);
                    }
                } else {
                    console.warn(`${cn} USER_UPDATE_MODEL draftOptions[${option.id}] is undefined`);
                }
            });

            return { ...state, entries: newState };
        }

        case fromActions.RESET_MODEL: {
            const { modelId } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                Object.keys(draft[modelId]).forEach(findingId => {
                    Object.keys(draft[modelId][findingId].options).forEach(optionId => {
                        const option = draft[modelId][findingId].options[optionId];

                        option.selected = false;

                        if ('value' in option) {
                            option.value = '';
                        }
                    });
                });
            });
            return { ...state, entries: newState };
        }

        case fromActions.UPDATE_FINDINGS: {
            // A finding is the same object type as an entry.
            const { findings } = action.payload;
            const originalFindings = state.entries;

            // Sanity check just in case.
            if (!findings) {
                return {
                    ...state
                };
            }

            const updatedFindings = produce(findings, draftFindings => {
                Object.keys(findings).forEach(findingId => {
                    const finding = findings[findingId];
                    const draftFinding = draftFindings[findingId];
                    if (findingId in originalFindings) {
                        const original = originalFindings[findingId];
                        Object.keys(original).forEach(parId => {
                            if (parId in finding) {
                                draftFinding[parId]['new'] = original[parId]['hide'] !== finding[parId]['hide'];
                            }
                        });
                    }
                });
            });

            return {
                ...state,
                entries: {
                    ...state.entries,
                    ...updatedFindings
                }
            };
        }

        case fromActions.ADD_VALUE_OPTION_SUCCESS: {
            const { modelId, findingId, valueOptionId, title, value } = action.payload;

            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                draft[modelId][findingId].options[valueOptionId] = {
                    id: valueOptionId,
                    min: '',
                    max: '',
                    step: '',
                    type: 'categorical',
                    title,
                    value_for_calculation: value
                };
            });
            return { ...state, entries: newState };
        }
        case fromActions.UPDATE_VALUE_OPTION_SUCCESS: {
            const { modelId, findingId, valueOptionId, title, value } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                Object.assign(draft[modelId][findingId].options[valueOptionId], {
                    title,
                    value_for_calculation: value
                });
            });
            return { ...state, entries: newState };
        }
        case fromActions.REMOVE_VALUE_OPTION_SUCCESS: {
            const { modelId, findingId, valueOptionId } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                delete draft[modelId][findingId].options[valueOptionId];
            });
            return { ...state, entries: newState };
        }
        case fromActions.ADD_SLIDER_SUCCESS: {
            const { modelId, findingId, valueOptionId, type, min, max, step } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                draft[modelId][findingId].options[valueOptionId] = {
                    id: valueOptionId,
                    min,
                    max,
                    step,
                    type: 'continuous',
                    title: '',
                    value_for_calculation: null
                };
            });
            return { ...state, entries: newState };
        }
        case fromActions.UPDATE_SLIDER_SUCCESS: {
            const { modelId, findingId, valueOptionId, type, min, max, step } = action.payload;
            const originalState = state.entries;
            const newState = produce(originalState, draft => {
                Object.assign(draft[modelId][findingId].options[valueOptionId], {
                    min,
                    max,
                    step
                });
            });
            return { ...state, entries: newState };
        }
    }
    return state;
}

export const getModelFindingEntries = (state: ModelsFindingsState) => state.entries;
export const getModelFindingSimplified = (state: ModelsFindingsState) => state.entries;
