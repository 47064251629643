import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges,
    ViewChild,
    ChangeDetectorRef,
    OnInit
} from '@angular/core';

import {  Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import { SearchArticlesModalComponent, AddNonPubmedArticleModalComponent } from '../../entry-components';

import { Literatures } from '../../../../models/literature-collection.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'literatures',
    templateUrl: './literatures.component.html',
    styleUrls: ['./literatures.component.scss']
})
export class LiteraturesComponent implements OnChanges, OnInit {
    @Input() protocolId: string;
    @Input() versions: [];
    @Input() protocolDetails: any;
    @Input() literatures: Literatures;
    @Input() added: string[];
    @Input() pubmeds: any;
    @Input() loading: boolean;

    @Output()


    eventBus: EventEmitter<any> = new EventEmitter<any>();
    searchQuery: string;

    constructor(
        private modalService: NgbModal,
        private router: Router) {}

    @ViewChild('agGrid') agGrid: AgGridAngular;

    ngOnInit() {
        const action = {
            type: 'handleRefreshLiterature',
            payload: {
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pubmeds && !changes.pubmeds.firstChange) {
            if (!changes.pubmeds.currentValue.loading && changes.pubmeds.currentValue.loaded) {
                setTimeout(() => {
                    this.handleOpenSearch();
                });
            }
        }
    }

    getProtocolLink(versions) {
        let params = []
        if(versions && versions.length) {
        versions.forEach(version => {
           if(version.status == "Current") {
                params.push({'version': 'Current'})
           }
           if(version.status == "Draft") {
                params.push({'version': 'Draft'})
            }
        });
        }
        if(!params.length) {params.push(({'version': 'Current'}))}
        return params
    }

    onCellClicked(params) {
        var data =  params.data
        var literatureId = data.id
        this.router.navigate(['/protocols/', this.protocolId, 'literatures', literatureId]);

        const event = {
            type: 'handleViewLiterature',
            payload: {
                protocolId: this.protocolId,
                literatureId
            }
        };
        this.eventBus.emit(event);
    }

    getLiteraturesKeys() {
        return Object.keys(this.literatures);
    }
    
    scrollToSection(id) {
        var el = document.getElementById(id)
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 100);
    }


    isActionsHasAddNew(actions) {
        const hasAddNeww = actions.filter(a => a.title === 'addNew');
        return hasAddNeww.length > 0;
    }

    handleViewLiterature(literatureId) {
        const event = {
            type: 'handleViewLiterature',
            payload: {
                protocolId: this.protocolId,
                literatureId
            }
        };
        this.eventBus.emit(event);
    }

    onClickAddArticle() {
        this.handleClearPubmedSearch();
        this.handleOpenSearch(true);
    }

    onClickAddNonPubmedArticle() {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(AddNonPubmedArticleModalComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            switch (type) {
                case 'handleAddNonPubmedArticle':
                    const literatureEvent = {
                        type: 'handleAddNonPubmedArticle',
                        payload: { ...payload, protocolId: this.protocolId }
                    };
                    this.eventBus.emit(literatureEvent);
                    this.modalService.dismissAll();
                    break;
                default:
                    break;
            }
        });
    }

    handleOpenSearch(clearResult = false) {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(SearchArticlesModalComponent, { size: 'lg' });
        if (clearResult) {
            modalRef.componentInstance.pubmeds = [];
            modalRef.componentInstance.query = '';
        } else {
            modalRef.componentInstance.pubmeds = this.pubmeds && this.pubmeds.results ? this.pubmeds.results : [];
            modalRef.componentInstance.query =
                this.pubmeds && this.pubmeds.query ? this.pubmeds.query : this.searchQuery;
        }
        modalRef.componentInstance.searchLoading = false;

        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            switch (type) {
                case 'handleSubmitSearch':
                    this.handleSubmitSearch(payload);
                    break;
                case 'handleClearPubmedSearch':
                    this.handleClearPubmedSearch();
                    break;
                case 'handleAddArticles':
                    this.handleAddArticles(payload);
                    break;
                default:
                    break;
            }
        });
        modalRef.result.then(
            values => {},
            reason => {}
        );
    }

    defaultColDef: any = {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'minWidth': '100px',
          'align-items': 'center'
        }
      };

    handleSubmitSearch(payload) {
        const { query } = payload;
        const event = { type: 'handleSubmitSearch', payload: { query } };
        this.eventBus.emit(event);
        this.searchQuery = query;
    }
    handleClearPubmedSearch() {
        const event = { type: 'handleClearPubmedSearch', payload: {} };
        this.eventBus.emit(event);
        this.searchQuery = '';
    }
    handleAddArticles(payload) {
        const { uids } = payload;
        const event = {
            type: 'handleAddArticles',
            payload: {
                articleIds: uids
            }
        };
        this.eventBus.emit(event);
        this.modalService.dismissAll();
    }

    trackByFn = (index, item) => item.id || index;
}
