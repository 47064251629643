import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { User } from '../../../../models/user.interface';
import { Organization } from '../../../../models/organization.interface';
import { DashboardSummary } from '../../../../models/dashboard-summary.model';

@Component({
    selector: 'add-user-form',
    templateUrl: './add-user-form.component.html',
    styleUrls: ['./add-user-form.component.scss']
})
export class AddUserFormComponent implements OnInit, OnChanges {
    profile: User;
    @Output() submit: EventEmitter<User> = new EventEmitter<User>();
    @Input() dashboards: DashboardSummary;
    @Input() organisation: Organization;

    addUserForm: FormGroup;

    loadingOrganisation = true;
    loadingDashboards = true;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation && changes.organisation.currentValue) {
            const organisation = changes.organisation.currentValue;
            if (organisation && organisation.participating_hospitals && organisation.participating_hospitals.length) {
                const hospital = this.organisation.participating_hospitals[0];
                this.addUserForm.get('hospitalid').setValue(hospital.hospitalId);
            }
            this.loadingOrganisation = false;
        }
        if (changes.dashboards && changes.dashboards.currentValue) {
            const dashboards = changes.dashboards.currentValue;
            if (dashboards && dashboards.dashboards && dashboards.dashboards.length) {
                const dashboard = this.dashboards.dashboards[0];
                this.addUserForm.get('dashboard').setValue(dashboard.id);
            }
            this.loadingDashboards = false;
        }
    }

    buildForm() {
        this.addUserForm = this.fb.group({
            first_name: ['', Validators.required],
            prefix: [''],
            last_name: ['', Validators.required],
            email: ['', Validators.required],
            teamId: ['', Validators.required],
            hospitalid: ['', Validators.required],
            dashboard: ['', Validators.required],
            setAdmin: ['no']
        });
    }

    handleSubmit() {
        if (this.addUserForm.valid) {
            this.submit.emit(this.addUserForm.value);
        }
    }

    trackByFn = (index, item) => item.id || index;
}
