/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { DashboardSummary } from '../models/dashboard-summary.model';
import {
    CreateCategoryV2DTO,
    CreateCategoryV2RO,
    CreateDashboardV2DTO,
    CreateDashboardV2RO,
    GetAllCategoriesV2RO,
    GetCategoryV2RO,
    GetDashboardV2RO,
    UpdateCategoryV2DTO,
    UpdateCategoryV2RO,
    UpdateDashboardV2DTO,
    UpdateDashboardV2RO
} from '@alii-web/models/dashboard-v2.model';
import { StartingPageActionDashboard, StartingPageRO } from '@alii-web/modules/starting-page/models';

export type DashboardRequestAction = 'getDashboard';
export type DashboardRequestState = 'loading' | 'success' | 'fail';

export interface DashboardRequest {
    action: DashboardRequestAction;
    state: DashboardRequestState;
    response?: any;
    error?: any;
}

const cn = 'DashboardService';

@Injectable()
export class DashboardService {
    private _request$ = new BehaviorSubject<DashboardRequest>(null);
    request$ = this._request$.asObservable();

    private allowCategoryDelete = environment['allowCategoryDelete'];

    private url = `${environment.baseUrl}/api/getDashboard.vm`;

    constructor(private http: HttpClient) {}

    // --- Dashboard API v2 : start --- ///

    getDashboardV2(dashboardId: string): Observable<GetDashboardV2RO> {
        const url = `${environment.baseUrl}/api/v2/organizations/dashboards.vm?dashboardId=${dashboardId}`;
        return this.http.get<GetDashboardV2RO>(url).pipe(
            map(response => {
                // TODO: Cheap way to flag possible errors, later improved in new backend.
                if (response.error) {
                    console.warn(`${cn} getDashboardV2() error='${JSON.stringify(response.error)}'`);
                }
                return { ...response['dashboard'], actions: response['actions'] };
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getAllDashboardsV2(): Observable<StartingPageActionDashboard[]> {
        const url = `${environment.baseUrl}/api/v2/getStartingPage.vm?app=web`;
        return this.http.get<StartingPageRO>(url).pipe(
            map(response => {
                if (response && response.actions) {
                    const found = response.actions.find(action => action.action === 'change_dashboard');
                    if (found) {
                        return found.dashboards;
                    } else {
                        throwError(`${cn} getAllDashboardsV2() failed`);
                    }
                }
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createDashboardV2(payload: CreateDashboardV2DTO): Observable<CreateDashboardV2RO> {
        const actions = ['createDashboard'];
        const url = `${environment.baseUrl}/api/v2/organizations/dashboards.vm`;
        return this.http
            .post<CreateDashboardV2RO>(url, { ...payload, actions })
            .pipe(
                map(response => {
                    // TODO: Cheap way to flag possible errors, later improved in new backend.
                    if (response.error) {
                        console.warn(`${cn} createDashboardV2() error='${JSON.stringify(response.error)}'`);
                    }
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    updateDashboardV2(dashboardId: string, payload: UpdateDashboardV2DTO): Observable<UpdateDashboardV2RO> {
        const actions = ['updateTitle'];
        const url = `${environment.baseUrl}/api/v2/organizations/dashboards.vm?dashboardId=${dashboardId}`;
        return this.http
            .post<UpdateDashboardV2RO>(url, { ...payload, actions })
            .pipe(
                map(response => {
                    // TODO: Cheap way to flag possible errors, later improved in new backend.
                    if (response.error) {
                        console.warn(
                            `${cn} updateDashboardV2() dashboardId='${dashboardId}' error='${JSON.stringify(
                                response.error
                            )}'`
                        );
                    }
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    deleteDashboardV2(dashboardId: string) {
        const actions = ['remove'];
        const url = `${environment.baseUrl}/api/v2/organizations/dashboards.vm?dashboardId=${dashboardId}`;
        return this.http
            .post<UpdateDashboardV2RO>(url, { actions })
            .pipe(
                map(response => {
                    // TODO: Cheap way to flag possible errors, later improved in new backend.
                    if (response.error) {
                        console.warn(
                            `${cn} deleteDashboardV2() dashboardId='${dashboardId}' error='${JSON.stringify(
                                response.error
                            )}'`
                        );
                    }
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    getAllCategoriesV2(): Observable<GetAllCategoriesV2RO> {
        const url = `${environment.baseUrl}/api/v2/organizations/categories.vm`;
        return this.http.get<any>(url).pipe(
            map(response => {
                // TODO: Cheap way to flag possible errors, later improved in new backend.
                if (response.error) {
                    console.warn(`${cn} getAllCategoriesV2() failed, error='${JSON.stringify(response.error)}'`);
                }
                const found = (response.actions || []).find(action => action.action === 'switchCategory');
                return found && found.categories ? found.categories : [];
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getCategoryV2(categoryId: string): Observable<GetCategoryV2RO> {
        const url = `${environment.baseUrl}/api/v2/organizations/categories.vm?categoryId=${categoryId}`;
        return this.http.get<GetCategoryV2RO>(url).pipe(
            map(response => {
                // TODO: Cheap way to flag possible errors, later improved in new backend.
                if (response.error) {
                    console.warn(
                        `${cn} getCategoryV2() categoryId='${categoryId}' error='${JSON.stringify(response.error)}'`
                    );
                }
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createCategoryV2(payload: CreateCategoryV2DTO): Observable<CreateCategoryV2RO> {
        const actions = ['createCategory'];
        const url = `${environment.baseUrl}/api/v2/organizations/categories.vm`;
        return this.http
            .post<CreateCategoryV2RO>(url, { ...payload, actions })
            .pipe(
                map(response => {
                    // TODO: Cheap way to flag possible errors, later improved in new backend.
                    if (response.error) {
                        console.warn(`${cn} createCategoryV2() error='${JSON.stringify(response.error)}'`);
                    }
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    updateCategoryV2(categoryId: string, payload: UpdateCategoryV2DTO): Observable<UpdateCategoryV2RO> {
        const actions = ['updateTitle'];
        const url = `${environment.baseUrl}/api/v2/organizations/categories.vm?categoryId=${categoryId}`;
        return this.http
            .post<UpdateCategoryV2RO>(url, { ...payload, actions })
            .pipe(
                map(response => {
                    // TODO: Cheap way to flag possible errors, later improved in new backend.
                    if (response.error) {
                        console.warn(
                            `${cn} updateCategoryV2() categoryId='${categoryId}' error='${JSON.stringify(
                                response.error
                            )}'`
                        );
                    }
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    deleteCategoryV2(categoryId: string) {
        const actions = ['remove'];
        const url = `${environment.baseUrl}/api/v2/organizations/categories.vm?categoryId=${categoryId}`;
        if (this.allowCategoryDelete) {
            return this.http
                .post<UpdateCategoryV2RO>(url, { actions })
                .pipe(
                    map(response => {
                        if (response.error) {
                            console.warn(
                                `${cn} deleteCategoryV2() categoryId='${categoryId}' error='${JSON.stringify(
                                    response.error
                                )}'`
                            );
                        }
                        return response;
                    }),
                    catchError((error: any) => throwError(error))
                );
        } else {
            console.warn(`${cn} deleteCategoryV2() categoryId='${categoryId}' is disallowed`);
            return EMPTY;
        }
    }

    // --- Dashboard API v2 : end --- ///

    getAllDocuments(): Observable<any> {
        return this.http.get<any>( `${environment.baseUrl}/api/protocol/getAllProtocols.vm`).pipe(
            map(response => {return response}),
            catchError((error: any) => throwError(error))
        );
    }
    getCurrentDashboardCategories(): Observable<any> {
        return this.http.get<any>(this.url).pipe(
            map(response => {
                const { dashboard_categories } = response;
                return { ...dashboard_categories };
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getDashboardCategories(): Observable<any> {
        return this.http.get<any>(this.url).pipe(
            map(response => {
                const { tags } = response;
                return { categories: tags };
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getDashboard(): Observable<DashboardSummary> {
        const url = `${environment.baseUrl}/api/v2/getStartingPage.vm?app=web`;
        return this.http.get<any>(url).pipe(
            map(response => {
                const switchDashboardIndex = response.actions.findIndex(x => x.action === 'change_dashboard');
                const dashboards = switchDashboardIndex !== -1 ? response.actions[switchDashboardIndex].dashboards : [];
                const default_dashboard = {
                    id: response.dashboard.dashboard_id,
                    name: response.dashboard.dashboard_title,
                }
                dashboards.push(default_dashboard);
                const dashboard_id =  response.dashboard.dashboard_id;
                dashboards.dashboard_id =  dashboard_id;
                return { ...response, dashboards, dashboard_id };
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    getAllCategories(): Observable<DashboardSummary> {
        const url = `${environment.baseUrl}/api/protocol/organization/getDashboards.vm`;
        return this.http.get<DashboardSummary>(url).pipe(
            map(response => {
                const categories = Object.values(response.tags);
                return { ...response, categories };
            }),
            catchError((error: any) => throwError(error))
        );
    }

    setDashboardDisplay(selected: string) {
        const url = `${environment.baseUrl}/api/protocol/updateUser.vm`;
        const data = {
            display_dashboard: selected
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    createDashboard(title: string) {
        const url = `${environment.baseUrl}/api/protocol/organization/createDashboard.vm`;
        const data = {
            title
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    editDashboard(dashboardId: string, title: string) {
        const url = `${environment.baseUrl}/api/protocol/organization/editDashboard.vm`;
        const data = {
            dashboardId,
            title
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    removeDashboard(dashboardId: string) {
        const url = `${environment.baseUrl}/api/protocol/organization/editDashboard.vm`;
        const data = {
            dashboardId,
            action: 'remove'
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    createCategory(title: string, index?: number) {
        const url = `${environment.baseUrl}/api/protocol/organization/createTag.vm`;
        const data = {
            title
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    editCategory(payload) {
        const url = `${environment.baseUrl}/api/protocol/organization/editTag.vm`;
        const { tagId, index, title, dashboardId, action } = payload;
        const data = {
            tagId,
            index,
            title,
            dashboardId,
            action
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    removeCategory(tagId: string) {
        //todo: this only works for removing a tag from a dashboard, so we also need to pass in the dashboardId
        const url = `${environment.baseUrl}/api/protocol/organization/editTag.vm`;
        const data = {
            tagId,
            action: 'remove'
        };

        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    sortCategory(payload) {
        const url = `${environment.baseUrl}/api/protocol/organization/editTag.vm`;
        const { tagId, dashboardId, position } = payload;
        const data = {
            tagId,
            dashboardId,
            position
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    getProtocolsByTagId(tagId) {
        const tagIdUrl = tagId ? '?tagId=' + tagId : '';
        const url = `${environment.baseUrl}/api/getDashboard.vm${tagIdUrl}`;
        return this.http.get<DashboardSummary>(url).pipe(
            map(response => {
                const { dashboard_categories } = response;
                let protocols = [];
                Object.entries(dashboard_categories).forEach(([key, value]) => {
                    protocols = [...protocols, value.protocols];
                });
                return protocols[0];
            })
            // ,
            // catchError((error: any) => {
            //     // throw error.json();
            // })
        );
    }

    getAllProtocols() {
        const url = `${environment.baseUrl}/api/protocol/getAllProtocolsList.vm`;
        return this.http.get<DashboardSummary>(url).pipe(
            map(response => {
                let protocols = [];
                Object.entries(response).forEach(([key, value]) => {
                    protocols = [...protocols, value.protocols];
                });
                return protocols[0];
            })
            // ,
            // catchError((error: any) => {
            //     // throw error.json();
            // })
        );
    }

    addProtocolToTag(protocolId, tagId) {
        const url = `${environment.baseUrl}/api/protocol/updateProtocolSettings.vm`;
        const data = {
            protocolId,
            addTag: tagId
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    removeProtocolFromTag(protocolId, tagId) {
        const url = `${environment.baseUrl}/api/protocol/updateProtocolSettings.vm`;
        const data = {
            protocolId,
            removeTag: tagId
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    createTag(payload) {
        const { title } = payload;
        const url = `${environment.baseUrl}/api/protocol/organization/createTag.vm`;
        const data = {
            title
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    addDashboardTag(payload) {
        const { dashboardId, tagId } = payload;
        return this.TagToDashboard({ dashboardId, tagId, action: 'add' });
    }

    removeDashboardTag(payload) {
        const { dashboardId, tagId } = payload;
        return this.TagToDashboard({
            dashboardId,
            tagId,
            action: 'remove'
        });
    }

    TagToDashboard(payload) {
        const { dashboardId, tagId, action } = payload;
        const url = `${environment.baseUrl}/api/protocol/organization/editTag.vm`;
        const data = { dashboardId, tagId, action };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    private _notify(action: DashboardRequestAction, state: DashboardRequestState, respOrErr: any = null) {
        if (state === 'success') {
            this._request$.next({ action, state, response: respOrErr });
        } else if (state === 'fail') {
            this._request$.next({ action, state, error: respOrErr });
        } else {
            this._request$.next({ action, state });
        }
    }
}
