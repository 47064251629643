<div class="modal-header">
    <h4 class="modal-title">
        {{ outcomes[0]?.title }} 
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>

    
</div>

<div class="modal-body">
    <div *ngIf="loading" id="loadingContent" class="row m-2">
        <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
        </div>
    </div>
    <div *ngIf="!loading" class="row m-2">
        
        <div class="row warning" *ngIf="data.updateKeyQuestion">
            <div class="col">
                
                {{ 'PARAGRAPH.EDIT.WARNINGUPDATEKEYQUESTION' | translate }}
                <br/>
                <a style="text-decoration: underline;" (click)="onUpdateKQ()">
                    {{ 'PARAGRAPH.EDIT.UPDATEKEYQUESTION' | translate }}
                </a>
            </div>
        </div>
        


        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs -inModal">
            
            <li [ngbNavItem]="'content'" [hidden]="!outcomes[0].text && !outcomes[0].expanded_text">
                <a ngbNavLink> Toelichting</a>
                <ng-template ngbNavContent>
                    <div class="wysiwyg" >
                        <h6 style="display: none;" class="small clear-both" *ngIf="outcomes[0].identifier">{{ outcomes[0].identifier }} </h6>
                        <p [innerHTML]="outcomes[0].text?.replace('href', 'target=\'_blank\' href')"></p>
                        <p [innerHTML]="outcomes[0].expanded_text?.replace('href', 'target=\'_blank\' href')"></p>
                    </div>
                </ng-template>
            </li>
            
            <li [ngbNavItem]="'details'" [hidden]="!outcomes[0].keyQuestionTitle && outcomes[0].conclusions?.length == 0">
                <a ngbNavLink> {{'MODEL.OUTCOME.TREEVIEW.HEADERCONCLUSION' | translate }}</a>
                <ng-template ngbNavContent>
                    <div *ngFor="let outcome of outcomes; let index = index;" [class]="'col-' + 12 / outcomes.length">
                        <div class="wysiwyg">
                            <p *ngIf="outcome.keyQuestionTitle" class="wysiwyg">{{'MODEL.OUTCOME.TREEVIEW.CONCLUSIONINTRO' | translate }}</p>

                            <h4 *ngIf="outcome.keyQuestionTitle"> {{outcome.keyQuestionTitle}}</h4>
                            <p *ngIf="outcome.keyQuestionLink" class="mb-3">
                                <a [href]="outcome.keyQuestionLink" target="_blank">Ga naar achterliggende richtlijntekst</a>
                                <br>
                                Deze start met de uitgangsvraag die is uitgewerkt in achtergrondinformatie over het probleem, literatuur, overwegingen en aanbevelingen.    
                            </p>
                        </div>
                        <div *ngIf="outcome.conclusions?.length > 0">
                            <ul class="conclusionList">
                                <li *ngFor="let conclusion of outcome.conclusions" >
                                    <div class="row wysiwyg">
                                        <div class="col-9">
                                            <h5>{{conclusion.title}}</h5>
                                            <span *ngIf="!!conclusion.text" [innerHTML]="conclusion.text"></span>
                                        </div> 
                                        <div class="col-3">
                                            <div class="row m-2"  *ngIf="conclusion.sourceOfText">
                                                <div *ngFor="let sourceText of conclusion.sourceOfText.split('|')">
                                                    <span class="tag -primary -noLink"
                                                        ngbTooltip="{{sourceText}}">
                                                        {{sourceText}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div class="button-group -borderless -toolbar">
                                        <a *ngIf="conclusion.sources.length" (click)="toggleShowSources(conclusion.id)" class="button is-empty -nopaddingLeft reference-toggle cursor-pointer">
                                            <span class="icon-alii-book"></span> {{ 'LITERATURE.PARAGRAPH.REFERENCES' | translate }} ({{ conclusion.sources.length }})
                                        </a>
                                    </div>
                                    <div *ngIf="sourcesVisible(conclusion.id) && conclusion.sources.length">

                                        <div class="row" *ngIf="conclusion.gradeAssessment.questions">
                                            <h4>{{ 'LITERATURE.PARAGRAPH.GRADE' | translate }}</h4>
                                    
                                            <table class="table table-striped">
                                                <tbody>
                                                <ng-container *ngFor="let grade of (conclusion.gradeAssessment.questions); trackBy: trackByFn">
                                                    <tr class="d-flex">
                                                        <td class="col-6 text-capitalize">{{ formatGradeKey(grade.key) }}</td>
                                                        <td class="col-4" [innerHTML]="grade.value"></td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                        <ng-container *ngFor="let sourceData of conclusion.sourceTable">
                                            <ag-grid-angular
                                                *ngIf="sourceData.rowData && sourceData.rowData.length"
                                                #agGrid
                                                style="width: 100%; min-width: 700px;"
                                                class="ag-theme-alpine"
                                                [rowData]="sourceData.rowData"
                                                [columnDefs]="sourceData.columnDefs"
                                                domLayout='autoHeight'
                                                [rowClass]="rowClass"
                                                [defaultColDef]="defaultColDef"
                                                [getRowClass]="getRowClass"
                                                [frameworkComponents]="frameworkComponents"
                                                >
                                            </ag-grid-angular>
                                        </ng-container>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="'situations'" id="treeview-tab" *ngIf=outcomes[0].branches>
                <a ngbNavLink> {{'MODEL.OUTCOME.TREEVIEW.TITLE' | translate}}</a>
                <ng-template ngbNavContent>
                    <div *ngFor="let outcome of outcomes; let index = index;">
                        <h5>{{'MODEL.OUTCOME.TREEVIEW.MODALTITLE' | translate }}</h5>
                        <p class="wysiwyg">{{'MODEL.OUTCOME.TREEVIEW.TEXT' | translate}}: 
                            <ngx-treeview
                                [config]="config"
                                [items]="items[index]"
                                (selectedChange)="onSelectedChange($event, index)"
                                (filterChange)="onFilterChange($event, index)"
                            >
                            </ngx-treeview>
                        </p>  
                    </div>
                </ng-template>
            </li>
        </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
<div class="modal-footer">
    <a class="btn btn-info text-capitalize" type="submit" (click)="activeModal.close()">{{'BACK' | translate}}</a>
</div>
