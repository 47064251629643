<ng-container>
    <div class="btn-group" ngbDropdown placement="bottom-right">
        <button type="button" class="button -light"
                ngbDropdownToggle>{{ 'DASHBOARD.ACTIONS.MANAGE.TITLE' | translate }}</button>
        <ul class="dropdown-menu right" ngbDropdownMenu>
            <li *ngxPermissionsOnly="['create_protocol']">
                <a class="dropdown-item cursor-pointer" [routerLink]="['/protocols/create']">
                    {{ 'DASHBOARD.ACTIONS.MANAGE.DOCUMENT-CREATE' | translate }}
                </a>
            </li>
            <ng-container *ngxPermissionsOnly="['manage_dashboard']">
                <li>
                    <div class="dropdown-divider"></div>
                </li>
                <div *ngIf="!dashboard" class="text-center mb-2">
                    <span class="spinner-grow spinner-grow-sm mt-2"></span>
                </div>
                <li *ngIf="dashboard">
                    <a class="dropdown-item cursor-pointer" [routerLink]="['/dashboard', dashboard.dashboard_id]">
                        <span>{{ 'DASHBOARD.ACTIONS.MANAGE.DASHBOARD-EDIT' | translate }}</span>
                        <span class="badge badge-pill badge-secondary">{{ dashboard.categories.length }}</span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>
