import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommentAddModalComponent } from './comment-add-modal/comment-add-modal.component';
import { GradeFormModalComponent } from '../../../../entry-components';

import {AgGridAngular} from 'ag-grid-angular';

import * as fromService from '../../../../../../services';
import { PubSubService } from '../../../../../../services/pubsub';
import { SourcesEditComponent } from './sources-edit/sources-edit.component';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';
import { threadId } from 'worker_threads';
import { Paragraph } from 'apps/web/src/app/models/paragraph.interface';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-footer-edit',
    templateUrl: './footer-edit.component.html',
    styleUrls: ['./footer-edit.component.scss']
})
export class FooterEditComponent implements OnInit, OnDestroy {
    @Input() protocolId: string;
    @Input() paragraphId: string;
    @Input() paragraph: Paragraph;
    @Input() hasFindings: boolean;
    @Input() keyQuestionId: string;
    @Input() parentId: string;
    @Input() comments: any[];
    @Input() type: string;
    @Input() files: any[] = [];
    @Input() sources: any;
    @Input() sourceInfo?: any;
    @Input() populations: any[] = [];
    @Input() title: string;
    @Input() gradeAssessmentPar: any;
    @Input() gradeRowData: any;
    @Input() gradeColumnDefs: any;
    @Input() filesPar: any;
    @Input() uploadEvent: any;
    @Input() sourceTable: any[];
    @Input() allSourceTable: any[];


    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    supportedImageExt = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'svg'];

    selectedQuestionIndex = 0;

    actionsShown = false
    isCommentsCollapsed = false;
    isFilesCollapsed = false;
    isSourcesCollapsed = false;
    isPopulationsCollapsed = false;
    clonedData = [];
    clonedColumns = [];

    regex = /_/gi;

    paragraphGradeCreated: any;
    frameworkComponents;

    tooltip = {};

    constructor(
        private modalService: NgbModal, private pubsub: PubSubService,
        private literaturesService: fromService.LiteraturesService) {
            this.frameworkComponents = {
                renderHTML: RenderHTML,
                editHTML: EditHTML,
              };
        } 
    ngOnInit(): void {
        
        // if(this.rowData) {
        //     this.clonedData = JSON.parse(JSON.stringify(this.rowData));
        //     this.clonedColumns = JSON.parse(JSON.stringify(this.columnDefs));
        // }
        this.paragraphGradeCreated = this.pubsub.$sub('paragraphGradeCreated' + this.paragraphId).subscribe(from => {
            this.modalService.dismissAll();
            const { field } = from;
            this.onClickUpdateGrade(field, true);
        });

  
    }

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('agGridGrade') agGridGrade: AgGridAngular;

    ngOnDestroy() {
        this.paragraphGradeCreated.unsubscribe();
    }

    isReferencesLinkActive() {
        if (this.type === 'keyQuestion') {return false}
        const action = this.gradeAssessmentPar ? this.gradeAssessmentPar.action : '';
        return this.sources.count > 0 || action === 'editGradeAssessment';
    }

    onCellClicked(event) {
        this.onClickUpdateGrade(event.colDef.headerName, true)
        
    }

    openOutcomeTreeview(event, paragraph) {
        const openTreeAction = {
            type: 'openOutcomeTreeview',
            payload: {
                outcome: paragraph,
                updateKeyQuestion: this.paragraph.updateKeyQuestion,
                populationId: null
            }
        };
        this.eventBus.emit(openTreeAction);
        event.stopPropagation();
        return false;
    }

    onClickUpdateGrade(field, edit) {
        let index = this.getQuestionIndex(field);
        
        if (index < 0) {
            // the question doesn't have this name, but there may be a field related to the question
            this.gradeAssessmentPar.questions.forEach(question => {
                if (question.fields) {
                    question.fields.rowData.forEach(element => {
                        if (element.label == field) {
                            index = this.gradeAssessmentPar.questions.findIndex(q => q.field === question.field)
                            return index
                        }
                    });
                }
            });
        }
        if(index < 0) { index = 0} 
        this.selectedQuestionIndex = index;
        this.handleOpenGradeModal(edit);
    }

    isUploading() {
        return this.uploadEvent.ppdId === this.paragraphId && this.uploadEvent.isUploading;
    }


    showActions() {
        this.actionsShown = true
    }

    getSelectedRowData() {
        let selectedNodes = this.agGrid.api.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data);
        return selectedData;
    }


    openArticle() {

        let data = this.getSelectedRowData()
        let articleId = data[0].articleId

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);

    }

    // onClickEditSources() {
    //     const modalRef = this.modalService.open(SourcesEditComponent, { 
    //         size: 'xl' as 'lg'
    //     });
    //     modalRef.componentInstance.paragraphId = this.paragraphId;
    //     modalRef.componentInstance.columnDefs = this.columnDefs;
    //     modalRef.componentInstance.rowData = this.rowData;
    // }


    deleteSelectedRows() {
        let data = this.getSelectedRowData()
        const action = {
            type: 'handleDeleteRows',
            payload: {
                data,
                ppdId: this.paragraphId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(action);

    }

    // onCellEditingStopped(params) {
    //     var data =  params.data
    //     var ppdId = this.paragraphId
    //     var payload = { ppdId, data}
    //     this.literaturesService.updateFindings(payload).subscribe(data => {
    //     });
    // }

    defaultColDef: any = {
        valueSetter: (params) => {
            let newVal = params.newValue;
            const field = params.colDef.field;
            var action = "updateReview"
         
            const eventPayload = {
              type: 'updateReview',
              payload: {
                    reviewId: params.data.id,
                    action, 
                    field,
                    ppdId: this.paragraphId,
                    value: newVal 
              }
          };
          this.eventBus.emit(eventPayload);
          return false;
        },
        resizable: true,
        cellStyle: {
          'align-items': 'center'
        }
      };


    getSourceTable() {
        if(this.paragraph.type == 'keyQuestion') {
            return this.allSourceTable
        } 
        return this.sourceTable
    }

    onClickDeleteFile(file) {
        const deleteFileAction = {
            type: 'handleDeleteFile',
            payload: {
                protocolId: this.protocolId,
                ppdId: this.paragraphId,
                fileId: file.id
            }
        };
        this.eventBus.emit(deleteFileAction);
    }

    handleEventBus(event) {
        const { type } = event;
        switch (type) {
            default:
                this.eventBus.emit(event);
                break;
        }
    }

    isSupportedImage(filename: string): boolean {
        if (!filename) {
            return false;
        }
        const fileExt = filename.split('.').pop();
        return this.supportedImageExt.includes(fileExt);
    }

    toggleCollapsed(target) {
        const origin = this[target];
        this.isCommentsCollapsed = false;
        this.isFilesCollapsed = false;
        this.isSourcesCollapsed = false;
        this.isPopulationsCollapsed = false;

        if (!origin) {
            this[target] = true;
        }
        
        if (target === 'isSourcesCollapsed') {
            this.loadEvidence();
        }

        if (target === 'isSourcesCollapsed' && this.agGrid) {
            setTimeout( () => {
                this.agGrid.api.resetRowHeights()
             }, 0 );      
        }
        if (target === 'isSourcesCollapsed' && this.agGridGrade) {
            setTimeout( () => {
                this.agGridGrade.api.resetRowHeights()
             }, 0 );      
        }
    }

    loadGradeQuestions() {
        const getQuestionsAction = {
            type: 'handleGetGradeQuestions',
            payload: {
                gradeId: this.gradeAssessmentPar.id,
                protocolId: this.protocolId,
                ppdId: this.paragraphId
            }
        };
        this.eventBus.emit(getQuestionsAction);
    }

    loadEvidence() {
        const loadFindingsAction = {
            type: 'handleLoadEvidence',
            payload: {
                protocolId: this.protocolId,
                ppdId: this.paragraphId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(loadFindingsAction);
    }

    convertToArray(obj) {
        const ignoreColumns = ['fields'];
        let columns = Object.keys(obj);
        columns = columns.filter(column => !ignoreColumns.includes(column));

        return columns.map(i => {
            return { key: i, value: obj[i] };
        });
    }

    isQuestionExist(field) {
        const index = this.getQuestionIndex(field);
        return index >= 0;
    }

    getQuestionIndex(field) {
        if (!this.gradeAssessmentPar.questions) {
            return -1;
        }
        let index = this.gradeAssessmentPar.questions.findIndex(question => question.field === field);
        if (index == -1) {
            index = this.gradeAssessmentPar.questions.findIndex(question => question.title === field);
        }
        return index;
    }

    
    getSources() {
        if(this.sources && this.sources.length) {
            return true
        }
        else if (this.sourceInfo.count ) {
            return true
        }
        return false
    }

    getSourceCount() {
        if(this.sources && this.sources.length) {
            return this.sources.length
        }
        else {
            return this.sourceInfo.count 
        }

    }


    handleOpenGradeModal(edit) {
        let rowData = ''
        if(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].rowData) {
            rowData = JSON.parse(JSON.stringify(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].rowData));
        }
        let fieldsRowData = ''
        if (this.gradeAssessmentPar.questions[this.selectedQuestionIndex].fields) {
            fieldsRowData = JSON.parse(JSON.stringify(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].fields.rowData));
        }
        
        const modalRef = this.modalService.open(GradeFormModalComponent, { size: 'xl' });
        modalRef.componentInstance.edit = edit;
        modalRef.componentInstance.ppdId = this.paragraphId;
        modalRef.componentInstance.isCreateGrade = false;
        modalRef.componentInstance.rowData = rowData;
        modalRef.componentInstance.fieldsRowData = fieldsRowData;
        modalRef.componentInstance.gradeAssessmentPar = this.gradeAssessmentPar;
        modalRef.componentInstance.selectedQuestion = this.gradeAssessmentPar.questions[this.selectedQuestionIndex];
        modalRef.componentInstance.selectedQuestionIndex = this.selectedQuestionIndex;

        modalRef.componentInstance.handleNavigate.subscribe(index => {
            modalRef.close();
            this.selectedQuestionIndex = index;
            this.handleOpenGradeModal(edit);
            //this.handleSubmitUpdateGrade();

        });

        modalRef.componentInstance.eventBus.subscribe(event => {
            this.eventBus.emit(event)
        });


        modalRef.result.then(
            () => {this.loadGradeQuestions();}, // on close with result
            () => {this.loadGradeQuestions();} // on dismiss with reason
        );
    }


    handleSubmitUpdateGrade(payload) {
        const { key, value } = payload;
        const action = {
            type: 'handleSubmitUpdateGrade',
            payload: {
                protocolId: this.protocolId,
                ppdId: this.paragraphId,
                key,
                value,
                gradeId: this.gradeAssessmentPar.id
            }
        };
        this.eventBus.emit(action);
    }

    handleOpenCommentAddModal() {
        const modalRef = this.modalService.open(CommentAddModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleAddComment',
                    payload: {
                        ppdId: this.paragraphId,
                        parentId: this.parentId,
                        text: result.message
                    }
                };
                this.eventBus.emit(action);
            },
            () => {} // on dismiss with reason
        );
    }

    handleOpenReplyCommentModal(comment) {
        const modalRef = this.modalService.open(CommentAddModalComponent);
        modalRef.componentInstance.comment = comment;
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleReplyComment',
                    payload: {
                        ppdId: this.paragraphId,
                        text: result.message,
                        parentId: this.parentId,
                        comment: result.comment
                    }
                };
                this.eventBus.emit(action);
            },
            () => {} // on dismiss with reason
        );
    }

    handleMarkComment(comment, status) {
        const action = {
            type: 'handleMarkComment',
            payload: {
                ppdId: this.paragraphId,
                status,
                comment
            }
        };
        this.eventBus.emit(action);
    }

    handleClickIncludeSource(source, articleId) {
        const action = {
            type: 'handleClickIncludeSource',
            payload: {
                action: source.action,
                sourceId: source.id,
                articleId,
                protocolId: this.protocolId,
                ppdId: this.paragraphId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(action);
    }

    handleClickExcludeSource(source) {
        const action = {
            type: 'handleClickIncludeSource',
            payload: {
                action: 'removeSource',
                sourceId: source.sourceId,
                articleId: source.articleId,
                ppdId: this.paragraphId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(action);
    }

    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                // TODO: fix this in backend
                const value = finding[key].toString().replace('illegal reference: ', '');
                return {
                    key,
                    value
                };
            });
    }

    trackByFn = (index, item) => item.id || index;
}
