<div class="row">
    <div class="button-group -borderless -toolbar paragraph-toolbar">
       <a
            *ngIf="!(comments && comments.length) && version=='Preview'"
            class="button is-empty -nopaddingLeft comments-toggle cursor-pointer"
            title="Add comment"
            (click)="toggleCollapsed('isCommentsCollapsed')"
        >
            <span class="icon-alii-message-square"></span> comments (0)
        </a>
        <a
            *ngIf="comments && comments.length && version=='Preview'"
            class="button is-empty -nopaddingLeft collapsed comments-toggle cursor-pointer"
            data-toggle="collapse"
            data-parent="#"
            data-target="#"
            aria-expanded="false"
            (click)="toggleCollapsed('isCommentsCollapsed')"
        >
            <span class="icon-alii-message-square"></span> comments ({{ comments.length }})
        </a>
        <a
            *ngIf="keyQuestionId"
            class="button is-empty -nopaddingLeft collapsed cursor-pointer"
            data-toggle="collapse"
            aria-expanded="false"
            (click)="openOutcomeTreeview($event, paragraph)"
        >
            <span class="icon-alii-text"></span> {{'FOOTER.KEYQUESTION' | translate }}
        </a>
        <a
            *ngIf="files && files.length"
            [id]="'para-' + paragraphId + '-file-toggle'"
            class="button is-empty files-toggle -nopaddingLeft cursor-pointer"
            (click)="toggleCollapsed('isFilesCollapsed')"
        >
            <span class="icon-alii-file"></span> files ({{ files.length }})
        </a>

        <a
            *ngIf="sourceInfo && sourceInfo.count > 0"
            class="button is-empty -nopaddingLeft reference-toggle cursor-pointer"
            (click)="toggleCollapsed('isSourcesCollapsed')"
        >        
        <ng-container *ngIf="sourceInfo && sourceInfo.type == 'footnote'"><span class="icon-alii-book"></span> {{'FOOTER.REFERENCES' | translate }}  ({{ sourceInfo.count }})</ng-container>
        <ng-container *ngIf="sourceInfo && sourceInfo.type == 'articleConclusion'"><span class="icon-alii-book"></span> {{'LITERATURE.SUMMARYOFFINDINGS' | translate }}</ng-container>

        </a>
    </div>
</div>


<div class="row">
    <div class="clearfix" style="width: 100%">
        <div id="commentsCollapsed" [ngbCollapse]="!isCommentsCollapsed">
            <h4>Comments</h4>
            <ng-container *ngFor="let comment of comments; trackBy: trackByFn">
                <div class="showComment wysiwyg">
                    {{ comment.user_name }} : {{ comment.review_details }}
                    
                    <div class="showReply" *ngFor="let reply of comment.replies">
                        <hr/>
                        {{ reply.user_name }} : {{ reply.review_details }}
                    </div>
                </div>


                <div class="showReply">
                    <hr/>
                    <a
                        (click)="handleOpenReplyCommentModal(comment)"
                        class="addParagraphFindingButton button"
                    >
                        {{'REPLY' | translate}}
                    </a>
                </div>
                <hr/>
            </ng-container>
            
            <a
                (click)="handleOpenCommentAddModal()"
                role="button"
                class="addParagraphFindingButton btn btn-info"
            >
                Add comment
            </a>
        </div>
    </div>
</div>
<div class="row">
    <div class="collapse mt-2" [ngbCollapse]="!isFilesCollapsed">
        <div class="dashboard">
            <div class="dashboard-items">
                <article [id]="'para-' + paragraphId + '-file-' + file.id" class="card"
                         *ngFor="let file of files; trackBy: trackByFn">
                    <header class="card__header">
                        <span class="card__header-heading">
                            <a href="{{ file.file_url }}" class="card__header__link" target="_blank">
                                {{ file.title }}
                            </a>
                        </span>
                        <div class="card__main-inner">
                            <img
                                src="{{ file.file_url }}"
                                *ngIf="isSupportedImage(file.file_url)"
                                class="img-fluid"
                                alt="{{ file.title }}"
                            />
                        </div>
                    </header>
                </article>
            </div>
        </div>
    </div>
</div>

<div class="collapse m-2" [ngbCollapse]="!isSourcesCollapsed">
    
    <div class="row" *ngIf="!sourceTable"><p class="wysiwyg">{{ 'LITERATURE.PARAGRAPH.NOREFERENCES' | translate }}</p></div>
    <div class="row" *ngIf="sourceTable">

        <div class="row" *ngIf="actionsShown">
            <div class="pull-right col-md">
                <span class="pull-right button small -primary m-2" (click)="openArticle()">show info</span> 
            </div>
        </div>
        <ng-container *ngFor="let sourceData of sourceTable">
            <ag-grid-angular
                #agGridSources
                style="width: 100%"
                class="ag-theme-alpine"
                [rowData]="sourceData.rowData"
                [columnDefs]="hideIrrelevantColumns(sourceData.columnDefs)"
                domLayout='autoHeight'
                rowSelection='single'
                [frameworkComponents]="frameworkComponents"
                (cellClicked)="onCellClicked(params)"
                >
            </ag-grid-angular>
        </ng-container>

    </div>

    <div class="row" *ngIf="gradeAssessmentPar?.gradeRowData?.length">
        <h4>{{ 'LITERATURE.PARAGRAPH.GRADE' | translate }}</h4>

        <ag-grid-angular
            *ngIf="gradeAssessmentPar.gradeRowData && gradeAssessmentPar.gradeRowData.length"
            #agGrid
            style="width: 100%"
            class="ag-theme-alpine"
            [rowData]="gradeAssessmentPar.gradeRowData"
            [columnDefs]="gradeAssessmentPar.gradeColumnDefs"
            domLayout='autoHeight'
            [frameworkComponents]="frameworkComponents"
            (click)="onClickShowGrade()"
            >
        </ag-grid-angular>

    </div>

</div>
