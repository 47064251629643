import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../services';

/* this component contains a logout-button and is useful in case of
custom styling that doesn't contain the main navigation bar and you
still want to show a logout button. default styling is display:none */

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-log-out-button',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
    constructor(private authService: AuthService) {}

    onLogout() {
        this.authService.logout();
    }
}
