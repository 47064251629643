import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ConstantPool } from '@angular/compiler';

@Injectable()
export class UploadService {
    constructor(private http: HttpClient) {}

    public uploadToBucket(payload) {
        const uploadURL = environment.froala.imageUploadURL;
        const { formData } = payload;
        return this.http
            .post<any>(uploadURL, formData, {
                reportProgress: true,
                observe: 'events'
            })
            .pipe(
                map(event => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round((100 * event.loaded) / event.total);
                            return { status: 'progress', progress };

                        case HttpEventType.Response:
                            const { link } = event.body;
                            return { status: 'success', url: link };
                        default:
                            return { status: 'failed', message: `Unhandled event: ${event.type}` };
                    }
                }),
                catchError((error: any) => throwError(error))
            );
    }
    saveUpload(payload): Observable<any> {
        //TODO: turn this in two separate functions for adding to par and to article

        const { protocolId, url, title, reviewQuestion } = payload;
        let postPayload = {};
        let endpointUrl = `${environment.baseUrl}/api/protocol/article/createNewFile.vm`;

        if ('articleId' in payload) {
            endpointUrl = `${environment.baseUrl}/api/protocol/article/createNewFile.vm`;
            postPayload = { articleId: payload.articleId, protocolId, url, title };
        }

        if ('ppdId' in payload) {
            endpointUrl = `${environment.baseUrl}/api/protocol/par/createNewFile.vm`;
            postPayload = { ppdId: payload.ppdId, protocolId, url, title, reviewQuestion };
        }

        return this.http.post<any>(endpointUrl, postPayload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }
    deleteFile(payload): Observable<any> {
        const endpointUrl = `${environment.baseUrl}/api/protocol/par/updateFile.vm`;
        const { ppdId, protocolId, fileId } = payload;

        return this.http.post<any>(endpointUrl, { ppdId, protocolId, fileId, action: 'Delete' }).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }
}
