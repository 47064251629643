import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import * as flowchartLib from 'flowchart.js';

import { Protocol } from '../../../../models/protocol.interface';
import { User } from '../../../../models/user.interface';
import { Literatures } from '../../../../models/literature.interface';
import { KeyQuestionImport } from '../../../../models/keyquestion-import.interface';
import { ViewArticleModalComponent } from './paragraph-edit/view-article-modal/view-article-modal.component';
import { getFlowchartOptions } from '../flowchart-builder/flowchart-area/flowchart-options';
import { setupScrollNavigationLinks } from '../libs';
import { AsHtmlPipe } from 'apps/web/src/app/pipes/as-html.pipe';

const cn = 'ProtocolEditComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-edit',
    templateUrl: './protocol-edit.component.html',
    styleUrls: ['./protocol-edit.component.scss']
})
export class ProtocolEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    channelMessages: any;

    @Input()
    pane: string;

    @Input()
    loading = true;

    @Input()
    protocol: Protocol;

    @Input()
    isFlowchart: boolean;
    
    @Input()
    currentVersion: any;

    @Input()
    draftVersion: any;

    @Input()
    deprecatedVersions: any[] = [];

    @Input()
    storedDraftVersions: any[] = [];

    @Input()
    literatures: Literatures;

    @Input()
    users: User[];

    @Input()
    isEditAble?: boolean;

    @Input()
    paragraphSelectedId?: string;

    @Input()
    versionId?: string;

    @Input()
    version?: string;

    @Input()
    modelMessages?: any;

    @Input()
    keyQuestionImportList: KeyQuestionImport[];

    @Input()
    gradeAssessment: any;

    @Input()
    paragraphsFiles: any;

    @Input()
    modelFindings: any;

    @Input()
    modelOutcomes: any;

    @Input()
    modelTagList: any;

    @Input()
    pubmeds: any;

    @Input()
    protocolArticle: any;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input()
    populationId: any;

    @Input()
    views: any[] = [];

    @Input()
    currentView: string;

    @Input()
    modelUpdateBySheetId: any;

    @Input()
    linkToProtocolId: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    updatePar: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('canvas') canvas: any;

    chart: any;

    updateModelBySheetIdArrayLoading: any = {};

    // --- Scroll highlight navigation links --- //
    @ViewChild('scroll') scrollRef: ElementRef;
    scrollListener: () => void;
    scrollParagraphId: string;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private toastr: ToastrService,
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private titleService: Title
    ) {}

    ngOnInit(): void {

        this.updateModelBySheetIdArrayLoading = Object.keys(this.gradeAssessment.entries).reduce((acc, key) => {
            if (this.gradeAssessment.entries[key].type === 'paragraph') {
                return { ...acc, [key]: false };
            }
            return acc;
        }, {});

    }

    ngAfterViewInit() {

        if (this.protocol.isLiterature || this.protocol.externalUrl)
        {
            this.pane = "settings"
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.protocol) {
            this._setTitle();
            setTimeout(() => this._setupScrollListener(), 200);
        }
        const modelUpdateBySheetId = changes.modelUpdateBySheetId;
        if (modelUpdateBySheetId && !modelUpdateBySheetId.firstChange) {
            const currentValue = modelUpdateBySheetId.currentValue;
            const previousValue = modelUpdateBySheetId.previousValue;
            const { ppdId } = currentValue.values;
            this.updateModelBySheetIdArrayLoading[ppdId] = currentValue.loading;
            // Must reassign object in order to trigger on change in paragraph edit component.
            this.updateModelBySheetIdArrayLoading = Object.assign({}, this.updateModelBySheetIdArrayLoading);
            if (currentValue.error) {
                // IMPORTANT: Need a call to setTimeout here, otherwise the following error will occur:
                // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
                setTimeout(() => this.toastr.error(currentValue.error, 'Error', { timeOut: 10000 }), 200);
            } else if (previousValue.loading && currentValue.loaded) {
                setTimeout(() => this.toastr.success(currentValue.message, 'Success'), 200);
            }
        }

        const linkToProtocolId = changes.linkToProtocolId;
        if (linkToProtocolId && !linkToProtocolId.firstChange) {
            const currentValue = linkToProtocolId.currentValue;
            const previousValue = linkToProtocolId.previousValue;
            const { connectProtocolId } = currentValue.values;
            if (currentValue.error) {
                setTimeout(() => this.toastr.error(currentValue.error, 'Error', { timeOut: 10000 }), 200);
            } else if (previousValue.loading && currentValue.loaded) {
                setTimeout(
                    () =>
                        this.toastr.success(
                            `Paragraph successfully linked to protocol #${connectProtocolId}`,
                            'Success'
                        ),
                    200
                );
            }
        }

        const { protocolArticle } = changes;

        if (protocolArticle && !protocolArticle.firstChange) {
            setTimeout(() => {
                this.modalService.dismissAll();
                this.handleOpenProtocolArticleModal();
            });
        }
    }

    ngOnDestroy() {
        if (this.scrollListener) {
            this.scrollListener();
        }
    }

    handleOpenProtocolArticleModal() {
        const modalRef = this.modalService.open(ViewArticleModalComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.article = this.protocolArticle;
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleUpdateModelBySheetId':
                this.eventBus.emit(event);
                break;
            case 'onHandleClickOption':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        protocolId: this.protocol.id
                    }
                });
                break;

            case 'onUpdateModel':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        populationId: this.populationId
                    }
                });
                break;

            case 'handleShowOutcomesByPopulationId':
                this.eventBus.emit({
                    ...event,
                    payload: {
                        ...payload,
                        populationId: this.populationId
                    }
                });
                break;

            default:
                this.eventBus.emit(event);
                break;
        }
    }

    handleSwitchView(type) {
        const action = { type: 'handleSwitchView', payload: type };
        this.eventBus.emit(action);
    }

    openFlowchart(event: MouseEvent) {
        this.router.navigate(['/protocols', this.protocol.id, 'flowchart']);
        event.stopPropagation();
        return false;
    }

    createFlowchart(event: MouseEvent) {
        this.eventBus.emit({
            type: 'handleCreateFlowchart',
            payload: {
                protocolId: this.protocol.id
            }
        });
        event.stopPropagation();
        return false;
    }

    deleteFlowchart(event: MouseEvent) {
        this.eventBus.emit({
            type: 'handleDeleteFlowchart',
            payload: {
                id: this.protocol.flowchart.cards[0].id,
                protocolId: this.protocol.id,
                removeStart: true
            }
        });
        event.stopPropagation();
        return false;
    }

    private _setTitle() {
        this.titleService.setTitle('Alii - ' + this.protocol.title + ' - ' + this.version);
    }

    private _setupScrollListener() {
        if (this.scrollListener) {
            this.scrollListener();
        }
        this.scrollListener = setupScrollNavigationLinks(this.scrollRef, this.renderer, paragraphId => {
            this.scrollParagraphId = paragraphId;
            this.cdr.markForCheck();
        });
    }
}
