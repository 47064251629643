<div class="button-group mb-1">
    <div [id]="'cogwheel-paragraph-' + ppdId" class="btn-group cogwheel-paragraph" ngbDropdown placement="bottom-right">
        <button
            type="button"
            class="button -light"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
        >
            <i class="fa fa-cog"></i>
        </button>
        <ul class="dropdown-menu right" ngbDropdownMenu>
            <li *ngIf="level == 3 && !isModel && !isSummary">
               <a class="dropdown-item" style="cursor: pointer;" (click)="onClickOpenSheetModelUpdateModal(create)">
                {{'PARAGRAPH.EDIT.CREATEMODELFROMSHEET' | translate}}
                </a>
            </li>
            <li *ngIf="level == 1">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickOpenSheetModelImportModal()">
                    {{'PARAGRAPH.EDIT.CREATEMODELFROMSHEET' | translate}}
                </a>
            </li>
            <li *ngIf="level == 1 && type != 'keyQuestion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickConvertToKeyQuestion()">
                    {{'PARAGRAPH.EDIT.CONVERTKEYQUESTION' | translate}}</a
                >
            </li>

            <li>
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickAddFile()"> {{ 'PARAGRAPH.EDIT.ADDFILE'| translate }}</a>
            </li>

            <li *ngIf="type == 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickEditTags()"> {{ 'PARAGRAPH.EDIT.EDITTAGS'| translate }}</a>
            </li>
            
            <li *ngIf="type != 'conclusion' && !keyQuestionId">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickConnectKQ()"> {{ 'PARAGRAPH.EDIT.CONNECTKEYQUESTION'| translate }}</a>
            </li>
            <li *ngIf="type != 'conclusion' && keyQuestionId" >
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickConnectKQ()"> {{ 'PARAGRAPH.EDIT.CHANGEKEYQUESTION'| translate }}</a>
            </li>
            <li *ngIf="type != 'conclusion' && updateKeyQuestion" >
                <a class="dropdown-item" style="cursor: pointer;" (click)="onUpdateKQ()"> {{ 'PARAGRAPH.EDIT.UPDATEQUESTION'| translate }}</a>
            </li>
            <li *ngIf="type != 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickAddReference()"> {{ 'PARAGRAPH.EDIT.ADDREFERENCE'| translate }}</a>
            </li>
            <li *ngIf="type != 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickImportReferences()"> {{ 'PARAGRAPH.EDIT.IMPORTREFERENCE'| translate }}</a>
            </li>
            <li *ngIf="type == 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="handleSelectArticles()"> {{ 'PARAGRAPH.EDIT.SELECTREFERENCE'| translate }}</a>
            </li>
            <li *ngIf="level > 1">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickLinkProtocol()"> {{ 'PARAGRAPH.EDIT.LINKTOPROTOCOL'| translate }}</a>
            </li>
            <li *ngIf="sourceInfo && sourceInfo.count > 0 && !sourceInfo.hasGrade && type != 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickCreateGrade()"> {{ 'PARAGRAPH.EDIT.ADDGRADE'| translate }}</a>
            </li>
            <li *ngIf="sourceInfo && sourceInfo.hasGrade">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickDeleteGrade()"> {{ 'PARAGRAPH.EDIT.DELETEGRADE'| translate }}</a>
            </li>
            <li *ngIf="sourceInfo && sourceInfo.count > 0 && !sourceInfo.hasGrade && type == 'conclusion'">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickCreateGrade()"> {{ 'PARAGRAPH.EDIT.ADDGRADE'| translate }}</a>
            </li>
            <hr *ngIf="level == 1 || isSummary || isModel " />
            <li *ngIf="!isSummary && !isModel && level == 1">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickConvertToSummary()">
                    {{ 'SUMMARY.CONVERT'| translate }}
                </a>
            </li>
            <li *ngIf="isSummary">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickConvertNormalPar()">
                    {{ 'SUMMARY.CONVERTBACK'| translate }}
                </a>
            </li>
            <li *ngIf="isModel">
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickOpenSheetModelUpdateModal(update)">
                    {{ 'MODEL.EDIT.UPDATESHEET'| translate }}
                </a>
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickOpenSheetLiteratureUpdateModal(literature)">
                    {{ 'MODEL.EDIT.UPDATELITERATURE'| translate }}
                </a>
                <a class="dropdown-item" style="cursor: pointer;" (click)="onClickCreateCalculationFormula()">
                    {{ 'MODEL.EDIT.CREATECALCULATION'| translate }}
                </a>
            </li>
            <hr/>
            <li><a class="dropdown-item" style="cursor: pointer;" (click)="onClickDeletePar()"> {{ 'PARAGRAPH.EDIT.DELETE'| translate }} </a></li>
        </ul>
    </div>
</div>
