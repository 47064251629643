import { ArticleService } from './article.service';
import { AuthService } from './auth.service';
import { BrowserService } from './browser.service';
import { CommentService } from './comment.service';
import { DashboardService } from './dashboard.service';
import { FlowchartService } from './flowchart.service';
import { HttpStateService } from './http-state.service';
import { LanguageService } from './language.service';
import { LiteraturesService } from './literatures.service';
import { ModelsService } from './models.service';
import { NavigationService } from './navigation.service';
import { OrganisationService } from './organisation.service';
import { OrganisationsService } from './organisations.service';
import { ParagraphsService } from './par.service';
import { PatientService } from './patient.service';
import { ProfileService } from './profile.service';
import { ProtocolService } from './protocol.service';
import { ProtocolsService } from './protocols.service';
import { PubmedService } from './pubmed.service';
import { PubSubService } from './pubsub';
import { ShopService } from './shop.service';
import { TermsService } from './terms.service';
import { ThemingService } from './theming.service';
import { UploadService } from './upload.service';

export const services = [
    ArticleService,
    AuthService,
    BrowserService,
    CommentService,
    DashboardService,
    FlowchartService,
    HttpStateService,
    LanguageService,
    LiteraturesService,
    ModelsService,
    NavigationService,
    OrganisationService,
    OrganisationsService,
    ParagraphsService,
    PatientService,
    ProfileService,
    ProtocolService,
    ProtocolsService,
    PubmedService,
    PubSubService,
    ShopService,
    TermsService,
    ThemingService,
    UploadService
];

export * from './article.service';
export * from './auth.service';
export * from './browser.service';
export * from './comment.service';
export * from './dashboard.service';
export * from './flowchart.service';
export * from './http-state.service';
export * from './language.service';
export * from './literatures.service';
export * from './models.service';
export * from './navigation.service';
export * from './organisation.service';
export * from './organisations.service';
export * from './par.service';
export * from './patient.service';
export * from './profile.service';
export * from './protocol.service';
export * from './protocols.service';
export * from './pubmed.service';
export * from './pubsub';
export * from './shop.service';
export * from './terms.service';
export * from './theming.service';
export * from './upload.service';
