import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import { Protocol } from '../../../../models/protocol.interface';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
@Component({
    //changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'protocol-report',
    templateUrl: './protocol-report.component.html',
    styleUrls:  ['./protocol-report.component.scss']
})
export class ProtocolReportComponent {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    modelFindings: any;

    @Input()
    modelOutcomes: any;

    @Input()
    modelTagList: any;

    @Input()
    loading = true;

    @Input()
    populationId: string;

    @Input()
    content: Protocol;

    @Input()
    protocol: any;

    @Input()
    isFlowchart: boolean;

    @Input()
    isChartView?: boolean;

    @Input()
    isTextView?: boolean;

    @Input()
    versionId?: string;

    @Input()
    version?: string;

    config: ExportAsConfig = {
        type: 'pdf', // the type you want to download
        elementIdOrContent: 'protocolContent' // the id of html/table element
    };
    constructor(private exportAsService: ExportAsService) {}

    printPdf() {
        this.exportAsService.save(this.config, this.content.title);
        this.exportAsService.get(this.config).subscribe(content => {
          });
    }

    print() {
        window.print();
        return true;
    }

    objectToArray(o) {
        return o ? Object.values(o) : [];
    }

    type(par) {
        if (par['protocol_paragraph.title'].includes('--report')) {return "report"}
        if (par['protocol_paragraph.title'].includes('--compare')) {return "goals"}
        if (par['protocol_paragraph.title'].includes('--frequency')) {return "frequency"}
        if (par['protocol_paragraph.title'].includes('--instruments')) {return "instruments"}
        if (par.is_model) {return "model"}
        return 'text'

    }

    getTitle(par) {
        let title = par['protocol_paragraph.title'].split("--")[0]
        return title
    }

    filterChildren(par, type) {
        let children = []
        par.children.forEach(c => {
            if (this.type(c) === type) {
                children.push(c)
            }
        });
        return children
    }


    getInfoFromModel(model) {
        // returns all the findings except when it's a yes/no question
        let findings = []
        Object.values(this.modelFindings[model.id]).forEach(question => {
            Object.values(question['options']).forEach(option => {
                if (
                    option['selected'] === true &&
                    option['title'] !== 'Ja' &&
                    option['title'] !== 'Nee'           
                ) {
                    let title = option['title'].replace('Ja, ', '')
                    findings.push(title)}
            })
            
        });
        return findings
    }

    thumbLeftPos(option) {
        return 'calc(' + (+option.start)*5 + '% - 20px)'
    }


    thumbWidth(option) {
        return 'calc(' + (((+option.end)-(+option.start))*5) + '%)'
    }

    closePrintPdf() {
        const action = { type: 'close-print', payload: {} };
        this.eventBus.emit(action);
    }

    public akwaThemesText(text) {
        let goal = this.akwaGoalText(text);
        var targetIndex = goal.indexOf('meten');
        if (targetIndex !== -1) {
            var result = goal.substring(targetIndex+6);
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
        
        return goal;
    }

   
    get sortedProblems() {
        const questionTitle = "Waar heb je last van?";
        const data = this.modelFindings;
        let sortedProblems = [];
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                  const option = options[optionId];
                  const optionValue = option.value;
                  sortedProblems.push(optionValue);
                }

                break;
            }    
          }
        }
        return sortedProblems;
      }
      

      get sortedActions() {
        const questionTitle = "Wat ga je doen?";
        const data = this.modelFindings;
        let sortedActions = [];
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                  const option = options[optionId];
                  const optionValue = option.value;
                  sortedActions.push(optionValue);
                }

                break;
            }    
          }
        }
        return sortedActions;
      }
      

      get evaluatieFrequentie() {
        const questionTitle = "Hoe vaak wil je evalueren?";
        const data = this.modelFindings;
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                    const option = options[optionId];
                    return option.value;
                }
                break;
            }    
          }
        }
        return "";
      }

      get otherStandardList() {
        const questionTitle = "Welke vragenlijsten worden standaard gebruikt?";
        const data = this.modelFindings;
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                    const option = options[optionId];
                    if (option.selected && option.title === "Andere standaard vragenlijst") 
                        return option.value;                
                }
                break;
            }    
          }
        }
        return "";
      }

      get betrokkenNaasten() {
        const questionTitle = "Wil je naasten betrekken?";
        const data = this.modelFindings;
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                    const option = options[optionId];
                    if (option.selected) 
                        return option.title;                
                }
                break;
            }    
          }
        }
        return "";
      }


      get sortedTargets() {
        const questionTitle = "Wat wil je bereiken?";
        const data = this.modelFindings;
        let sortedTargets = [];
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;
          
                for (const optionId in options) {
                  const option = options[optionId];
                  const optionValue = option.complex_value;
                  sortedTargets.push(optionValue);
                }

                break;
            }    
          }
        }
        return sortedTargets;
      }

    public akwaGoalTitle(text) {
        if  (text === "Anders, namelijk") {
            return this.otherStandardList;
        }

        var targetIndex = text.indexOf('--');
  
        if (targetIndex !== -1) {
            var result =  text.substring(0, targetIndex);
            return result.charAt(0).toUpperCase() + result.slice(1);
        }
        
        return text;
    }

    public akwaGoalText(text) {
        var targetIndex = text.indexOf(', de invultijd wordt geschat op');
  
        if (targetIndex !== -1) {
            return text.substring(0, targetIndex);
        }
        
        return '';
    }

    get akwaHasNoOutcomes() {
        for (const m in this.modelOutcomes) {
            const model = this.modelOutcomes[m];
            for (let parent in model) {
                if (this.modelOutcomes[m][parent].length !== 0) {
                    return false;
                }
            }
        }
        return true;
    }

    get akwaGetAllSelectedThemes() {
        const questionTitle = "Waar ga je aan werken?";
        const data = this.modelFindings;
        let themes = [];
      
        for (const paragraphId in data) {
          const model = data[paragraphId];
          for (const q in model) {
            const question = data[paragraphId][q];
            if (question["protocol_paragraph.title"] === questionTitle) {
                const options = question.options;          
                for (const optionId in options) {
                  const option = options[optionId];
                  const optionValue = option.title;
                  if (option.selected) {
                    themes.push(optionValue);
                  }

                }

                break;
            }    
          }
        }
        return themes;
    }

    akwaIsDuplicateList(title, id) {
        for (let m in this.modelOutcomes) {
            const model = this.modelOutcomes[m];
            for (let parent in model) {
                const parentOutcome = this.modelOutcomes[m][parent];
                for (let outcome of parentOutcome.outcomeList) {
                    console.log("comparing ", outcome.title, ' with ', title);
                    if (outcome.title.indexOf(title) !== -1 && id !== outcome.id) {
                        return true;
                    }          
                }
            }
        }
        
        return false;
    }

    akwaGetGoalTextVaste(title) {
        for (let m in this.modelOutcomes) {
            const model = this.modelOutcomes[m];
            for (let parent in model) {
                const parentOutcome = this.modelOutcomes[m][parent];
                for (let outcome of parentOutcome.outcomeList) {
                    if (outcome.title.includes(title) && this.akwaGoalText(outcome.text)) {
                        return this.akwaGoalText(outcome.text);
                    }          
                }
            }
        }

        return "";
        return "Geen van de geselecteerde onderwerpen wordt gemeten met deze vragenlijst";
    }

    trackByFn = (index, item) => item.id || index;
}