import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { Paragraph, SelectedFootnote } from '../../../../../models/paragraph.interface';
import { KeyQuestionImport } from '../../../../../models/keyquestion-import.interface';
import { ModelSheetUpdateModalComponent } from './model-edit/model-sheet-update-modal/model-sheet-update-modal.component';
import { LiteratureImportModalComponent } from './literature-import-modal/literature-import-modal.component';


import { GradeFormModalComponent, SearchPubmedModalComponent } from '../../../entry-components';
import { PubSubService } from '../../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-edit',
    templateUrl: './paragraph-edit.component.html',
    styleUrls: ['./paragraph-edit.component.scss']
})
export class ParagraphEditComponent implements OnInit, OnChanges {
    @Input()
    channelMessages: any;

    @Input()
    protocolId: string;

    @Input()
    hasNumberedIndex: boolean;

    @Input()
    parentId: string;

    @Input()
    paragraph: Paragraph;

    @Input()
    index: number;

    @Input()
    parentIndex: number;
    
    @Input()
    keyQuestionTypeIndex: number;

    @Input()
    paragraphSelectedId: string;

    @Input()
    isEditAble: boolean;

    @Input()
    hasFindings: boolean;

    @Input()
    modelMessages: any;

    @Input()
    keyQuestionImportList: KeyQuestionImport[];

    @Input()
    gradeAssessment: any;

    @Input()
    parentSources: any

    @Input()
    parentSourceTable: any

    @Input()
    paragraphsFiles: any;

    @Input()
    pubmeds: any;

    @Input()
    protocolArticle: any;

    @Input()
    modelFindings: any;

    @Input()
    modelOutcomes: any;

    @Input()
    modelTagList: any;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input()
    updateModelBySheetIdArrayLoading: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    isRoot: boolean;

    @Input()
    populationId: string;

    selectedQuestionIndex = 0;
    isCreateGrade = false;

    searchQuery = '';

    selectedFootnote$ = new Subject<SelectedFootnote>();
    loadingSourceTable: boolean;

    constructor(
        private translateService: TranslateService,
        private modalService: NgbModal, 
        private sanitizer: DomSanitizer, 
        private pubsub: PubSubService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        const { gradeAssessment } = changes;

        if (this.isCreateGrade && gradeAssessment && !gradeAssessment.firstChange) {
            const {
                currentValue: { selected }
            } = gradeAssessment;
            if (selected) {
                setTimeout(() => {
                    this.isCreateGrade = false;
                    this.modalService.dismissAll();
                    this.openCreateGrade();
                });
            }
        }
        if (changes.pubmeds && !changes.pubmeds.firstChange) {
            if (!changes.pubmeds.currentValue.loading && changes.pubmeds.currentValue.loaded) {
                setTimeout(() => {
                    this.handleOpenSearch();
                });
            }
        }
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'onHandleClickOption':
                this.eventBus.emit({
                    type: 'onHandleClickOption',
                    payload: { ...payload, paragraphId: this.paragraph.id }
                });
                break;

            case 'onClickOpenSheetModelUpdateModal':
                this.handleOpenSheetModelUpdateModal();
                break;
            
            case 'handleOpenImportReferencesModal':
                this.handleOpenImportReferencesModal();
                break;

            case 'onClickOpenSheetLiteratureUpdateModal':
                this.handleOpenSheetLiteratureUpdateModal();
                break;

            case 'onClickOpenSheetModelImportModal':
                this.onClickOpenSheetModelImportModal();
                break;

            case 'onClickCreateCalculationFormula':
                this.handleCreateCalculationFormula(payload);
                break;

            case 'onClickArticle':
                this.onClickArticle(payload);
                break;

            case 'onClickCreateGrade':
                this.onClickCreateGrade(payload);
                break;

            case 'onClickDeleteGrade':
                this.onClickDeleteGrade(payload);
                break;    

            case 'onClickUpdateGrade':
                this.onClickUpdateGrade(payload);
                break;

            case 'onClickAddReference':
                this.onClickAddReference();
                break;

            default:
                this.eventBus.emit(event);
                break;
        }
    }


    onClickAddReference() {
        this.handleOpenSearch();
    }

    // TODO: Is this ever used? See paragraph-edit-options.component.ts
    handleOpenSearch() {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(SearchPubmedModalComponent, { size: 'lg' });
        modalRef.componentInstance.pubmeds = this.pubmeds && this.pubmeds.results ? this.pubmeds.results : [];
        modalRef.componentInstance.ppdId = this.pubmeds && this.pubmeds.ppdId ? this.pubmeds.ppdId : this.paragraph.id;
        modalRef.componentInstance.parentId =  this.parentId;
        modalRef.componentInstance.query = this.pubmeds && this.pubmeds.query ? this.pubmeds.query : this.searchQuery;
        modalRef.componentInstance.searchLoading = false;

        modalRef.componentInstance.eventBus.subscribe(event => {
            switch (event.type) {
                case 'handleGetPubmedSearch':
                    this.getSearch(event.payload);
                    break;
                case 'handleAddReference':
                    this.handleAddReference(event.payload);
                    modalRef.dismiss();
                    break;
                default:
                    break;
            }
        });
        modalRef.result.then(
            () => {},
            () => {}
        );
    }

    getSearch(payload) {
        const { query } = payload;
        this.searchQuery = query;
        const event = {
            type: 'handleGetPubmedSearch',
            payload: {
                ppdId: this.paragraph.id,
                parentId: this.parentId,
                query
            }
        };
        this.eventBus.emit(event);
    }

    handleAddReference(payload) {
        this.searchQuery = '';
        const event = {
            type: 'handleAddReference',
            payload: {
                ...payload,
                protocolId: this.protocolId,
                articleIds: payload.uids,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(event);
    }

    onClickCreateGrade(payload) {
        this.isCreateGrade = true;
        this.handleGetGradeQuestions(payload);
    }

    onClickDeleteGrade(payload) {
        const { ppdId } = payload;
        const action = {
            type: 'handleDeleteGrade',
            payload: { protocolId: this.protocolId, ppdId, parentId: this.parentId}
        };
        this.eventBus.emit(action);
    }

    openCreateGrade() {
        if (!this.gradeAssessment.selected) {
            return;
        }
        const modalRef = this.modalService.open(GradeFormModalComponent, { size: 'xl' });
        modalRef.componentInstance.edit = true;
        modalRef.componentInstance.isCreateGrade = !this.gradeAssessment.selected.id;
        modalRef.componentInstance.gradeAssessmentPar = this.gradeAssessment.selected;
        modalRef.componentInstance.parentId = this.parentId;
        if (this.gradeAssessment.selected.questions[this.selectedQuestionIndex].rowData) {
            modalRef.componentInstance.rowData =  JSON.parse(JSON.stringify(this.gradeAssessment.selected.questions[this.selectedQuestionIndex].rowData));
        }
        if (this.gradeAssessment.selected.questions[this.selectedQuestionIndex].fields) {
            modalRef.componentInstance.fieldsRowData =  JSON.parse(JSON.stringify(this.gradeAssessment.selected.questions[this.selectedQuestionIndex].fields.rowData));
        }
        modalRef.componentInstance.selectedQuestion = this.gradeAssessment.selected.questions[
            this.selectedQuestionIndex
        ];
        modalRef.componentInstance.selectedQuestionIndex = this.selectedQuestionIndex;

        modalRef.componentInstance.eventBus.subscribe(event => {
            this.eventBus.emit(event)
        });
        modalRef.componentInstance.handleNavigate.subscribe(index => {
            modalRef.close();
            this.selectedQuestionIndex = index;
            this.openCreateGrade();
        });

        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );
    }

    keyQuestionSubType() {
        if( this.paragraph.type === 'conclusion' ||
            this.paragraph.type === 'recommendation' ||
            this.paragraph.type === 'consideration' ||
            this.paragraph.grandParentId
        ) { 
            return true }
        else {
            return false
        }
    }

    defaultColDef: any = {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'top'
        }
    }

    getSourceTable(par) {
        if(par.sourceTable) {return par.sourceTable }
        
        let rowData = []
        let columnDefs = []
        let sources = {rowData, columnDefs}
        let sourceTable = [sources]
        if(par.type === 'keyQuestion' && !this.loadingSourceTable) {
            this.loadingSourceTable = true
            const loadFindingsAction = {
                type: 'handleLoadEvidence',
                payload: {
                    protocolId: this.protocolId,
                    ppdId: this.paragraph.id,
                    parentId: this.parentId
                }
            };
            this.eventBus.emit(loadFindingsAction);
            return sourceTable
        }
        
        return sourceTable
    }

    isScore(score) {
        if (score == 1) {
            return true
        }  else if (score == 2) {
            return true
        }  else if (score == 3) {
            return true
        }  else if (score == 4) {
            return true
        }
        return false
    }

    getKeyQuestionSubTypes() {
        let title = ''
        let typeList = [
            {type:'conclusion', translate: 'PARAGRAPH.CONCLUSIONS'}, 
            {type: 'consideration', translate: 'PARAGRAPH.CONSIDERATIONS'}, 
            {type:'recommendation', translate: 'PARAGRAPH.RECOMMENDATIONS'}]
        let typeSet = []
        typeList.forEach(type => {
            // only for non draft this.getChildren(this.paragraph, type)
            this.translateService.get(type.translate).subscribe(translation => title = translation)
            let element = {
                index: this.createIndex(), 
                type: type.type,
                title}
            typeSet.push(element)
        });
        return typeSet
    }

    createIndex() {
        if(!this.hasNumberedIndex) { return undefined }
        let index = 0
        let indexString = ''
        if (this.parentIndex !== undefined) {index = this.parentIndex + 1; indexString = index + '.'}
        if (this.keyQuestionTypeIndex !== undefined) {index = this.keyQuestionTypeIndex + 1; indexString = indexString + index + '.'}
        index = this.index + 1
        if (indexString === '') {
            indexString = indexString + index + '.'
        } else {
            indexString = indexString + index
        }
        return indexString
    }

    handleGetGradeQuestions(payload) {
        
        const { ppdId = false, populationId = false, gradeId = false, parentId = false } = payload;
    
        const outPayload = { protocolId: this.protocolId, gradeId: gradeId, parentId: this.parentId };
        
        if (ppdId) {
            outPayload['ppdId'] = ppdId;
        }
        if (populationId) {
            outPayload['populationId'] = populationId;
        }

        const getQuestionsAction = {
            type: 'handleGetGradeQuestions',
            payload: outPayload
        };
        this.eventBus.emit(getQuestionsAction);
    }

    

    handleSubmitUpdateGrade(payload) {
        const { ppdId, key, value, gradeId, comment } = payload;
        const action = {
            type: 'handleSubmitUpdateGrade',
            payload: {
                protocolId: this.protocolId,
                ppdId,
                key,
                value,
                gradeId,
                comment
            }
        };
        this.eventBus.emit(action);
    }

    handleSubmitCreateGrade(payload) {
        const { ppdId, key, value, type, comment, parentId } = payload;
        const action = {
            type: 'handleSubmitCreateGrade',
            payload: { protocolId: this.protocolId, ppdId, key, value, type, comment, parentId }
        };
        this.eventBus.emit(action);
        //this.modalService.dismissAll();

        switch (type) {
            case 'paragraph':
                this.pubsub.$pub(type + 'GradeCreated' + this.paragraph.id, { field: key });
                break;

            case 'population':
            case 'outcoome':
                this.onClickUpdateGrade({ ppdId, field: key });
                break;

            default:
                break;
        }
    }

    onClickUpdateGrade(payload) {
        const { field } = payload;
        const index = this.getQuestionIndex(field);
        if (index < 0) {
            return;
        }
        this.selectedQuestionIndex = index;
        this.isCreateGrade = false;
        this.openCreateGrade();
    }

    handleGradeUpdateRowAction(payload) {
        this.eventBus.emit(payload);
    }

    getQuestionIndex(field) {
        if (!this.gradeAssessment.selected.questions) {
            return -1;
        }
        return this.gradeAssessment.selected.questions.findIndex(question => question.field === field);
    }


    private handleOpenImportReferencesModal() {
        const modalRef = this.modalService.open(LiteratureImportModalComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.ppdId = this.paragraph.id;
        modalRef.componentInstance.action = 'import'
        modalRef.result.then(
            result => {
                this.handleImportReferences(result);
            },
            () => {} // dismiss reason
        );
    }



    private handleOpenSheetModelUpdateModal() {
        const modalRef = this.modalService.open(ModelSheetUpdateModalComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.ppdId = this.paragraph.id;
        modalRef.componentInstance.sheetId = this.paragraph.sheetId;
        modalRef.componentInstance.action = 'updateModel'
        modalRef.result.then(
            result => {
                this.handleUpdateModelBySheetId(result);
            },
            () => {} // dismiss reason
        );
    }

    private handleOpenSheetLiteratureUpdateModal() {

        const modalRef = this.modalService.open(ModelSheetUpdateModalComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.ppdId = this.paragraph.id;
        modalRef.componentInstance.sheetId = this.paragraph.sheetId;
        modalRef.componentInstance.action = 'updateLiterature';
        modalRef.result.then(
            result => {
                this.handleUpdateModelBySheetId(result);
            },
            () => {} // dismiss reason
        );
    }


    private handleImportReferences(result) {
        const action = {
            type: 'handleImportReferences',
            payload: {
                protocolId: this.protocolId,
                ppdId: result.ppdId,
                parentId: this.parentId,
                sheetId: result.sheetId,
                action: result.action
            }
        };
        this.eventBus.emit(action);
    }

    private handleUpdateModelBySheetId(result) {
        const action = {
            type: 'handleUpdateModelBySheetId',
            payload: {
                protocolId: this.protocolId,
                ppdId: result.ppdId,
                parentId: this.parentId,
                sheetId: result.sheetId,
                action: result.action
            }
        };
        this.eventBus.emit(action);
    }

    private handleCreateCalculationFormula(result) {
        const action = {
            type: 'handleCreateCalculationFormula',
            payload: {
                parentId: result.parentId,
                ppdId: result.ppdId
            }
        };
        this.eventBus.emit(action);
    }

    onClickOpenSheetModelImportModal() {
        const modalRef = this.modalService.open(ModelSheetUpdateModalComponent, {
            size: 'xl' as 'lg'
        });

        modalRef.componentInstance.ppdId = this.paragraph.id;
        modalRef.componentInstance.sheetId = this.paragraph.sheetId;
        modalRef.result.then(
            result => {
                this.handleImportModelBySheetId(result);
            },
            () => {} // dismiss reason
        );
    }

    handleImportModelBySheetId(result) {
        const action = {
            type: 'handleImportModelBySheetId',
            payload: {
                protocolId: this.protocolId,
                parentId: result.ppdId,
                sheetId: result.sheetId
            }
        };
        this.eventBus.emit(action);
    }

    onClickArticle(payload) {
        const { articleId } = payload;
        const action = {
            type: 'handleLoadProtocolArticle',
            payload: {
                protocolId: this.protocolId,
                articleId
            }
        };
        this.eventBus.emit(action);
    }

    getGradeAssessmentPar(ppdId) {
        return this.gradeAssessment.entries ? this.gradeAssessment.entries[ppdId] : null;
    }

    getChildren(paragraph, subType) {
        let childrenList = []
        if (subType != 'main') {
            paragraph.children.forEach(child => {
                if (child.type === subType) {
                    childrenList.push(child)
                } 
            });
        }
        if (subType === 'main') {
            paragraph.children.forEach(child => {
                if (!child.type || child.type === 'keyQuestion') {
                    childrenList.push(child)
                } 
            });
        }
        return childrenList
    }

    getFilesPar(ppdId) {
        return ppdId in this.paragraphsFiles.entries ? this.paragraphsFiles.entries[ppdId] : [];
    }

    getParChildren(pars, parentId) {
        return pars.map(par => {
            return { ...par, parentId };
        });
    }

    handleUpdateParPosition(payload) {
        if (this.isEditAble) {
            this.eventBus.emit({
                type: 'handleUpdateParPosition',
                payload
            });
        }
    }

    textUpdated() {
        // Do something here if you want to do something whenever the text is changed..
    }

    trackByFn = (index, item) => item.id || index;
}
