import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ParagraphHelperService } from '../../../../shared/helpers';

import * as protocolCategoriesActions from '../actions/protocol-categories.action';
import * as protocolsActions from '../actions/protocols.action';
import * as flowchartActions from '../actions/flowchart.action';
import * as gradeActions from '../actions/grade-assessment.action';
import * as parFilesActions from '../actions/paragraphs-files.action';
import * as modelFindingActions from '../actions/models-findings.action';
import * as summaryRowActions from '../actions/summary-rows.action';
import * as modelOutcomeActions from '../actions/models-outcomes.action';
import * as protocolAllOutcomesActions from '../actions/protocol-all-outcomes.action';

import * as fromService from '../../../../services';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services'

@Injectable()
export class ProtocolsEffects {
    constructor(
        private actions$: Actions,
        private ProtocolsService: fromService.ProtocolsService,
        private router: Router,
        public paragraphHelper: ParagraphHelperService,
        private toastr: ToastrService,
        private authService: AuthService
    ) {}

    @Effect()
    SearchProtocolsEffect$ = this.actions$.pipe(
        ofType(protocolsActions.GET_PROTOCOLS_BY_QUERY),
        switchMap(action => {
            const {
                payload: { query }
            } = action as any;
            return this.ProtocolsService.getProtocolsByQuery(query).pipe(
                map(protocols => new protocolsActions.GetProtocolsByQuerySuccess({ protocols })),
                catchError(error => of(new protocolsActions.GetProtocolsByQueryFail(error)))
            );
        })
    );

    @Effect()
    CopyProtocolEffect$ = this.actions$.pipe(
        ofType(protocolsActions.COPY_PROTOCOL),
        switchMap(action => {
            const {
                payload: { parentId }
            } = action as any;
            return this.ProtocolsService.copyProtocol({ parentId }).pipe(
                map(response => {
                    const { protocol_id: protocolId } = response;
                    return new protocolsActions.CopyProtocolSuccess({ protocolId });
                }),
                catchError(error => of(new protocolsActions.CopyProtocolFail(error)))
            );
        })
    );

    @Effect()
    CreateProtocolEffect$ = this.actions$.pipe(
        ofType(protocolsActions.CREATE_PROTOCOL),
        switchMap(action => {
            const { payload } = action as any;
            return this.ProtocolsService.createProtocol(payload).pipe(
                map(response => {
                    const { protocol_id: protocolId } = response;
                    return new protocolsActions.CreateProtocolSuccess({ protocolId });
                }),
                catchError(error => of(new protocolsActions.CreateProtocolFail(error)))
            );
        })
    );

    @Effect()
    DeleteProtocolEffect$ = this.actions$.pipe(
        ofType(protocolsActions.DELETE_PROTOCOL),
        switchMap(action => {
            const { payload } = action as any;
            return this.ProtocolsService.deleteProtocol(payload).pipe(
                map(response => {
                    if (response.error) {
                        return new protocolsActions.DeleteProtocolFail(response);
                    } else {
                        return new protocolsActions.DeleteProtocolSuccess(response);
                    }
                }),
                catchError(error => of(new protocolsActions.DeleteProtocolFail(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    CreateProtocolSuccessEffect$ = this.actions$.pipe(
        ofType(protocolsActions.CREATE_PROTOCOL_SUCCESS, protocolsActions.COPY_PROTOCOL_SUCCESS),
        tap(action => {
            const {
                payload: { protocolId }
            } = action as any;
            return this.router.navigate(['/protocols', protocolId], { queryParams: { version: 'Draft' } });
        })
    );

    @Effect()
    CreateLocalProtocolEffect$ = this.actions$.pipe(
        ofType(protocolsActions.CREATE_LOCAL_PROTOCOL),
        switchMap(action => {
            const { payload } = action as any;
            const { parentId } = payload;
            return this.ProtocolsService.createLocalProtocol({ parentId }).pipe(
                map(response => {
                    const { protocol_id: protocolId } = response;
                    return new protocolsActions.CreateLocalProtocolSuccess({ protocolId });
                }),
                catchError(error => of(new protocolsActions.CreateLocalProtocolFail(error)))
            );
        })
    );

    @Effect({ dispatch: false })
    CreateLocalProtocolSuccessEffect$ = this.actions$.pipe(
        ofType(protocolsActions.CREATE_LOCAL_PROTOCOL_SUCCESS),
        tap(action => {
            const {
                payload: { protocolId }
            } = action as any;
            return this.router.navigate(['/protocols', protocolId]);
        })
    );

    @Effect()
    CreateLocalProtocolFailEffect$ = this.actions$.pipe(
        ofType(protocolsActions.CREATE_LOCAL_PROTOCOL_FAIL),
        mergeMap((protocol: any) => {
            const msg = protocol.payload.message
                ? protocol.payload.message
                : 'The requested protocol could not be created.';
            this.router.navigate(['/home']);
            this.toastr.error('', msg);
            return [];
        })
    );

    @Effect()
    loadCategoriesEffect$ = this.actions$.pipe(
        ofType(protocolCategoriesActions.LOAD_PROTOCOL_CATEGORIES),
        switchMap(() => {
            return this.ProtocolsService.getAllByCategory().pipe(
                map(categories => new protocolCategoriesActions.LoadProtocolCategoriesSucces(categories)),
                catchError(error => of(new protocolCategoriesActions.LoadProtocolCategoriesFail(error)))
            );
        })
    );

    @Effect()
    loadSelectedEffect$ = this.actions$.pipe(
        ofType(protocolsActions.LOAD_PROTOCOL),
        switchMap(action => {
            const {
                payload: { protocolId, versionId, version, populationId, viewByPopulation, showAllOutcomes, compareVersion }
            } = action as any;
            return this.ProtocolsService.getProtocol({
                protocolId,
                versionId,
                version,
                populationId,
                viewByPopulation,
                showAllOutcomes,
                compareVersion
            }).pipe(
                map(protocol =>
                    'error' in protocol && ('message' in protocol['error'] || 'error' in protocol['error'])
                        ? new protocolsActions.LoadProtocolFail(protocol)
                        : new protocolsActions.LoadProtocolSuccess(protocol)
                ),
                catchError(error => of(new protocolsActions.LoadProtocolFail(error)))
            );
        })
    );

    @Effect()
    loadSelectedSuccessEffect$ = this.actions$.pipe(
        ofType(protocolsActions.LOAD_PROTOCOL_SUCCESS),
        mergeMap(action => {
            const { payload: protocol } = action as any;

            if (protocol.theme) {
                this.authService.setTheme(protocol.theme)
            }

            // const messageNotAllowed = protocol.message ? protocol.message : '';
            const {
                flowchart: { cards = [], start, scheme, yesText, noText },
                paragraphs = []
            } = protocol;
            const {
                gradeAssessment = {},
                files = {},
                rows = {},
                questions: findings = {},
                questions = {},
                outcomes = {},
                tagList = {},
                populations = {}
            } = this.paragraphHelper.flattenParagraph(paragraphs).reduce((acc, par) => {
                const arrOutcomes = par.outcomes ? [...par.outcomes] : [];
                const arrQuestions = par.questions ? [...par.questions] : [];
                const arrTagList = par.tagList ? [...par.tagList] : [];
                return {
                    files: {
                        ...acc.files,
                        [par.id]: par.files ? [...par.files] : []
                    },
                    comments: {
                        ...acc.comments,
                        [par.id]: par.comments ? [...par.comments] : []
                    },
                    rows: {
                        ...acc.rows,
                        [par.id]: par.rows ? [...par.rows] : []
                    },
                    sources: {
                        ...acc.sources,
                        [par.id]: par.sources ? [...par.sources] : []
                    },
                    gradeAssessment: {
                        ...acc.gradeAssessment,
                        [par.id]: par.gradeAssessment
                            ? {
                                  id: par.gradeAssessment.id ? par.gradeAssessment.id : null,
                                  action: par.gradeAssessment.action,
                                  values: par.gradeAssessment.questions ? par.gradeAssessment.questions : {},
                                  type: 'paragraph'
                              }
                            : {},
                        ...{
                            ...arrOutcomes.reduce((acumulate, outcome) => {
                                const gradeOutcome = outcome.gradeAssessment
                                    ? {
                                          [outcome.id]: {
                                              id: outcome.gradeAssessment.id ? outcome.gradeAssessment.id : null,
                                              action: outcome.gradeAssessment.action,
                                              values: outcome.gradeAssessment.questions
                                                  ? outcome.gradeAssessment.questions
                                                  : {},
                                              type: 'outcome'
                                          }
                                      }
                                    : {};

                                const gradePopulations = (outcome.populations || []).reduce(
                                    (accumulate, population) => {
                                        const gradePopulation = population.gradeAssessment
                                            ? {
                                                  [population.id]: {
                                                      id: population.gradeAssessment.id
                                                          ? population.gradeAssessment.id
                                                          : null,
                                                      action: population.gradeAssessment.action,
                                                      values: population.gradeAssessment.questions
                                                          ? population.gradeAssessment.questions
                                                          : {},
                                                      type: 'population'
                                                  }
                                              }
                                            : {};
                                        return { ...accumulate, ...gradePopulation };
                                    },
                                    {}
                                );
                                return { ...acumulate, ...gradeOutcome, ...gradePopulations };
                            }, {})
                        }
                    },
                    questions: {
                        ...acc.questions,
                        [par.id]: par.questions
                            ? arrQuestions.reduce((accQuestion, question) => {
                                  const options = question.options.reduce((accOption, option) => {
                                      return { ...accOption, [option.id]: option };
                                  }, {});
                                  return { ...accQuestion, [question.id]: { ...question, options } };
                              }, {})
                            : {}
                    },
                    outcomes: {
                        ...acc.outcomes,
                        [par.id]: par.outcomes ? [...par.outcomes] : []
                    },
                    tagList: {
                        ...acc.tagList,
                        [par.id]: par.tagList ? [...par.tagList] : []
                    },
                    populations: {
                        ...acc.populations,
                        [par.id]: arrOutcomes.reduce((accPopulations, outcome) => {
                            return [
                                ...accPopulations,
                                ...(outcome.populations || []).map(population => {
                                    return {
                                        ...population,
                                        population_properties: population['population properties'],
                                        //outcome: {populations, ...outcome}
                                        outcome: outcome
                                    };
                                })
                            ];
                        }, [])
                    }
                };
            }, {});
            return [
                new flowchartActions.LoadCards({ cards, startPoint: start, scheme, yesText, noText }),
                new gradeActions.MapGradesReviews({ gradeAssessment }),
                new parFilesActions.MapParagraphFiles({ files }),
                new summaryRowActions.MapSummaryRows({ rows }),
                new modelFindingActions.MapModelsFindings({ findings }),
                new modelOutcomeActions.MapModelTagList({ tagList }),
                new modelOutcomeActions.MapModelOutcomes({ outcomes }),
                new protocolAllOutcomesActions.LoadProtocolWithAllOutcomesSuccess({
                    protocol,
                    questions,
                    populations
                })
            ];
        })
    );

    @Effect()
    LoadProtocolFailEffect$ = this.actions$.pipe(
        ofType(protocolsActions.LOAD_PROTOCOL_FAIL),
        mergeMap((fail: any) => {
            const { payload: protocol } = fail;
            const { error } = protocol;

            // if a protocol returns a 404 and the id does not contains hyphens
            // log out this user
            const regex = /protocolId=.{8}-.*/g;
            const hasGuid = fail.payload.url.match(regex);

            if (error.status === 404 && !hasGuid) {
                this.authService.logout();
            }

            // TODO: backend show nicer messages (capital first letter etc.)
            const msg = error.message || 'The requested protocol could not be found.';
            this.toastr.error('', msg);

            // TODO: temp fix, backend should send '/home'
            let location = error.url && error.url !== '/home' ? error.url : '/home';
            let qparams;

            // TODO: backend should send '/path/to/1234?k=v' instead of '/path/to/1234/?k=v'
            if (location.indexOf('?') !== -1) {
                [location, qparams] = location.split('?');
                if (location.endsWith('/')) {
                    location = location.substring(0, location.length - 1);
                }
                location = location + '?' + qparams;
            }
            this.router.navigateByUrl(location);

            return [];
        })
    );

    @Effect()
    SortOutcomesEffect$ = this.actions$.pipe(
        ofType(protocolsActions.LOAD_PROTOCOL_SUCCESS, modelOutcomeActions.UPDATE_OUTCOMES),
        mergeMap(() => {
            return [new modelOutcomeActions.SortOutcomes()];
        })
    );

    @Effect()
    SubmitFlowActionEffect$ = this.actions$.pipe(
        ofType(protocolsActions.SUBMIT_FLOW_ACTION),
        switchMap(action2 => {
            const {
                payload: { action, protocolId, categoryId, comment, versionId }
            } = action2 as any;
            return this.ProtocolsService.submitFlowAction({
                action,
                protocolId,
                categoryId,
                versionId,
                comment
            }).pipe(
                map(
                    response =>
                        new protocolsActions.SubmitFlowActionSuccess({
                            action,
                            protocolId,
                            categoryId,
                            response
                        })
                ),
                catchError(error => of(new protocolsActions.SubmitFlowActionFail(error)))
            );
        })
    );

    @Effect()
    SubmitFlowActionEffectSuccess$ = this.actions$.pipe(
        ofType(protocolsActions.SUBMIT_FLOW_ACTION_SUCCESS),
        mergeMap(payloadAction => {
            const {
                payload: { response = {} }
            } = payloadAction as any;
            const version = response['show'] ? response['show'] : 'Draft';
            const protocolId = 'protocolId' in response ? response['protocolId'] : payloadAction['protocolId'];

            this.router.navigate(['/protocols', protocolId], { queryParams: { version } });
            if ('message' in response) {
                this.toastr.success('', response['message']);
            }

            return [
                new protocolsActions.LoadProtocol({
                    protocolId,
                    versionId: null,
                    version
                })
            ];
        })
    );

    @Effect()
    UpdateSettingEffect$ = this.actions$.pipe(
        ofType(protocolsActions.UPDATE_SETTING),
        switchMap(action => {
            const {
                payload: { protocolId, options }
            } = action as any;
            const { version, versionId } = options;
            return this.ProtocolsService.setProtocol(protocolId, options).pipe(
                map(() => new protocolsActions.UpdateSettingSuccess({ protocolId, version, versionId })),
                catchError(error => of(new protocolsActions.UpdateSettingFail(error)))
            );
        })
    );

    @Effect()
    UpdateSettingSuccessEffect$ = this.actions$.pipe(
        ofType(protocolsActions.UPDATE_SETTING_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId, version, versionId }
            } = action as any;

            return [new protocolsActions.LoadProtocol({ protocolId, versionId, version })];
        })
    );
}
