import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { StartingPageActionDashboard, StartingPageDashboard } from '@alii-web/modules/starting-page/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-manage-menu',
    templateUrl: './manage-menu.component.html',
    styleUrls: ['./manage-menu.component.scss']
})
export class ManageMenuComponent implements OnInit {
    @Input() dashboard: StartingPageDashboard;
    @Input() dashboards: StartingPageActionDashboard[] = [];

    constructor() {}

    ngOnInit() {}
}
