import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromProtocol from '../reducers/protocols.reducer';

export const getProtocolState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.protocols
);

export const getSelected = createSelector(getProtocolState, fromProtocol.getSelected);
export const getSelectedFlowchart = createSelector(getProtocolState, fromProtocol.getSelectedFlowchart);

export const getSelectedScheme = createSelector(getProtocolState, fromProtocol.getSelectedScheme);

export const getViews = createSelector(getProtocolState, fromProtocol.getViews);

export const getDefaultView = createSelector(getViews, views => {
    const def = views.find(view => {
        return 'start' in view && view['start'];
    });
    return def ? def['name'] : false;
});

export const getCurrentView = createSelector(getProtocolState, fromProtocol.getCurrentView);

export const getCurrentViewScroll = createSelector(getProtocolState, fromProtocol.getCurrentViewScroll);

export const getCurrentVersion = createSelector(getProtocolState, fromProtocol.getCurrentVersion);
export const getDraftVersion = createSelector(getProtocolState, fromProtocol.getDraftVersion);
export const getDeprecatedVersions = createSelector(getProtocolState, fromProtocol.getDeprecatedVersions);
export const getStoredDraftVersions = createSelector(getProtocolState, fromProtocol.getStoredDraftVersions);
export const getSelectedLoaded = createSelector(getProtocolState, fromProtocol.getSelectedLoaded);

export const getSelectedLoading = createSelector(getProtocolState, fromProtocol.getSelectedLoading);
export const getCreateProtocolLoading = createSelector(getProtocolState, fromProtocol.getCreateProtocolLoading);

export const getCreateLocalProtocolLoading = createSelector(
    getProtocolState,
    fromProtocol.getCreateLocalProtocolLoading
);

export const getSearchProtocolQuery = createSelector(getProtocolState, fromProtocol.getSearchProtocolQuery);

export const getSearchProtocolEntries = createSelector(getProtocolState, fromProtocol.getSearchProtocolEntries);

export const getSearchProtocolLoading = createSelector(getProtocolState, fromProtocol.getSearchProtocolLoading);

export const getDeleteProtocolResponse = createSelector(getProtocolState, fromProtocol.getDeleteProtocolResponse);
export const getDeleteProtocolLoading = createSelector(getProtocolState, fromProtocol.getDeleteProtocolLoading);
export const getDeleteProtocolLoaded = createSelector(getProtocolState, fromProtocol.getDeleteProtocolLoaded);

export const getLoadProtocolLanguage = createSelector(getProtocolState, fromProtocol.getLoadProtocolLanguage);
