import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

interface AddParagraphPayloadInterface {
    protocolId: string;
    parentId: string;
    options: {
        title?: string;
        modelId?: string;
        copyFromPpdId?: string;
        type?: string;
        min?: number;
        max?: number;
    };
}

interface UpdateParagraphPayloadInterface {
    protocolId: string;
    ppdId: string;
    options: {
        parentId?: string;
        title?: string;
        position?: number;
    };
}

@Injectable()
export class ParagraphsService {
    constructor(private http: HttpClient) {}

    getParWithAllOutcomes(payload: any): Observable<any> {
        const { ppdId } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/getPar.vm?ppdId=${ppdId}&showAllOutcomes=yes&app=web`;

        return this.http.get<any>(url).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    getParWithOutcomesByPopulationId(payload: any): Observable<any> {
        const { ppdId, populationId } = payload;
        const populationIdQuery = populationId ? `&populationId=${populationId}` : '';
        const url = `${environment.baseUrl}/api/protocol/par/getPar.vm?ppdId=${ppdId}${populationIdQuery}&app=web`;

        return this.http.get<any>(url).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    getListOfImports(payload: any): Observable<any> {
        const qType = payload.qType ? `type=${payload.qType}` : '';
        const protocolId = payload.qType ? `&protocolId=${payload.protocolId}` : '';
        const qLevel = payload.qLevel ? `&level=${payload.qLevel}` : '';
        const qQuery = payload.qQuery ? `&query=${payload.qQuery}` : '';
        const url = `${environment.baseUrl}/api/protocol/par/getListOfImports.vm?${qType}${qLevel}${qQuery}${protocolId}`;

        return this.http.get<any>(url).pipe(catchError((error: any) => throwError(error)));
    }

    getListOfOutcomes(payload: any): Observable<any> {
        const { ppdId } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/getListOfOutcomes.vm?ppdId=${ppdId}`;

        return this.http.get<any>(url).pipe(catchError((error: any) => throwError(error)));
    }

    getOutcome(payload: any): Observable<any> {
        const { ppdId, populationId } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/getOutcome.vm?ppdId=${ppdId}${populationId ? '&populationId=' + populationId : ''}`;

        return this.http.get<any>(url).pipe(catchError((error: any) => throwError(error)));
    }

    addParagraph(payload: AddParagraphPayloadInterface): Observable<any> {
        const { protocolId, options, parentId } = payload;
        const payloadPostData = {
            ...options,
            protocolId,
            ...(parentId ? { parentId } : {})
        };

        const url = `${environment.baseUrl}/api/protocol/par/createNewPar.vm`;
        return this.http.post<any>(url, payloadPostData).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateParagraphToModel(payload): Observable<any> {
        const { protocolId, ppdId } = payload;
        const payloadPostData = {
            protocolId,
            ppdId,
            action: 'setModel'
        };
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        return this.http.post<any>(url, payloadPostData).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateParagraph(payload: UpdateParagraphPayloadInterface): Observable<any> {
        const { protocolId, ppdId, options } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const data = {
            ...options,
            protocolId,
            ppdId
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    loadEvidence(payload) {
        const { protocolId, ppdId} = payload;
        const url = `${environment.baseUrl}/api/protocol/par/loadEvidence.vm?protocolId=${protocolId}&ppdId=${ppdId}`;
        return this.http.get<any>(url).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }

    deleteParagraph(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { protocolId, ppdId } = payload;
        const data = {
            protocolId,
            ppdId,
            action: 'Delete'
        };
        return this.http.post<any>(url, data).pipe(map(response => {return response['message list']}),catchError((error: any) => throwError(error)));
    }

    deleteFinding(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { protocolId, ppdId } = payload;
        const data = {
            protocolId,
            ppdId,
            action: 'Delete'
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    addValueOption(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/createNewValueOption.vm`;
        const { protocolId, ppdId, title, value, type } = payload;
        const data = {
            protocolId,
            ppdId,
            title,
            value,
            type
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { id: valueOptionId }
                } = response;

                return { valueOptionId };
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateValueOption(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updateValueOption.vm`;
        const { protocolId, ppdId, valueOptionId, title, value } = payload;
        const data = {
            protocolId,
            ppdId,
            valueOptionId,
            title,
            value
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    removeValueOption(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updateValueOption.vm`;
        const { protocolId, ppdId, valueOptionId } = payload;
        const data = {
            protocolId,
            ppdId,
            valueOptionId,
            action: 'Delete'
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    addSlider(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/createNewValueOption.vm`;
        const { protocolId, ppdId, type, min, max, step } = payload;
        const data = {
            protocolId,
            ppdId,
            type,
            min,
            max,
            step
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { id: valueOptionId }
                } = response;

                return { valueOptionId };
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateSlider(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updateValueOption.vm`;
        const { protocolId, ppdId, valueOptionId, min, max, step } = payload;
        const data = {
            protocolId,
            ppdId,
            valueOptionId,
            min,
            max,
            step
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    addReferenceNonPubmed(payload: any): Observable<any> {
        const apiUrl = `${environment.baseUrl}/api/protocol/source/addReference.vm?app=web`;
        const { protocolId, ppdId, url, anchorText, author, year, title } = payload;
        const postData = {
            protocolId,
            ppdId,
            url,
            anchorText,
            author,
            year,
            title
        };

        return this.http.post<any>(apiUrl, postData).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    saveConclusionTags(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updateConclusionTags?app=web`;
        const { conclusionId, tags } = payload;
        const postData = {
            conclusionId,
            tags
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                return response;
            }),

            catchError((error: any) => throwError(error))
        );
    }


    addReference(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/article/addReferenceToPar.vm?app=web`;
        const { protocolId, ppdId, articleIds, findingIds, parentId } = payload;
        const postData = {
            protocolId,
            ppdId,
            findingIds,
            parentId,
            articleIds
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                return response;
            }),

            catchError((error: any) => throwError(error))
        );
    }

    importReferences(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/article/addReferenceToPar.vm?app=web`;
        const { protocolId, ppdId, sheetId } = payload;
        const postData = {
            protocolId,
            ppdId,
            sheetId
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                return response;
            }),

            catchError((error: any) => throwError(error))
        );
    }


    removeReference(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/article/addReferenceToPar.vm?app=web`;
        const { ppdId, action, findingIds } = payload;
        const postData = {
            action: 'removeFindings',
            ppdId,
            findingIds
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }


    editReference(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/article/saveReview.vm?app=web`;
        const { value, field, reviewId, ppdId, parentId } = payload;
        let data = {id: reviewId}
        const postData = {
            action: 'updateReview',
            value,
            field,
            ppdId,
            parentId,
            data
        };
        return this.http.post<any>(url, postData).pipe(
            map(response => {
                return response;
            }),

            catchError((error: any) => throwError(error))
        );
    }

    addFinding(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/createNewPar.vm`;
        const { protocolId, parentId, title, type } = payload;
        const data = {
            protocolId,
            parentId,
            title,
            type
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0].questions;
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateFinding(payload: UpdateParagraphPayloadInterface): Observable<any> {
        const { protocolId, ppdId, options } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const data = {
            ...options,
            protocolId,
            ppdId
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    addOutcome(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/createNewPar.vm`;
        const { protocolId, parentId, title, type, min, max } = payload;
        const data = {
            protocolId,
            parentId,
            title,
            type,
            min,
            max
        };

        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0].outcomes;
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateOutcome(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { protocolId, ppdId, min, max } = payload;
        const data = {
            protocolId,
            ppdId,
            min,
            max
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateOutcomeTitle(payload): Observable<any> {
        const { protocolId, ppdId, title } = payload;
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const data = {
            title,
            protocolId,
            ppdId
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    importModelBySheetId(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/createNewPar.vm`;
        const { protocolId, parentId, sheetId } = payload;
        const data = {
            protocolId,
            parentId,
            sheetId
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                if (response.error) {
                    throw response.error;
                }
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateModelBySheetId(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { protocolId, ppdId, sheetId, action } = payload;
        const data = {
            protocolId,
            ppdId,
            sheetId,
            action
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                if (response.error) {
                    throw response.error;
                }
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    linkToProtocol(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { protocolId, ppdId, connectProtocolId } = payload;
        const data = {
            protocolId,
            ppdId,
            connectProtocolId
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                if (response.error) {
                    throw response.error;
                }
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }

    createCalculationFormula(payload: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/par/updatePar.vm?app=web`;
        const { ppdId } = payload;
        const data = {
            ppdId,
            calculationFormulaType: 'addition'
        };
        return this.http.post<any>(url, data).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }
}
