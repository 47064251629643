import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { RegisterDTO, RegisterInitRO, RegisterRO } from '../models/register.model';
import { ThemingService } from '../services/theming.service';

const KEY_AUTH_HASH = 'authHash';
const KEY_AN_ID = 'anId'
const KEY_USER_ID = 'userId';
const KEY_CUSTOM_STYLE = 'customStyle';

@Injectable()
export class AuthService {
    private authHash: string;
    authHash$: BehaviorSubject<string>;

    constructor(private http: HttpClient, 
        private router: Router,
        private themingService: ThemingService) {
        this.authHash = localStorage.getItem(KEY_AUTH_HASH);
        this.authHash$ = new BehaviorSubject(this.authHash);
    }

    getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    isCustomLogin() {
        const currentLocation = window.location;
        return this.getParameterByName('client', currentLocation);
    }

    isLoggedIn() {
        return !!(localStorage.getItem(KEY_AUTH_HASH));
    }


    loginv2(username: string, password: string): Observable<any> {
        const url = `${environment.baseUrl}/api/login.vm`;
        return this.http.post<any>(url, ({username, password})).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }


    login(username: string, password: string): Observable<any> {
        const url = `${environment.baseUrl}/api/login.vm?u=${username}&p=${password}`;
        return this.http.get<any>(url).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    public setAuthHash(authHash) {
        this.authHash = authHash;
        this.setTrackingId();
        
        if (!authHash) {
            localStorage.clear();
        } else {
            localStorage.setItem(KEY_AUTH_HASH, authHash);
        }
    }

    public setTrackingId() {
        let AnId = this.getRandomString(16);

        if (!localStorage.getItem(KEY_AN_ID)) {
            localStorage.setItem(KEY_AN_ID, AnId);
        }
    }

    getRandomString(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    public redirectAfterLogin(returnUrl) {
        this.router.navigateByUrl(returnUrl);
    }

    public getAuthHash() {
        return localStorage.getItem(KEY_AUTH_HASH);
    }

    public getAnId() {
        return localStorage.getItem(KEY_AN_ID);
    }

    public loginByAuthHash(payload) {
        const { authHash, returnUrl } = payload;
        this.setAuthHash(authHash);
        this.redirectAfterLogin(returnUrl);
    }

    public setUserId(userId) {
        localStorage.setItem(KEY_USER_ID, userId);
    }

    logout() {
        this.setAuthHash(null);
        /* todo: fix this in proper way, fixes this issue:
        https://trello.com/c/S4ukrf3F/565-prevent-api-calls-from-being-done-when-user-got-logged-out-in-another-tab
        this.router.navigate(['users', 'login']);
        */
        window.location.replace('/users/login/');
    }

    getSSO(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/auth/getLoginOptions.vm`).pipe(
            map(response => response.clients),
            catchError((error: any) => throwError(error))
        );
    }

    validateAuthHash(clientAllowed = false): Observable<boolean> {
        return this.http
            .get<any>(`${environment.baseUrl}/api/protocol/user/checkHash.vm?clientAllowed=${clientAllowed}`)
            .pipe(
                map(response => response.valid),
                catchError((error: any) => throwError(error))
            );
    }

    getProtocolSSOProvider(protocolId): Observable<any> {
        return this.http
            .get<any>(`${environment.baseUrl}/api/protocol/auth/getLoginOptions.vm?protocolId=${protocolId}`)
            .pipe(
                map(response => response.clients),
                catchError((error: any) => throwError(error))
            );
    }

    setTheme(theme: string) {
        localStorage.setItem(KEY_CUSTOM_STYLE, theme);
        const fileref = document.getElementById('theme');
        if (theme) {
            fileref.setAttribute("href", 'assets/css/' + theme + '.css');
            if (theme === 'rapp') {
                this.themingService.setTheme("rapp")
            }
        }      
    }

    async customLogin(username: string) {
        const url = `${environment.baseUrl}/api/login.vm?client=${username}`;
        const response = await this.http.get<any>(url).toPromise();
        if ('user' in response && response.user) {
            const newHash = response.user.authhash;
            const newStyle = response.css;
            this.setAuthHash(newHash);    
            this.setTheme(newStyle);
        } 
        else {
            this.logout();
        }
    }

    registerInit(payload: Params): Observable<RegisterInitRO> {
        // If there are query parameters then include in the url.
        let q = '';
        const { queryParams } = payload;
        Object.keys(queryParams).forEach((key, i) => (q += (i ? ',' : '') + `${key}=${queryParams[key]}`));

        const url = `${environment.baseUrl}/api/protocol/user/register.vm${
            q.length ? '?queryParams=' + encodeURIComponent(q) : ''
        }`;
        return this.http.get<RegisterInitRO>(url).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    register(payload: RegisterDTO): Observable<RegisterRO> {
        const url = `${environment.baseUrl}/api/protocol/user/register.vm`;
        return this.http.post<RegisterRO>(url, payload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }
}
