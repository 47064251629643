import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';
import * as fromService from '../../../../services';

import { Literatures } from '../../../../models/literature-collection.interface';
import { Pubmed } from '../../../../models/pubmed.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'aliiweeb-literatures',
    template: `
        <literatures
            [literatures]="literatures$ | async"
            [added]="added$ | async"
            [versions]="versions$ | async"
            [protocolDetails]="protocolDetails$ | async"
            [loading]="loading$ | async"
            [pubmeds]="pubmeds$ | async"
            [protocolId]="protocolId"
            (eventBus)="handleEventBus($event)"
        ></literatures>
    `
})
export class LiteraturesComponent implements OnInit {
    protocolId: string;
    
    protocol$: Observable<any> = this.store.select(fromStore.SelectProtocol);
    literatures$: Observable<Literatures> = this.store.select(fromStore.getLiteraturesCollection);
    added$: Observable<string[]> = this.store.select(fromStore.getAddedArticles);
    loading$: Observable<boolean> = this.store.select(fromStore.getLiteraturesCollectionLoading);
    pubmeds$: Observable<any> = this.store.select(fromStore.getPubmeds);
    versions$: Observable<any> = this.store.select(fromStore.getVersions);
    protocolDetails$: Observable<any> = this.store.select(fromStore.getProtocolDetails);

    constructor(
        private literaturesService: fromService.LiteraturesService,
        private store: Store<fromStore.LiteraturesFeatureState>,
        private route: ActivatedRoute
    ) {
        this.route.paramMap.subscribe(params => {
            this.protocolId = params.get('protocolId');
        });
    }
    ngOnInit() {
        this.store.dispatch(new fromStore.LoadLiteraturesCollectionContinue({ protocolId: this.protocolId }));
    }
    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleViewLiterature':
                this.handleViewLiterature(payload);
                break;

            case 'handleSubmitSearch':
                this.handleSubmitSearch(payload);
                break;

            case 'handleClearPubmedSearch':
                this.handleClearPubmedSearch();
                break;

            case 'handleAddArticles':
                this.handleAddArticles(payload);
                break;

            case 'handleRefreshLiterature':
                this.handleRefreshLiterature(payload);
                break;

            case 'handleAddNonPubmedArticle':
                this.handleAddNonPubmedArticle(payload);
                break;

            default:
                break;
        }
    }
    handleSubmitSearch(payload) {
        const { query } = payload;
        this.store.dispatch(new fromStore.LoadPubmeds({ query }));
    }
    handleClearPubmedSearch() {
        this.store.dispatch(new fromStore.ClearPubmeds());
    }

    handleRefreshLiterature(payload) {
        this.store.dispatch(new fromStore.LoadLiteraturesCollectionContinue({ protocolId: payload.protocolId }));
    }

    handleAddArticles(payload) {
        const { articleIds } = payload;
        const data = {
            protocolId: this.protocolId + '',
            articleIds
        };
        this.store.dispatch(new fromStore.AddArticles(data));
    }

    handleAddNonPubmedArticle(payload) {
        this.literaturesService.addNonPubmedArticle(payload).subscribe(() => {
            this.store.dispatch(new fromStore.LoadLiteraturesCollection({ protocolId: this.protocolId }));
            location.reload();
        });
    }

    handleViewLiterature(payload) {
        const { protocolId, literatureId } = payload;
        this.store.dispatch(new fromStore.SelectLiterature({ protocolId, literatureId }));
    }
}
