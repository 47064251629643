<div class="dashboard__items">
    <div class="dashboard__item" *ngFor="let protocol of protocols; trackBy: trackByFn">
        <article #card class="card">
            <header class="card__header">
                <a
                    class="card__header-link"
                    (click)="clickName(protocol, protocol.queryParams)"
                >
                    <span class="card__header-heading">{{ protocol.title }}</span>
                    <span class="card__header-subheading">
                </span>
                </a>
            </header>
            <footer class="card__footer">
                <a
                    class="tag mr-1"
                    [ngClass]="{'-primary': version.version == 'current', '-secondary': version.version == 'draft'}"
                    *ngFor="let version of protocol.versions"
                    (click)="clickVersion(protocol.id, version.version)"
                >
                    {{ version.version.toUpperCase() | translate }}
                </a>
            </footer>
        </article>
    </div>
</div>
