<div class="layout">
    <div class="layout__page">
        <main id="content" class="layout__content ">
            <div class="page">
                <div class="page__content">
                    <div
                        class="message"
                        style="z-index: 10; position: fixed; top: 0; width:100%; padding: 20px; background: #fff;"

                    >
                        <h1>Samenvatting / Afdrukken</h1>
                        <p>Gebruik onderstaande knoppen of ctrl-p om te printen.</p>

                        <button class="next-question-button" (click)="print()" style="border:1px solid #333">Print</button>&nbsp; 
                        <button class="next-question-button" (click)="closePrintPdf()"  style="border:1px solid #333">Terug</button>

                    </div>
                    <div class="mainContent">
                        <div style="width:100%">
                            <img src="assets/images/akwa-report-bg-new.png" style="width:30%; float: left">
                            <div style="margin-left: 8%; font-weight: bold; font-size:3em !important; line-height: 1em; float: left; color: black; width: 60%">Overzicht behandeldoelen en het evaluatieplan</div>
                        </div>
                        <br style="clear: both;">

                        <h2 style="border-top: 3px solid black; border-bottom: 3px solid black; padding: 5px; margin-top: 10px;">Doel</h2>

                        <b style="font-size: 1.5em">Waar heb je last van?</b><br>
                        <p>
                            <ng-container *ngFor="let problem of sortedProblems">
                                <span *ngIf="problem"><span class="icon-arrow-right"></span> &nbsp;  <span [innerText]="problem" style="line-height:2em"></span><br></span>
                            </ng-container>
                        </p>

                        <br>
                        <b style="font-size: 1.5em">Wat wil je bereiken?</b><br>
                        <table style="width:100%;">
                            <ng-container *ngFor="let last of sortedTargets">

                                <tr *ngIf="!!last.goal">
                                    <td style="width:35%; vertical-align: top; padding-top: 12px;">
                                        <span class="icon-arrow-right"></span>&nbsp; 
                                        {{ last.goal }}
                                    </td>

                                    <td style="width:30%; vertical-align: top;">
                                        <div class="range-container" style="width:100%">
                                        <span style="font-size:.875em; font-weight: bold;">Hoe gaat het nu?</span>
                                            <input
                                                type="range"
                                                min="1"
                                                max="20"
                                                class="range-slider-akwa"
                                                [value]="last.start"
                                                disabled="disabled"
                                            />
                                            <br class="clearfix" />

                                            <div class="left-range-label" style="margin-left:0 !important">Helemaal niet goed</div>
                                            <div class="right-range-label" style="margin-right:0 !important">Helemaal goed</div>
                                        </div>
                                    </td>
                                    <td style="padding-left:2%; width:32%; vertical-align: top;">
                                        <div class="range-container"  style="width:100%">
                                            <span style="font-size:.875em; font-weight: bold;">Wanneer ben je tevreden? Het gaat dan:</span>
                                            <input
                                            type="range"
                                            min="1"
                                            max="20"
                                            class="range-slider-akwa range-slider-akwa-right"
                                            [value]="last.end"
                                            disabled="disabled"

                                            />
                                            <br class="clearfix" />

                                            <div class="left-range-label" style="margin-left:0 !important">Helemaal niet goed</div>
                                            <div class="right-range-label" style="margin-right:0 !important">Helemaal goed</div>

                                            <div class="arrow" 
                                                [style.left]="thumbLeftPos(last)"
                                                [style.width]="thumbWidth(last)"></div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>

                        </table>

                        <br><br>

                        <b style="font-size: 1.5em">Wat ga je doen?</b><br>
                        <p>
                            <ng-container *ngFor="let problem of sortedActions">
                                <span *ngIf="problem"><span class="icon-arrow-right"></span> &nbsp;  <span [innerText]="problem" style="line-height:2em"></span><br></span>
                            </ng-container>
                        </p>

                        <h2 style="border-top: 3px solid black; border-bottom: 3px solid black; padding: 5px; margin-top: 10px; margin-bottom: 10px;">Afspraken over het evalueren van het doel</h2>


                        <b style="font-size: 1.5em" *ngIf="evaluatieFrequentie">Hoe vaak wil je evalueren?</b><br>
                        <span *ngIf="evaluatieFrequentie"><span class="icon-arrow-right"></span> &nbsp;  <span [innerText]="'Elke ' + evaluatieFrequentie" style="line-height:2em"></span><br></span>
                        <br>

                        <b *ngIf="betrokkenNaasten" style="font-size: 1.5em">Wil je naasten betrekken?</b><br>
                        <span *ngIf="betrokkenNaasten === 'Ja'"><span class="icon-arrow-right"></span> &nbsp;  <span [innerText]="'Ja, leg vast wie dit is/zijn'" style="line-height:2em"></span><br></span>
                        <span *ngIf="betrokkenNaasten === 'Nee'"><span class="icon-arrow-right"></span> &nbsp;  <span [innerText]="'Nee, denk hier bij de volgende evaluatie opnieuw over na'" style="line-height:2em"></span><br></span>


                        <br>
                        <b style="font-size: 1.5em">Welke vragenlijsten ondersteunen de evaluatie?</b><br>
                        
                        <table style="width:100%; vertical-align: top; margin-top:20px">
                            <tr style="border-bottom: 2px solid black">
                                <td style="width:30%; font-weight: bold">Vragenlijst</td>
                                <td style="width:40%; font-weight: bold;">Onderwerp</td>
                                <td style="width:30%; font-weight: bold">Hoe vaak?</td>
                            </tr>
                            <ng-container *ngFor="let model of objectToArray(modelOutcomes)">
                                <!--<pre>{{ model | json }}</pre>-->
                                <ng-container  *ngFor="let parentOutcome of model">
                                    <ng-container  *ngFor="let outcome of parentOutcome.outcomeList">
                                        <!--
                                                                                    <tr *ngIf="outcome.selected ||
                                            (outcome.selected && parentOutcome.title !== 'Vaste vragenlijsten die worden gebruikt in de organisatie') 
                                            ||
                                            (outcome.selected && parentOutcome.title === 'Vaste vragenlijsten die worden gebruikt in de organisatie' && !akwaIsDuplicateList(akwaGoalTitle(outcome.title), outcome.id))">
                                        -->
                                        <tr *ngIf="outcome.selected">
                                            <td style="padding: 5px; padding-left:0">{{ akwaGoalTitle(outcome.title) }}</td>
                                            <td style="padding: 5px; padding-left:0" [innerHTML]="
                                            parentOutcome.title === 'Vaste vragenlijsten die worden gebruikt in de organisatie' ? 
                                                akwaGetGoalTextVaste(akwaGoalTitle(outcome.title)) : akwaThemesText(outcome.text)
                                            "></td>
                                            <td style="padding: 5px; padding-left:0">Elke {{ outcome.summaryText }} evaluatie(s)</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="akwaHasNoOutcomes">
                                <tr *ngFor="let theme of akwaGetAllSelectedThemes">
                                    <td>Geen vragenlijsten geselecteerd</td>
                                    <td>{{ theme }} </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </ng-container>
                       </table>
                       <br>
                       Meer informatie over de vragenlijsten is terug te vinden via <a href="https://ggzdataportaal.nl/bibliotheek/" style="color: blue">https://ggzdataportaal.nl/bibliotheek/</a><br>
                       Meer informatie over evalueren is terug te vinden via <a href="https://akwaggz.nl/evalueren" style="color: blue">https://akwaggz.nl/evalueren</a>
                       <br>
                       <br>
                       <img src="assets/images/akwa-report-footer.png" style="width:100%; max-width: 1500px; break-inside: avoid;  break-before: auto; break-after: auto;">
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>