import produce from 'immer';

import * as fromLiteraturesCollection from '../actions/literatures-collection.action';

import { Literature } from '../../../../models/literature.interface';

export interface LiteratureCollectionState {
    protocolId: string;
    tabs: any[];
    versions: any[];
    protocolDetails: {
        title: string;
        author: string;
    };
    entries: any;
    ids: any[];
    selectedId: string;
    selected: Literature;
    added: string[];
    steps: any[];
    data: any;
    paragraphs: any[];
    populations: any[];
    loaded: boolean;
    loading: boolean;
    uploadEvent: {
        isUploading: boolean;
    };
}

export const initialState: LiteratureCollectionState = {
    protocolId: null,
    tabs: [],
    versions: [],
    entries: {},
    ids: [],
    selectedId: null,
    protocolDetails: null,
    selected: null,
    added: [],
    steps: [],
    data: {},
    paragraphs: [],
    populations: [],
    loaded: false,
    loading: false,
    uploadEvent: { isUploading: false }
};

export function reducer(
    state = initialState,
    action: fromLiteraturesCollection.LiteraturesCollectionAction
): LiteratureCollectionState {
    switch (action.type) {
        case fromLiteraturesCollection.LOAD_LITERATURES: {
            return state;
        }

        case fromLiteraturesCollection.LOAD_LITERATURES_CONTINUE: {
            const { protocolId } = action.payload;
            return { ...state, loading: true, protocolId };
        }

        case fromLiteraturesCollection.LOAD_LITERATURES_SUCCESS: {
            const { steps, data, literatures, paragraphs, populations, versions, protocolDetails } = action.payload;
            const ids = literatures.map(literature => literature.id);
            const entries = literatures.reduce((acc, entry) => {
                if ((entry.id in state.entries)) {
                    entry = state.entries[entry.id]
                }
                
                return { ...acc, [entry.id]: entry };
                
            }, {});


            return {
                ...state,
                selected: state.selectedId ? entries[state.selectedId] : null,
                entries,
                ids,
                steps,
                loading: false,
                loaded: true,
                data,
                versions,
                paragraphs,
                populations,
                protocolDetails
            };
        }

        case fromLiteraturesCollection.LOAD_LITERATURES_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
        case fromLiteraturesCollection.GET_LITERATURE: {
            return state;
        }
        case fromLiteraturesCollection.GET_LITERATURE_SUCCESS: {
            
            const { literature, literatureId } = action.payload;
            const sourceCat = state.entries[literatureId] ? state.entries[literatureId].category : '';
            const targetCat = literature.category;
            const newData = state.steps.reduce((acc, step) => {
                const data = state.data[step.id];
                const newEntries = (id, entries) => {
                    switch (id) {
                        case sourceCat: {
                            if (sourceCat === targetCat) {
                                return entries; 
                            } else {
                                return entries.filter(entry => entry.id !== literatureId);
                            }
                        }
                        case targetCat: {
                            return [...entries, state.entries[literatureId]];
                        }
                        default:
                            return entries;
                    }
                };
                return { ...acc, [step.id]: { ...data, entries: newEntries(step.id, data.entries) } };
            }, {});

           // const ids = state.steps.reduce((acc, s) => {
           //     return [...acc, ...newData[s.id].entries.map(e => e.id)];
           // }, []);

            return {
                ...state,
                ...(state.selectedId === literatureId && { selected: literature }),
                //ids,
                data: newData,
                entries: { ...state.entries, [literatureId]: literature }
            };
        }

        case fromLiteraturesCollection.SELECT_LITERATURE: {
            const { literatureId } = action.payload;

            return {
                ...state,
                selectedId: literatureId,
                ...(state.entries && { selected: state.entries[literatureId] })
            };
        }

        case fromLiteraturesCollection.SUBMIT_LITERATURE_ACTION_SUCCESS:
        case fromLiteraturesCollection.SUBMIT_LITERATURE_ACTION:
        case fromLiteraturesCollection.SUBMIT_LITERATURE_ACTION_FAIL: {
            return state;
        }
        case fromLiteraturesCollection.ADD_ARTICLES: {
            return { ...state, loading: true };
        }

        case fromLiteraturesCollection.ADD_ARTICLES_SUCCESS: {
            return { ...state, loading: false, loaded: false };
        }

        case fromLiteraturesCollection.UPDATE_ADDED_ARTICLES: {
            const { articleIds } = action.payload;
            return { ...state, added: [...state.added, ...articleIds] };
        }

        case fromLiteraturesCollection.ADD_ARTICLES_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
        case fromLiteraturesCollection.LOAD_PARAGRAPHS: {
            const paragraphs = action.payload;
            const options = paragraphs.map(p => {
                return { value: p['protocol_paragraph.id'], title: p['protocol_paragraph.title'], conclusions: p.conclusions };
            });
            return { ...state, paragraphs: options };
        }

        case fromLiteraturesCollection.LOAD_POPULATIONS: {
            const populations = action.payload;
            const options = populations.map(p => {
                return { value: p.populationId, title: p.name };
            });
            return { ...state, populations: options };
        }
        case fromLiteraturesCollection.UPLOAD_FILE_TO_BUCKET: {
            return { ...state, uploadEvent: { isUploading: true } };
        }
        case fromLiteraturesCollection.UPLOAD_FILE_TO_BUCKET_UPLOADING: {
            return { ...state, uploadEvent: { isUploading: true } };
        }
        case fromLiteraturesCollection.UPLOAD_FILE_TO_BUCKET_SUCCESS: {
            return { ...state, uploadEvent: { isUploading: true } };
        }
        case fromLiteraturesCollection.UPLOAD_FILE_TO_BUCKET_FAIL: {
            return { ...state, uploadEvent: { isUploading: false } };
        }
        case fromLiteraturesCollection.ADD_FILE_TO_ARTICLE_SUCCESS: {
            const { title, url } = action.payload;
            const newState = produce(state, draft => {
                const file = { title, url };
                draft.selected.files.push(file);
                draft.uploadEvent.isUploading = false;
                draft.entries[state.selectedId].files.push(file);
            });
            return { ...state, ...newState };
        }
        case fromLiteraturesCollection.ADD_FILE_TO_ARTICLE_FAIL: {
            return { ...state, uploadEvent: { isUploading: false } };
        }
    }
    return state;
}

export const getSelectedLiterature = (state: LiteratureCollectionState) => state.selected;
export const getIds = (state: LiteratureCollectionState) => state.ids;
export const getVersions = (state: LiteratureCollectionState) => state.versions;
export const getProtocolDetails = (state: LiteratureCollectionState) => state.protocolDetails;

export const selectProtocolId = (state: LiteratureCollectionState) => state.protocolId;
export const getLiteraturesCollection = (state: LiteratureCollectionState) => state.data;
export const getLiteraturesCollectionLoading = (state: LiteratureCollectionState) => state.loading;
export const getLiteraturesCollectionLoaded = (state: LiteratureCollectionState) => state.loaded;

export const getAddedArticles = (state: LiteratureCollectionState) => state.added;

export const getParagraphs = (state: LiteratureCollectionState) => state.paragraphs;
export const getPopulations = (state: LiteratureCollectionState) => state.populations;
export const getSteps = (state: LiteratureCollectionState) => state.steps;

export const getUploadEvent = (state: LiteratureCollectionState) => state.uploadEvent;
