import { Component, ChangeDetectionStrategy, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AuthService } from './services';

const cn = 'AppComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(DOCUMENT) private document,
        private titleService: Title,
        private router: Router,
        public authService: AuthService
    ) {}

    title = 'Alii';

// The following regex indicates which low-level API calls will be bracketed by a blocked ui spinner.
    blockUiFilterBy = /createNewProtocol|updateProtocol|addReferenceToPar|saveReview/;

    subscriptions: Subscription[] = [];

    ngOnInit() {
        // this.subscriptions.push(
        //     this.authService.authHash$.subscribe(authHash => console.log(`${cn} authHash$='${authHash}'`))
        // );
        this._forcedLogout();
        this._setupCustomeStyles();
        this._setupNavigationEnd();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private _forcedLogout() {
        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd && event.url === '/logout'))
                .subscribe(() => this.authService.logout())
        );
    }
    private _setupCustomeStyles() {
        const style = localStorage.getItem('customStyle');
        console.log({style});
        if (style) {
            this.document.getElementById('theme').setAttribute('href', 'assets/css/' + style + '.css');
        }            
    }

    private _setupNavigationEnd() {
        // Convert the following strings if present.
        const conv = {
            Dashboard: 'Dashboards',
            Community: 'Team',
            Calendar: 'Agenda',
            Shop: 'Library'
        };

        // Replace or strip out certain strings if present.
        const repl = [
            { from: /Users - Login/, to: 'Login' },
            { from: /Dashboards - \d+/, to: 'Edit Dashboard' },
            { from: /Dashboards - Create/, to: 'Create Dashboard' },
            { from: /Dashboards$/, to: 'Manage Dashboards' }
        ];

        // Set Title
        // By scanning through the route path, will have the following format:
        //     Alii - Segment - Sub-segment - ... - End-segment
        // Any segments appearing as a key in the names list will be replaced by the value. A component
        // for a given end-segment can make its own call to setTitle to add extra specific info.
        // Note that for protocol page the title is set there instead.
        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd && !event.url.match(/\/protocols\/\d+/)))
                .subscribe((event: NavigationEnd) => {
                    const separator = ' - ';
                    const segments = event.url
                        // Strip out url fragment if present.
                        .replace(/#.*$/, '')
                        .split('/')
                        .map(c => (c.length ? c.charAt(0).toUpperCase() + c.slice(1) : this.title))
                        .map(c => conv[c] || c);
                    let title = segments.join(separator);
                    repl.forEach(t => {
                        if (title.match(t.from)) {
                            title = title.replace(t.from, t.to);
                        }
                    });
                    this.titleService.setTitle(title);
                })
        );
    }
}
