import { Action } from '@ngrx/store';
import { KeyQuestionImport } from 'apps/web/src/app/models/keyquestion-import.interface';
import { Paragraph } from '../../../../models/paragraph.interface';

export const ADD_PARAGRAPH = '[Protocols] Add Paragraph';
export const ADD_PARAGRAPH_FAIL = '[Protocols] Add Paragraph Fail';
export const ADD_PARAGRAPH_SUCCESS = '[Protocols] Add Paragraph Success';
export const ADD_CHAPTER_SUCCESS = '[Protocols] Add Chapter Success';

export class AddParagraph implements Action {
    readonly type = ADD_PARAGRAPH;

    constructor(
        public payload: {
            protocolId: string;
            parentId?: string;
            options: {
                isModel?: boolean;
                title?: string;
                modelId?: string;
                copyFromPpdId?: string;
                type?: string;
                min?: number;
                max?: number;
            };
        }
    ) {}
}

export class AddParagraphFail implements Action {
    readonly type = ADD_PARAGRAPH_FAIL;

    constructor(public payload: any) {}
}

export class AddParagraphSucces implements Action {
    readonly type = ADD_PARAGRAPH_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export class AddChapterSuccess implements Action {
    readonly type = ADD_CHAPTER_SUCCESS;

    constructor(public payload: { parentId: null; paragraph: Paragraph }) {}
}

export const UPDATE_PARAGRAPH = '[Protocols] Update Paragraph';
export const UPDATE_PARAGRAPH_FAIL = '[Protocols] Update Paragraph Fail';
export const UPDATE_PARAGRAPH_SUCCESS = '[Protocols] Update Paragraph Success';

export class UpdateParagraph implements Action {
    readonly type = UPDATE_PARAGRAPH;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            options: {
                position?: number;
                action?: string;
                title?: string;
                field?: string;
                value?: string;
                keyQuestionId?: string;
            };
        }
    ) {}
}

export class UpdateParagraphFail implements Action {
    readonly type = UPDATE_PARAGRAPH_FAIL;

    constructor(public payload: any) {}
}

export class UpdateParagraphSucces implements Action {
    readonly type = UPDATE_PARAGRAPH_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const DONE_EDIT_SORT = '[Protocols] Done edit sort order';

export class DoneEditSort implements Action {
    readonly type = DONE_EDIT_SORT;

    constructor(public payload: { protocolId: string }) {}
}

export const UPDATE_PAR_POSITION = '[Protocols] Update Paragraph Position';
export const UPDATE_PAR_POSITION_FAIL = '[Protocols] Update Paragraph Position Fail';
export const UPDATE_PAR_POSITION_SUCCESS = '[Protocols] Update Paragraph Position Success';

export class UpdateParPosition implements Action {
    readonly type = UPDATE_PAR_POSITION;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            position?: number;
        }
    ) {}
}

export class UpdateParPositionFail implements Action {
    readonly type = UPDATE_PAR_POSITION_FAIL;

    constructor(public payload: any) {}
}

export class UpdateParPositionSucces implements Action {
    readonly type = UPDATE_PAR_POSITION_SUCCESS;

    constructor(public payload: any) {}
}

export const UPDATE_PARAGRAPH_TO_MODEL = '[Protocols] Update Paragraph To Model';
export const UPDATE_PARAGRAPH_TO_MODEL_FAIL = '[Protocols] Update Paragraph To Model Fail';
export const UPDATE_PARAGRAPH_TO_MODEL_SUCCESS = '[Protocols] Update Paragraph To Model Success';

export class UpdateParagraphToModel implements Action {
    readonly type = UPDATE_PARAGRAPH_TO_MODEL;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
        }
    ) {}
}

export class UpdateParagraphToModelFail implements Action {
    readonly type = UPDATE_PARAGRAPH_TO_MODEL_FAIL;

    constructor(public payload: any) {}
}

export class UpdateParagraphToModelSucces implements Action {
    readonly type = UPDATE_PARAGRAPH_TO_MODEL_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const DELETE_PARAGRAPH = '[Protocols] Delete Paragraph';
export const DELETE_PARAGRAPH_FAIL = '[Protocols] Delete Paragraph Fail';
export const DELETE_PARAGRAPH_SUCCESS = '[Protocols] Delete Paragraph Success';

export class DeleteParagraph implements Action {
    readonly type = DELETE_PARAGRAPH;

    constructor(
        public payload: {
            protocolId?: string;
            ppdId: string;
            parentId: string;
            grandParentId?: string;
        }
    ) {}
}

export class DeleteParagraphFail implements Action {
    readonly type = DELETE_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}

export class DeleteParagraphSucces implements Action {
    readonly type = DELETE_PARAGRAPH_SUCCESS;

    constructor(
        public payload: {
            ppdId?: string;
            parentId?: string;
            paragraph?: Paragraph;
        }
    ) {}
}


export const LOAD_KEYQUESTION_IMPORT = '[Protocols] Load KeyQuestion Import';
export const LOAD_KEYQUESTION_IMPORT_FAIL = '[Protocols] Load KeyQuestion Import Fail';
export const LOAD_KEYQUESTION_IMPORT_SUCCESS = '[Protocols] Load KeyQuestion Import Success';

export class LoadKeyQuestionImport implements Action {
    readonly type = LOAD_KEYQUESTION_IMPORT;
    constructor(public payload: any) {}
}

export class LoadKeyQuestionImportFail implements Action {
    readonly type = LOAD_KEYQUESTION_IMPORT_FAIL;

    constructor(public payload: any) {}
}

export class LoadKeyQuestionImportSuccess implements Action {
    readonly type = LOAD_KEYQUESTION_IMPORT_SUCCESS;

    constructor(public payload: KeyQuestionImport[]) {}
}

export const IMPORT_MODEL_BY_SHEET_ID = '[Protocols] Import Model by SheetId';
export const IMPORT_MODEL_BY_SHEET_ID_FAIL = '[Protocols] Import Model by SheetId Fail';
export const IMPORT_MODEL_BY_SHEET_ID_SUCCESS = '[Protocols] Import Model by SheetId Success';

export class ImportModelBySheetId implements Action {
    readonly type = IMPORT_MODEL_BY_SHEET_ID;

    constructor(public payload: { protocolId: string; parentId: string; sheetId: string }) {}
}

export class ImportModelBySheetIdFail implements Action {
    readonly type = IMPORT_MODEL_BY_SHEET_ID_FAIL;

    constructor(public payload: any) {}
}

export class ImportModelBySheetIdSucces implements Action {
    readonly type = IMPORT_MODEL_BY_SHEET_ID_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const UPDATE_MODEL_BY_SHEET_ID = '[Protocols] Update Model by SheetId';
export const UPDATE_MODEL_BY_SHEET_ID_FAIL = '[Protocols] Update Model by SheetId Fail';
export const UPDATE_MODEL_BY_SHEET_ID_SUCCESS = '[Protocols] Update Model by SheetId Success';

export class UpdateModelBySheetId implements Action {
    readonly type = UPDATE_MODEL_BY_SHEET_ID;

    constructor(
        public payload: { protocolId: string; parentId: string; ppdId: string; sheetId: string; action: string }
    ) {}
}

export class UpdateModelBySheetIdFail implements Action {
    readonly type = UPDATE_MODEL_BY_SHEET_ID_FAIL;

    constructor(public payload: any) {}
}

export class UpdateModelBySheetIdSucces implements Action {
    readonly type = UPDATE_MODEL_BY_SHEET_ID_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const LINK_TO_PROTOCOL = '[Protocols] Link to Protocol';
export const LINK_TO_PROTOCOL_FAIL = '[Protocols] Link to Protocol Fail';
export const LINK_TO_PROTOCOL_SUCCESS = '[Protocols] Link to Protocol Success';

export class LinkToProtocol implements Action {
    readonly type = LINK_TO_PROTOCOL;

    constructor(public payload: { protocolId: string; ppdId: string; connectProtocolId: string }) {}
}

export class LinkToProtocolFail implements Action {
    readonly type = LINK_TO_PROTOCOL_FAIL;

    constructor(public payload: any) {}
}

export class LinkToProtocolSucces implements Action {
    readonly type = LINK_TO_PROTOCOL_SUCCESS;

    constructor(public payload: { paragraph: Paragraph }) {}
}

export const CREATE_CALCULATION_FORMULA = '[Protocols] Create Calculation Formula';
export const CREATE_CALCULATION_FORMULA_FAIL = '[Protocols] Create Calculation Formula Fail';
export const CREATE_CALCULATION_FORMULA_SUCCESS = '[Protocols] Create Calculation Formula Success';

export class CreateCalculationFormula implements Action {
    readonly type = CREATE_CALCULATION_FORMULA;

    constructor(public payload: { parentId: string; ppdId: string }) {}
}

export class CreateCalculationFormulaFail implements Action {
    readonly type = CREATE_CALCULATION_FORMULA_FAIL;

    constructor(public payload: any) {}
}

export class CreateCalculationFormulaSuccess implements Action {
    readonly type = CREATE_CALCULATION_FORMULA_SUCCESS;

    constructor(public payload: any) {}
}

export const IMPORT_ELEMENT_TO_SUMMARY = '[Protocols] Import Element To Summary';
export const IMPORT_ELEMENT_TO_SUMMARY_FAIL = '[Protocols] Import Element To Summary Fail';
export const IMPORT_ELEMENT_TO_SUMMARY_SUCCESS = '[Protocols] Import Element To Summary Success';

export class ImportElementToSummary implements Action {
    readonly type = IMPORT_ELEMENT_TO_SUMMARY;

    constructor(public payload: { protocolId: string; parentId: string; copyFromPpdId: string; type: string }) {}
}

export class ImportElementToSummaryFail implements Action {
    readonly type = IMPORT_ELEMENT_TO_SUMMARY_FAIL;

    constructor(public payload: any) {}
}

export class ImportElementToSummarySucces implements Action {
    readonly type = IMPORT_ELEMENT_TO_SUMMARY_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const ADD_COMMENT = '[Protocols] Add Comment';
export const ADD_COMMENT_FAIL = '[Protocols] Add Comment Fail';
export const ADD_COMMENT_SUCCESS = '[Protocols] Add Comment Success';

export class AddComment implements Action {
    readonly type = ADD_COMMENT;

    constructor(public payload: { protocolId?: string; parentId: string; ppdId: string, text?: string, comment?: string, status?: string }) {}
}

export class AddCommentFail implements Action {
    readonly type = ADD_COMMENT_FAIL;

    constructor(public payload: any) {}
}

export class AddCommentSuccess implements Action {
    readonly type = ADD_COMMENT_SUCCESS;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const LOAD_EVIDENCE = '[Protocols] Load evidence';
export const LOAD_EVIDENCE_FAIL = '[Protocols] Load evidence Fail';
export const LOAD_EVIDENCE_SUCCESS = '[Protocols] Load evidence Success';

export class LoadEvidence implements Action {
    readonly type = LOAD_EVIDENCE;

    constructor(public payload: { protocolId?: string; ppdId: string, parentId?: string}) {}
}

export class LoadEvidenceFail implements Action {
    readonly type = LOAD_EVIDENCE_FAIL;

    constructor(public payload: any) {}
}

export class LoadEvidenceSuccess implements Action {
    readonly type = LOAD_EVIDENCE_SUCCESS;

    constructor(public payload: { 
        protocolId: string;
        parentId: string;
    }) {}
}


export type ParagraphsAction =
    | AddParagraph
    | AddParagraphFail
    | AddParagraphSucces
    | AddComment
    | AddCommentFail
    | AddCommentSuccess
    | CreateCalculationFormula
    | CreateCalculationFormulaFail
    | CreateCalculationFormulaSuccess
    | DeleteParagraph
    | DeleteParagraphFail
    | DeleteParagraphSucces
    | DoneEditSort
    | ImportElementToSummary
    | ImportElementToSummaryFail
    | ImportElementToSummarySucces
    | ImportModelBySheetId
    | ImportModelBySheetIdFail
    | ImportModelBySheetIdSucces
    | LinkToProtocol
    | LinkToProtocolFail
    | LinkToProtocolSucces
    | LoadKeyQuestionImport
    | LoadKeyQuestionImportFail
    | LoadKeyQuestionImportSuccess
    | UpdateModelBySheetId
    | UpdateModelBySheetIdFail
    | UpdateModelBySheetIdSucces
    | UpdateParPosition
    | UpdateParPositionFail
    | UpdateParPositionSucces
    | UpdateParagraph
    | UpdateParagraphFail
    | UpdateParagraphSucces
    | UpdateParagraphToModel
    | UpdateParagraphToModelFail
    | UpdateParagraphToModelSucces
    | LoadEvidence
    | LoadEvidenceFail
    | LoadEvidenceSuccess;

