<div>
    <ng-container *ngIf="!isRoot">

        <div class="pull-right" *ngIf="!paragraph.generated" >

            <alii-web-paragraph-status-label
                (eventBus)="handleEventBus($event)"
                [paragraph]="paragraph"
            >
            </alii-web-paragraph-status-label>
            <alii-web-paragraph-edit-options
                *ngIf="isEditAble"
                (eventBus)="handleEventBus($event)"
                [channelMessages]="channelMessages"
                [ppdId]="paragraph.id"
                [parentId]="parentId"
                [keyQuestionId]="paragraph.keyQuestionId"
                [updateKeyQuestion]="paragraph.updateKeyQuestion"
                [protocolId]="protocolId"
                [parentSourceTable]="parentSourceTable"
                [isModel]="paragraph.is_model"
                [isSummary]="paragraph.is_summary"
                [level]="paragraph.level"
                [sources]="paragraph.sources"
                [sourceInfo]="paragraph.sourceInfo"
                [keyQuestionImportList]="keyQuestionImportList"
                [gradeAssessmentPar]="getGradeAssessmentPar(paragraph.id)"
                [pubmeds]="pubmeds"
                [viewByPopulation]="viewByPopulation"
                [type]="paragraph.type"
                [conclusionTags]="paragraph.sourceOfText"
            >
            </alii-web-paragraph-edit-options>
        </div>
        <div class="clearfix"></div>

        <div *ngIf="paragraph.generated" id="heading-{{ paragraph.id }}" class="heading-paragraph pull-left -no-margin" style="width: 100%;">
            <ng-container>
                <h3 class="m-2" *ngIf="+paragraph.level === 3">{{paragraph['protocol_paragraph.title']}} </h3>
                <h6 *ngIf="+paragraph.level === 6">{{paragraph['protocol_paragraph.title']}} </h6>
            </ng-container>
        </div>

        <ng-container *ngIf="!paragraph.generated" >
            <div id="paragraph-{{ paragraph.anchorId }}" class="heading-paragraph pull-left -no-margin" style="width: 100%;">
                <ng-container>
                    <alii-web-title-edit
                        (eventBus)="handleEventBus($event)"
                        [protocolId]="protocolId"
                        [id]="paragraph.id"
                        [index]=createIndex()
                        [parentId]="parentId"
                        [title]="paragraph['protocol_paragraph.title']"
                        [level]="''+paragraph.level"
                    >
                    </alii-web-title-edit>
                    <span id="heading-{{ paragraph.id }}">&nbsp;
                    </span>
                </ng-container>
                <div class="float-right" style="padding: 0" *ngIf="!!paragraph.quality">
                    <div class="m-2">
                        <span class="tag" *ngIf="paragraph.quality == 1">{{'PARAGRAPH.EVIDENCE.VERYLOWQUALITY'|translate}}</span>
                        <span class="tag" *ngIf="paragraph.quality == 2">{{'PARAGRAPH.EVIDENCE.LOWQUALITY'|translate}}</span>
                        <span class="tag" *ngIf="paragraph.quality == 3">{{'PARAGRAPH.EVIDENCE.MODERATEQUALITY'|translate}}</span>
                        <span class="tag" *ngIf="paragraph.quality == 4">{{'PARAGRAPH.EVIDENCE.HIGHQUALITY'|translate}}</span>
                        <span class="tag" *ngIf="!isScore(paragraph.quality)">{{paragraph.quality}}</span>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="modelSheetUpdateLoader"></ng-container>
            </div>
            
            
            <div class="clearfix"></div>
            
            <ng-container >
                <alii-web-text-edit
                    [text]="paragraph['protocol_paragraph.text']"
                    [parId]="paragraph.id"
                    [isEditAble]="isEditAble"
                    [title]="paragraph['protocol_paragraph.title']"
                    (eventBus)="handleEventBus($event)"
                    [parentId]="parentId"
                    [sources]="paragraph.sources"
                    [sourceInfo]="paragraph.sourceInfo"
                    (updated)="textUpdated()"
                    [linkedProtocol]="paragraph['linked_protocol']"
                >
                </alii-web-text-edit>
            </ng-container>


            <p *ngIf="paragraph.is_model" class="wysiwyg small">
                Dit is een extern model dat niet aangepast kan worden - wilt u een eigen versie, neem contact op met <a
                href="mailto:info@alii.care">info@alii.care</a>.
            </p>
            <alii-web-model-detail
                *ngIf="paragraph.is_model"
                (eventBus)="handleEventBus($event)"
                [findings]="modelFindings[paragraph.id]"
                [outcomes]="modelOutcomes[paragraph.id]"
                [tagList]="modelTagList[paragraph.id]"
                [modelSource]="paragraph.model_source"
                [viewByPopulation]="viewByPopulation"
                [populations]="populations[paragraph.id]"
                [paragraphId]="paragraph.id"
                [populationId]="populationId"
                [parentId]="parentId"
                [paragraph]="paragraph"
                [showIdentifiers]=true
            >
            </alii-web-model-detail>
            <alii-web-model-edit
                *ngIf="paragraph.is_summary"
                (eventBus)="handleEventBus($event)"
                [channelMessages]="channelMessages"
                [protocolId]="protocolId"
                [parentId]="parentId"
                [paragraph]="paragraph"
                [findings]="modelFindings[paragraph.id]"
                [outcomes]="modelOutcomes[paragraph.id]"
                [tagList]="modelTagList[paragraph.id]"
                [modelSource]="paragraph.model_source"
                [modelMessages]="modelMessages"
                [isEditAble]="isEditAble"
                [viewByPopulation]="viewByPopulation"
                [populations]="populations[paragraph.id]"
                [gradeAssessment]="gradeAssessment"
            >
            </alii-web-model-edit>

            <alii-web-paragraph-recommendation-table
                *ngIf="paragraph.type === 'recommendation' 
                        && paragraph.recommendationList 
                        && paragraph.recommendationList.rowData"
                [columnDefs]="paragraph.recommendationList.columnDefs"
                [rowData]="paragraph.recommendationList.rowData"
                [index]="index"
                [hasNumberedIndex]="hasNumberedIndex"
                (eventBus)="handleEventBus($event)"
                [parentId]="paragraphId"
                [protocolId]="protocolId"
                edit=true
                [defaultColDef]="defaultColDef"
            >
            </alii-web-paragraph-recommendation-table>

            <summary-detail *ngIf="paragraph.is_summary" [rows]="paragraph.rows"></summary-detail>
        
            <div id="accordion-{{ paragraph.id }}">
                <div class="ml-4">
                    <alii-web-footer-edit
                        [protocolId]="''+protocolId"
                        [paragraphId]="''+paragraph.id"
                        [paragraph]="paragraph"
                        [parentId]="parentId"
                        [type]="paragraph.type"
                        [comments]="paragraph.comments"
                        [keyQuestionId]="paragraph.keyQuestionId"
                        [sources]="paragraph.sources"
                        [sourceInfo]="paragraph.sourceInfo"
                        [sourceTable]="getSourceTable(paragraph)"
                        [allSourceTable]="paragraph.allSourceTable"
                        [populations]="paragraph.populations"
                        (eventBus)="handleEventBus($event)"
                        [gradeAssessmentPar]="getGradeAssessmentPar(paragraph.id)"
                        [filesPar]="getFilesPar(paragraph.id)"
                        [uploadEvent]="paragraphsFiles.uploadEvent"
                    >
                    </alii-web-footer-edit>
                </div>
            </div>
            <div id="showNewFile-{{ paragraph.id }}"></div>
            <hr *ngIf="paragraph.level + '' === '3'" class="page__divider paragraphContent" />
        </ng-container>
        <ng-container *ngIf="paragraph.type === 'keyQuestion'">
            <alii-web-paragraph-evidence-table
                [paragraphId]="''+paragraph.id"
                [sources]="paragraph.sources"
                [sourceInfo]="paragraph.sourceInfo"
                [sourceTable]="paragraph.sourceTable"
                [index]="index"
                [isEditAble]="isEditAble"
                [hasNumberedIndex]="hasNumberedIndex"
                (eventBus)="handleEventBus($event)"
            >
            </alii-web-paragraph-evidence-table>
        </ng-container>
       
    </ng-container>

    <ng-container *ngIf="paragraph.children">
        <div [attr.data-id]="paragraph.id">
            <div
                *ngFor="let listChild of getChildren(paragraph, 'main'); trackBy: trackByFn; index as i"
                data-id="{{ listChild.id }}"
                [attr.parentId]="paragraph.id"
            >
                <div
                    class="page__section"
                    [ngClass]="{
                        '-no-bottom': listChild.level + '' != '1',
                        '-no-top': listChild.level + '' != '1',
                        '-small-bottom': listChild.level + '' === '1'
                    }"
                >
                    <alii-web-paragraph-edit
                            [isRoot]="false"
                            (eventBus)="handleEventBus($event)"
                            [channelMessages]="channelMessages"
                            [updateModelBySheetIdArrayLoading]="updateModelBySheetIdArrayLoading"
                            [protocolId]="protocolId"
                            [parentId]="paragraph.id"
                            [paragraph]="listChild"
                            [isEditAble]="isEditAble"
                            [index]="i"
                            [parentIndex]="index"
                            [paragraphSelectedId]="paragraphSelectedId"
                            [modelMessages]="modelMessages"
                            [keyQuestionImportList]="keyQuestionImportList"
                            [gradeAssessment]="gradeAssessment"
                            [parentSourceTable]="paragraph.allSourceTable"
                            [paragraphsFiles]="paragraphsFiles"
                            [modelFindings]="modelFindings"
                            [modelOutcomes]="modelOutcomes"
                            [modelTagList]="modelTagList"
                            [hasNumberedIndex]="hasNumberedIndex"
                            [viewByPopulation]="viewByPopulation"
                            [populations]="populations"
                            [populationId]="populationId"
                        >
                    </alii-web-paragraph-edit>

                </div>
            </div>
            <ng-container *ngIf="paragraph.id && paragraph.level + '' === '1' && !isRoot && paragraph.type != 'keyQuestion'">
                <protocol-paragraph-add
                    (eventBus)="handleEventBus($event)"
                    [protocolId]="protocolId"
                    [parentId]="paragraph.id"
                >
                </protocol-paragraph-add>
            </ng-container>

            <ng-container *ngIf="paragraph.type === 'keyQuestion'">
                <ng-container *ngFor="let type of getKeyQuestionSubTypes(); index as subTypeIndex; trackBy: trackByFn">
                    <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="hasNumberedIndex">
                        {{index + 1}}.{{subTypeIndex + 1}} {{type.title}}</h4>
                    <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="!hasNumberedIndex">
                        {{type.title}}</h4>
                    <div
                        *ngFor="let listChild of getChildren(paragraph, type.type); index as i; trackBy: trackByFn"
                        data-id="{{ listChild.id }}"
                        [attr.parentId]="paragraph.id"
                    >
                        <div
                            class="page__section"
                            [ngClass]="{
                                '-no-bottom': listChild.level + '' != '1',
                                '-small-bottom': listChild.level + '' === '1'
                            }"
                        >
                            <alii-web-paragraph-edit
                                    [isRoot]="false"
                                    (eventBus)="handleEventBus($event)"
                                    [channelMessages]="channelMessages"
                                    [updateModelBySheetIdArrayLoading]="updateModelBySheetIdArrayLoading"
                                    [protocolId]="protocolId"
                                    [parentId]="paragraph.id"
                                    [paragraph]="listChild"
                                    [isEditAble]="isEditAble"
                                    [index]="i"
                                    [keyQuestionTypeIndex]="subTypeIndex"
                                    [parentIndex]="index"
                                    [paragraphSelectedId]="paragraphSelectedId"
                                    [modelMessages]="modelMessages"
                                    [keyQuestionImportList]="keyQuestionImportList"
                                    [gradeAssessment]="gradeAssessment"
                                    [parentSourceTable]="paragraph.allSourceTable"
                                    [paragraphsFiles]="paragraphsFiles"
                                    [modelFindings]="modelFindings"
                                    [modelOutcomes]="modelOutcomes"
                                    [modelTagList]="modelTagList"
                                    [viewByPopulation]="viewByPopulation"
                                    [populations]="populations"
                                    [populationId]="populationId"
                                    [hasNumberedIndex]="hasNumberedIndex"
                                >
                            </alii-web-paragraph-edit>
                        </div>
                    </div>

                    <protocol-paragraph-add
                        (eventBus)="handleEventBus($event)"
                        [protocolId]="protocolId"
                        [parentId]="paragraph.id"
                        [type]="type.type"
                    >
                    </protocol-paragraph-add>
                    <hr class="page__divider paragraphContent" />
                    
                </ng-container>
                
                
            </ng-container>
        </div>
    </ng-container>
</div>
<ng-template #modelSheetUpdateLoader>
    <div *ngIf="updateModelBySheetIdArrayLoading[paragraph.id]">
        <div class="spinner-grow" role="status"></div>
        Updating...
    </div>
</ng-template>
