import { Component, Input, Output, ViewChild, EventEmitter, AfterViewInit, HostListener, OnInit } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Observable, Subject, merge } from 'rxjs';

const cn = 'ModelDetailTypeaheadComponent';

@Component({
    selector: 'alii-web-model-detail-typeahead',
    templateUrl: './model-detail-typeahead.component.html',
    styleUrls: ['./model-detail-typeahead.component.scss']
})
export class ModelDetailTypeaheadComponent implements OnInit, AfterViewInit {
    @Input() id: string;
    @Input() minLength = 2;
    @Input() items: any[] = [];
    @Input() max;
    @Input() placeholder = 'Select';
    @Output() selected: EventEmitter<{ id: string; item: string }> = new EventEmitter<any>();

    @ViewChild('instance') instance: NgbTypeahead;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    model: string;

    inputElement: HTMLInputElement;
    widthInputElement: number;
    inputId: string;

    @HostListener('window:resize', ['$event']) handleResize() {
        this._adjustDropdownWindowWidth();
    }

    constructor() {}

    ngOnInit() {
        this.inputId = 'typeahead-focus-' + this.id;
        this.max = this.max || 9999;
    }

    ngAfterViewInit() {
        // @ts-ignore
        if (this.instance && this.instance._elementRef) {
            // @ts-ignore
            this.inputElement = this.instance._elementRef.nativeElement as HTMLInputElement;
            this.widthInputElement = this.inputElement.offsetWidth;
        } else {
            console.error(`${cn} Cannot get instance._elementRef`);
        }
    }

    selectItem(event: any) {
        this.selected.next({ id: this.id, item: event.item });
        if (this.inputElement) {
            // Clear the typeahead input field
            setTimeout(() => (this.inputElement.value = ''), 0);
        }
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        let defaultList = []
        if(this.items.length < 20) {
            defaultList = this.items
        }
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
                debounceTime(200),
                distinctUntilChanged(),
                map(term => 
                    ( term.length < this.minLength
                        ? defaultList
                        : this.filterList(term)
                    ).slice(0, this.max)
                    ),
                    tap(() => this._adjustDropdownWindowWidth())     
                );

    };

    filterList(term) {
        let filteredList = []
        this.items.forEach(element => {            
            if (
                element.synonyms.filter(v => v.title.toLowerCase().indexOf(term.toLowerCase()) > -1).length 
                    ||
                element.name.toLowerCase().indexOf(term.toLowerCase()) > -1
                )
                {
                    filteredList.push(element)
                }
            });
        return filteredList
    }

    // Adjust the dropdwon window width to be the same as the input element.
    private _adjustDropdownWindowWidth() {
        if (this.inputElement && this.widthInputElement) {
            setTimeout(() => {
                const dropdown = this.inputElement.parentElement.getElementsByTagName('ngb-typeahead-window')[0];
                if (dropdown) {
                    dropdown.setAttribute('style', 'width: ' + this.widthInputElement + 'px');
                }
            }, 20);
        }
    }
}
