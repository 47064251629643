import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as UsersActions from '../actions/users.action';
import * as fromService from '../../../../services/organisation.service';
import { of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions, 
        private organisationService: fromService.OrganisationService,
        private toastr: ToastrService) {}

    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType(UsersActions.LOAD_USERS),
        switchMap(action => {
            const teamId = (action as any).payload;
            return this.organisationService.getUsers('', teamId).pipe(
                map(dashboard => new UsersActions.LoadUsersSucces(dashboard)),
                catchError(error => of(new UsersActions.LoadUsersFail(error)))
            );
        })
    );

    @Effect()
    createUser$ = this.actions$.pipe(
        ofType(UsersActions.CREATE_USER),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.createUser(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.CreateUserFail(response)
                    } else {
                        return new UsersActions.CreateUserSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.CreateUserFail(error)))
            );
        })
    );

    @Effect()
    SetAdmin$ = this.actions$.pipe(
        ofType(UsersActions.SET_ADMIN),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.setAdmin(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.SetAdminFail(response)
                    } else {
                        return new UsersActions.SetAdminSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.SetAdminFail(error)))
            );
        })
    );

    @Effect()
    SetReviewer$ = this.actions$.pipe(
        ofType(UsersActions.SET_REVIEWER),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.setReviewer(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.SetReviewerFail(response)
                    } else {
                        return new UsersActions.SetReviewerSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.SetReviewerFail(error)))
            );
        })
    );

    @Effect()
    RemoveReviewer$ = this.actions$.pipe(
        ofType(UsersActions.REMOVE_REVIEWER),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.removeReviewer(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.RemoveReviewerFail(response)
                    } else {
                        return new UsersActions.RemoveReviewerSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.RemoveReviewerFail(error)))
            );
        })
    );

    @Effect()
    SetStatistics$ = this.actions$.pipe(
        ofType(UsersActions.SET_STATISTICS),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.setStatistics(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.SetStatisticsFail(response)
                    } else {
                        return new UsersActions.SetStatisticsSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.SetStatisticsFail(error)))
            );
        })
    );

    @Effect()
    RemoveStatistics$ = this.actions$.pipe(
        ofType(UsersActions.REMOVE_STATISTICS),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.removeStatistics(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.RemoveStatisticsFail(response)
                    } else {
                        return new UsersActions.RemoveStatisticsSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.RemoveStatisticsFail(error)))
            );
        })
    );


    @Effect()
    CreateUserFailEffect$ = this.actions$.pipe(
        ofType(
            UsersActions.CREATE_USER_FAIL,
            UsersActions.SET_ADMIN_FAIL,
            UsersActions.SET_REVIEWER_FAIL,
            UsersActions.REMOVE_REVIEWER_FAIL,
            ),
        mergeMap((response: any) => {
            const msg = response.payload.error
                ? response.payload.error
                : 'Oops something went wrong, try again.';
            this.toastr.error('', msg);
            return [];
        })
    );

    @Effect()
    RemoveUserEffect$ = this.actions$.pipe(
        ofType(UsersActions.REMOVE_USER),
        switchMap(action => {
            const { payload } = action as any;
            const { userId } = payload;
            return this.organisationService.removeUser({ userId }).pipe(
                map(response => new UsersActions.RemoveUserSuccess(response)),
                catchError(error => of(new UsersActions.RemoveUserFail(error)))
            );
        })
    );

    @Effect()
    ReloadUsers$ = this.actions$.pipe(
        ofType(
            UsersActions.CREATE_USER_SUCCESS, 
            UsersActions.REMOVE_USER_SUCCESS, 
            UsersActions.SET_ADMIN_SUCCESS,
            UsersActions.SET_REVIEWER_SUCCESS,
            UsersActions.REMOVE_REVIEWER_SUCCESS,
            UsersActions.SET_STATISTICS_SUCCESS,
            UsersActions.REMOVE_STATISTICS_SUCCESS,
            ),
            switchMap(response => {
            const { payload } = response as any;
            return [new UsersActions.LoadUsers(payload.teamId)];
        })
    );
}
